import customAxiosInstance from "api/axios"

// role = ADMIN, MEMBER, CUSTOMER
// short_role = A, M, C

/**
 * @params {
 *    userId: string,
 *    account: string,
 *    firstName: string,
 *    lastName: string,
 *    role: string,
 *    phone: string,
 *    email: string,
 *    isValid: boolean,
 *    country: string,
 *    countryCode: string,
 *    address: string,
 *    city: string,
 *    province: string,
 *    postalCode: string
 * }
 */
export const updateUserInfo = async ({
  userId = "",
  account,
  firstName,
  lastName,
  role = "C",
  phone,
  email,
  isValid = true,
  country,
  countryCode,
  address,
  city,
  province,
  postalCode,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/users/${userId}`,
      data: {
        account,
        firstName,
        lastName,
        role,
        phone,
        email,
        isValid,
        country,
        countryCode,
        address,
        city,
        province,
        postalCode,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateUserInfo
