import { IconButton, makeStyles, TextField } from "@material-ui/core"
import PropTypes from "prop-types"
import Build from "@material-ui/icons/Build"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js"
import React from "react"

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import Card from "components/Card/Card"
import { Close } from "@material-ui/icons"

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

export default function RelPartForm({ formik }) {
  const [relPartDataList, setRelPartDataList] = React.useState([])
  const [partAlias, setPartAlias] = React.useState("")

  const shoppingCartClasses = useStylesShoppingCart()

  const relPartList = relPartDataList.map((alias) => [
    alias,
    <IconButton
      key={1}
      color="inherit"
      onClick={() => {
        formik.setFieldValue(
          "aliases",
          relPartDataList.filter((a) => a !== alias).join(","),
        )
      }}>
      <Close color="error" />
    </IconButton>,
  ])

  React.useEffect(() => {
    setRelPartDataList(
      formik.values.aliases ? formik.values.aliases.split(",") : [],
    )
  }, [formik.values.aliases])

  return (
    <>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Card
            style={{
              maxHeight: "15rem",
              overflow: "scroll",
              borderRadius: 0,
              border: "1px solid #ddd",
            }}
            plain>
            <div style={{ width: "100%", height: "2px", background: "#ddd" }} />
            <Table
              tableHead={["파트번호", <Build key={1} />]}
              tableData={relPartList}
              customHeadCellClasses={[
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
              ]}
              customHeadClassesForCells={[0, 1]}
              customCellClasses={[
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
              ]}
              customClassesForCells={[0, 1]}
            />
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2rem",
        }}>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            label="파트 번호를 입력해주세요"
            variant="outlined"
            value={partAlias}
            onChange={(e) => {
              setPartAlias(e.target.value)
            }}
            style={{ width: "100%" }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <Button
            fullWidth
            color="primary"
            onClick={() => {
              formik.setFieldValue(
                "aliases",
                [...relPartDataList, partAlias].join(","),
              )
              setPartAlias("")
            }}>
            추가
          </Button>
        </GridItem>
      </GridContainer>
    </>
  )
}

RelPartForm.propTypes = {
  formik: PropTypes.object,
  selectedPart: PropTypes.object,
}
