import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"

import CustomInput from "components/CustomInput/CustomInput"
import { useFormik } from "formik"
import Button from "components/CustomButtons/Button.js"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import CustomIntlTelInput from "components/CustomIntlTelInput/CustomIntlTelInput"
import accountEditFormValidationSchema from "./validationSchema"
import { useMutation, useQuery } from "@tanstack/react-query"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"
import { intlTelCountry } from "constants/intlTelCountry"
import updateUserPassword from "api/user/updateUserPassword"
import { updateUserInfo } from "api/user/updateUserInfo"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(selectStyles)

export default function AccountEditForm() {
  const history = useHistory()
  const [user, setUser] = React.useState()
  const { data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )
  const updateUserPasswordMutation = useMutation(updateUserPassword)
  const updateUserInfoMutation = useMutation(updateUserInfo, {
    onSuccess: () => {
      alert("User information has been modified.")
      history.push("/")
    },
  })
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      id: "",
      password: "",
      confirmPassword: "",
      email: "",
      mobile: { country: "", countryCode: "", phone: "" },
      country: "kr",
      address: "",
      city: "",
      province: "",
      postalCode: "",
    },
    validationSchema: accountEditFormValidationSchema,
    onSubmit: (values) => {
      const userId = user ? user.id : ""
      const { id, password, confirmPassword, mobile, ...rest } = values
      if (password && !confirmPassword) {
        return formik.setErrors({
          confirmPassword: "Enter your Confirm password",
        })
      }

      if (password && confirmPassword) {
        const isVaildPassword = password === confirmPassword
        if (isVaildPassword) {
          updateUserPasswordMutation.mutateAsync({ userId, password })
        }
      }

      const userInfo = {
        ...rest,
        userId,
        phone: mobile.phone,
        countryCode: mobile.countryCode,
        account: id,
        role: "C",
        isValid: 1,
      }
      updateUserInfoMutation.mutateAsync(userInfo)
    },
  })

  const classes = useStyles()

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUser(verifyTokenAndGetUserInfoResponse.data.user)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  React.useEffect(() => {
    if (user) {
      const {
        account,
        country,
        countryCode,
        phone,
        // createdAt,
        // updatedAt,
        ...restUser
      } = user
      const mobileCountry = intlTelCountry.getCountryWithCode(countryCode)
      const selectedCountry = intlTelCountry.getCountryWithISO2(country)
      formik.setFormikState((prev) => ({
        ...prev,
        values: {
          ...prev.values,
          ...restUser,
          id: account,
          country: selectedCountry ? selectedCountry.iso2 : "kr",
          mobile: {
            country: mobileCountry ? mobileCountry.iso2 : "kr",
            countryCode: countryCode,
            phone: phone,
          },
        },
      }))
    }
  }, [user])

  return (
    <form onSubmit={formik.handleSubmit}>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="ID"
            disabled
            inputProps={{
              disabled: true,
              name: "id",
              autoComplete: "off",
              value: formik.values.id,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={6}
          sm={5}
          md={3}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="First name"
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            inputProps={{
              required: true,
              id: "firstName",
              name: "firstName",
              value: formik.values.firstName,
              onChange: formik.handleChange,
              error:
                formik.touched.firstName && Boolean(formik.errors.firstName),
            }}
            formControlProps={{
              fullWidth: true,
              error:
                formik.touched.firstName && Boolean(formik.errors.firstName),
            }}
          />
        </GridItem>
        <GridItem
          xs={6}
          sm={5}
          md={3}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="Last name"
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            inputProps={{
              required: true,
              id: "lastName",
              name: "lastName",
              value: formik.values.lastName,
              onChange: formik.handleChange,
              error: formik.touched.lastName && Boolean(formik.errors.lastName),
            }}
            formControlProps={{
              fullWidth: true,
              error: formik.touched.lastName && Boolean(formik.errors.lastName),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="Password"
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            inputProps={{
              name: "password",
              type: "password",
              autoComplete: "off",
              value: formik.values.password,
              onChange: formik.handleChange,
              error: formik.touched.password && Boolean(formik.errors.password),
            }}
            formControlProps={{
              fullWidth: true,
              error: formik.touched.password && Boolean(formik.errors.password),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            labelText="Confirm Password"
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            inputProps={{
              name: "confirmPassword",
              type: "password",
              value: formik.values.confirmPassword,
              onChange: formik.handleChange,
              error:
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword),
            }}
            formControlProps={{
              fullWidth: true,
              error:
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="Email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            inputProps={{
              required: true,
              name: "email",
              value: formik.values.email,
              onChange: formik.handleChange,
              error: formik.touched.email && Boolean(formik.errors.email),
            }}
            formControlProps={{
              fullWidth: true,
              error: formik.touched.email && Boolean(formik.errors.email),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={12} sm={10} md={6}>
          <InputLabel
            htmlFor="mobile"
            style={{
              textAlign: "start",
              fontSize: "14px",
              color: "#aaa",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.62857",
            }}>
            Mobile
          </InputLabel>
          {user && (
            <CustomIntlTelInput
              helperText={formik.touched.mobile && formik.errors.mobile}
              inputProps={{
                defaultCountry: intlTelCountry.getCountryWithCode(
                  user.countryCode,
                )
                  ? intlTelCountry.getCountryWithCode(user.countryCode).iso2
                  : "kr",
                autoPlaceholder: false,
                formatOnInit: false,
                onSelectFlag: (phoneNum, countryObj) => {
                  const { iso2: country, dialCode } = countryObj
                  formik.setFormikState((prev) => ({
                    ...prev,
                    values: {
                      ...prev.values,
                      mobile: {
                        ...prev.values.mobile,
                        country,
                        countryCode: `+${dialCode}`,
                      },
                    },
                  }))
                },
                onPhoneNumberChange: (isValid, phone) => {
                  formik.setFormikState((prev) => ({
                    ...prev,
                    values: {
                      ...prev.values,
                      mobile: {
                        ...prev.values.mobile,
                        phone,
                      },
                    },
                  }))
                },
                value: formik.values.mobile.phone,
                fieldName: "mobile",
                fieldId: "mobile",
                telInputProps: {
                  style: {
                    width: "100%",
                    border: 0,
                    color: "#3C4858",
                    borderBottom: "1px solid #D2D2D2",
                    paddingBottom: "2px",
                    fontSize: "18px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: "400",
                    lineHeight: "1.62857",
                    // "&:before": { borderBottom: "1px solid #D2D2D2" },
                    // "&:after": { borderBottom: "2px solid #3f51b5" },
                  },
                },
              }}
              formControlProps={{
                fullWidth: true,
                style: { paddingTop: "4px" },
              }}
            />
          )}
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <FormControl
            fullWidth
            className={
              formik.touched.country && Boolean(formik.errors.country)
                ? classes.selectFormControlError
                : classes.selectFormControl
            }
            error={formik.touched.country && Boolean(formik.errors.country)}>
            <InputLabel htmlFor="country" className={classes.selectLabel}>
              Country
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              error={formik.touched.country && Boolean(formik.errors.country)}
              value={formik.values.country}
              onChange={formik.handleChange}
              inputProps={{
                required: true,
                id: "country",
                name: "country",
              }}>
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}>
                Select Country
              </MenuItem>
              {intlTelCountry.getCountries().map(({ name, iso2 }) => (
                <MenuItem
                  key={iso2}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={iso2}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.country && Boolean(formik.errors.country) && (
              <FormHelperText error style={{ textAlign: "center" }}>
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            )}
          </FormControl>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="Detailed Address"
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            inputProps={{
              required: true,
              name: "address",
              value: formik.values.address,
              onChange: formik.handleChange,
              error: formik.touched.address && Boolean(formik.errors.address),
            }}
            formControlProps={{
              fullWidth: true,
              error: formik.touched.address && Boolean(formik.errors.address),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="City"
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            inputProps={{
              required: true,
              name: "city",
              value: formik.values.city,
              onChange: formik.handleChange,
              error: formik.touched.city && Boolean(formik.errors.city),
            }}
            formControlProps={{
              fullWidth: true,
              error: formik.touched.city && Boolean(formik.errors.city),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="Province"
            error={formik.touched.province && Boolean(formik.errors.province)}
            helperText={formik.touched.province && formik.errors.province}
            inputProps={{
              required: true,
              name: "province",
              value: formik.values.province,
              onChange: formik.handleChange,
              error: formik.touched.province && Boolean(formik.errors.province),
            }}
            formControlProps={{
              fullWidth: true,
              error: formik.touched.province && Boolean(formik.errors.province),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem
          xs={12}
          sm={10}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <CustomInput
            labelText="Postal Code"
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
            inputProps={{
              required: true,
              name: "postalCode",
              value: formik.values.postalCode,
              onChange: formik.handleChange,
              error:
                formik.touched.postalCode && Boolean(formik.errors.postalCode),
            }}
            formControlProps={{
              fullWidth: true,
              error:
                formik.touched.postalCode && Boolean(formik.errors.postalCode),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}>
        <GridItem
          xs={8}
          sm={7}
          md={4}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Save
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  )
}
