import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import IntlTelInput from "react-intl-tel-input"
import OutlinedInput from "@material-ui/core/OutlinedInput"
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js"

const useStyles = makeStyles(styles)

export default function CustomIntlTelInput(props) {
  const {
    formControlProps,
    labelText,
    helperText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    variant,
  } = props

  const classes = useStyles()
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  })
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.inputError]: error,
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames({
      [formControlProps.className]: true,
      [classes.formControl]: true,
      [classes.formControlError]: error,
    })
  } else {
    formControlClasses = classNames({
      [classes.formControl]: true,
      [classes.formControlError]: error,
    })
  }
  let newInputProps = {
    maxLength: inputProps ? inputProps.maxLength : undefined,
    minLength: inputProps ? inputProps.minLength : undefined,
  }
  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      error={error}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      {variant === "outlined" ? (
        <OutlinedInput
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            // underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
          inputProps={{ ...newInputProps, ...inputProps }}
        />
      ) : (
        <IntlTelInput inputClassName={inputClasses} id={id} {...inputProps} />
      )}
      {helperText ? (
        <FormHelperText error style={{ textAlign: "center" }}>
          {helperText || ""}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

CustomIntlTelInput.propTypes = {
  labelText: PropTypes.node,
  helperText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  variant: PropTypes.string,
  white: PropTypes.bool,
}
