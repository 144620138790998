import {
  IconButton,
  MenuItem,
  makeStyles,
  Select,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core"
import PropTypes from "prop-types"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js"
import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import moment from "moment"
import CustomInput from "components/CustomInput/CustomInput"
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import { QuotationStatus } from "constants/statuses"
import { useQuery } from "@tanstack/react-query"
import getAdminQuotation from "api/adminQuotation/getAdminQuotation"
import QuotationCurrency from "./QuotationCurrency"
import QuotationTotalAmount from "./QuotationTotalAmount"

const useStylesShoppingCart = makeStyles(shoppingCartStyle)
const useSelectStyles = makeStyles(selectStyles)

const validationSchema = yup.object({
  status: yup.string("Enter your status"),
  userName: yup.string("Enter your userName"),
  referNo: yup.string("Enter your referNo"),
  startRequestQuotationDate: yup.string("Enter your status"),
  endRequestQuotationDate: yup.string("Enter your status"),
})

export default function DashboardList({
  detailRef,
  onClickRow,
  handleClickQuotationClear,
}) {
  const [quotationDataList, setQuotationDataList] = React.useState([])
  const [
    filteredQuotationDataList,
    setFilteredQuotationDataList,
  ] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [perPage] = React.useState(10)

  const formik = useFormik({
    initialValues: {
      status: "unselected",
      userName: "",
      referNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClickQuotationClear()
      const {
        status,
        userName,
        referNo,
        startRequestQuotationDate,
        endRequestQuotationDate,
      } = values
      if (
        !status &&
        !userName &&
        !referNo &&
        !startRequestQuotationDate &&
        !endRequestQuotationDate
      )
        return setFilteredQuotationDataList(quotationDataList)

      return setFilteredQuotationDataList(
        quotationDataList
          .filter((part) => {
            if (status && status !== "unselected") {
              const { quotation } = part
              return quotation.status === status
            }
            return true
          })
          .filter((part) => {
            if (referNo) {
              const { quotation } = part
              return quotation.id.includes(referNo)
            }
            return true
          })
          .filter((part) => {
            if (userName) {
              const {
                rel: { user },
              } = part
              return (
                user.firstName.includes(userName) ||
                user.lastName.includes(userName)
              )
            }
            return true
          })
          .filter((part) => {
            if (startRequestQuotationDate) {
              const {
                quotation: { requestedAt },
              } = part
              return moment(requestedAt).isAfter(
                startRequestQuotationDate.startOf("day"),
              )
            }
            return true
          })
          .filter((part) => {
            if (endRequestQuotationDate) {
              const {
                quotation: { requestedAt },
              } = part
              return moment(requestedAt).isBefore(
                endRequestQuotationDate.endOf("day"),
              )
            }
            return true
          }),
      )
    },
  })

  const shoppingCartClasses = useStylesShoppingCart()
  const selectClasses = useSelectStyles()

  const { isLoading, data: getAdminQuotationResponse } = useQuery(
    ["admin_quotaions"],
    getAdminQuotation,
  )

  React.useEffect(() => {
    if (getAdminQuotationResponse && getAdminQuotationResponse.data) {
      setQuotationDataList(getAdminQuotationResponse.data.quotations)
      setFilteredQuotationDataList(getAdminQuotationResponse.data.quotations)
    }
  }, [getAdminQuotationResponse])

  const quotationList = filteredQuotationDataList.map(
    ({ quotation, rel }, i) => {
      const { user } = rel
      return [
        i + 1,
        quotation.id,
        `${user.firstName} ${user.lastName}`,
        user.account,
        quotation.deliveryType,
        moment(quotation.eta).format("YYYY-MM-DD"),
        <QuotationCurrency key={quotation.id} quotationId={quotation.id} />,
        <QuotationTotalAmount key={quotation.id} quotationId={quotation.id} />,
        quotation.status,
        moment(quotation.requestedAt).format("YYYY-MM-DD HH-mm-ss"),
      ]
    },
  )

  const quotationTotalCount = filteredQuotationDataList.length
  const lastPage = Math.ceil(Number(quotationTotalCount / perPage))

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ margin: 0 }}>
        <GridContainer
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <GridItem xs={12} sm={12} md={3}>
            <FormControl
              fullWidth
              className={
                formik.touched.status && Boolean(formik.errors.status)
                  ? selectClasses.selectFormControlError
                  : selectClasses.selectFormControl
              }
              error={formik.touched.status && Boolean(formik.errors.status)}>
              <Select
                MenuProps={{
                  className: selectClasses.selectMenu,
                }}
                classes={{
                  select: selectClasses.select,
                }}
                value={formik.values.status}
                onChange={formik.handleChange}
                inputProps={{
                  required: true,
                  id: "status",
                  name: "status",
                }}>
                <MenuItem value="unselected">상태 선택</MenuItem>
                {QuotationStatus.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.status && Boolean(formik.errors.status) && (
                <FormHelperText error style={{ textAlign: "center" }}>
                  Error
                </FormHelperText>
              )}
            </FormControl>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "3rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              고객명
            </Typography>
            <CustomInput
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
              inputProps={{
                placeholder: "고객명",
                name: "userName",
                value: formik.values.userName,
                onChange: formik.handleChange,
                error:
                  formik.touched.userName && Boolean(formik.errors.userName),
              }}
              formControlProps={{
                fullWidth: true,
                style: { margin: 0, padding: 0 },
              }}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "4rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              견적번호
            </Typography>
            <CustomInput
              error={formik.touched.referNo && Boolean(formik.errors.referNo)}
              helperText={formik.touched.referNo && formik.errors.referNo}
              inputProps={{
                placeholder: "견적번호",
                name: "referNo",
                value: formik.values.referNo,
                onChange: formik.handleChange,
                error: formik.touched.referNo && Boolean(formik.errors.referNo),
              }}
              formControlProps={{
                fullWidth: true,
                style: { margin: 0, padding: 0 },
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "5rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              견적 요청일
            </Typography>
            <FormControl
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginRight: "0.5rem",
              }}>
              <CustomDatePicker
                value={formik.values.startRequestQuotationDate}
                onChange={(date) => {
                  formik.setFieldValue("startRequestQuotationDate", date)
                }}
                inputProps={{
                  id: "startRequestQuotationDate",
                  name: "startRequestQuotationDate",
                }}
              />
            </FormControl>
            <Typography
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              ~
            </Typography>
            <FormControl
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: "0.5rem",
              }}>
              <CustomDatePicker
                value={formik.values.endRequestQuotationDate}
                onChange={(date) => {
                  formik.setFieldValue("endRequestQuotationDate", date)
                }}
                inputProps={{
                  id: "endRequestQuotationDate",
                  name: "endRequestQuotationDate",
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}></GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={2}
            style={{ display: "flex", justifyContent: "end" }}>
            <Button fullWidth type="submit" color="primary">
              Search
            </Button>
          </GridItem>
        </GridContainer>
      </form>
      {isLoading ? null : (
        <>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}>
              <Typography
                style={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                {(page - 1) * perPage + 1} -{" "}
                {quotationTotalCount <= (page - 1) * perPage + perPage
                  ? quotationTotalCount
                  : (page - 1) * perPage + perPage}{" "}
                of {quotationTotalCount}
              </Typography>
              <IconButton
                disabled={page <= 1}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  if (page > 1) setPage(page - 1)
                }}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                disabled={page >= lastPage}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  if (page < lastPage) setPage(page + 1)
                }}>
                <ChevronRightIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginBottom: "2rem" }}>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{ width: "100%", height: "1px", background: "#ddd" }}
              />
              <Table
                tableHead={[
                  "번호",
                  "견적번호",
                  "고객명",
                  "고객ID",
                  "배송유형",
                  "ETA",
                  "통화",
                  "총견적액",
                  "상태",
                  "주문일시",
                ]}
                tableShopping
                tableData={quotationList.filter(
                  (u, i) => i >= (page - 1) * perPage && i < page * perPage,
                )}
                customHeadCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                customCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.textBoldAndDanger,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                onClickTableData={(row) => {
                  const [, quotationId] = row
                  const finded = getAdminQuotationResponse.data.quotations.find(
                    ({ quotation: { id } }) => id === quotationId,
                  )
                  onClickRow(finded)
                  detailRef.current.scrollIntoView()
                }}
              />
            </GridItem>
          </GridContainer>
        </>
      )}
    </>
  )
}

DashboardList.defaultProps = {
  onClickRow: () => {},
}

DashboardList.propTypes = {
  onClickRow: PropTypes.func,
  detailRef: PropTypes.object,
  handleClickQuotationClear: PropTypes.func,
}
