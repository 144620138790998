import React from "react"
import SectionContent from "./Sections/SectionContent"

import HomeHeader from "components/Header/HomeHeader.js"
import HomeSection from "components/Section/HomeSection"
import HomeFooterContents from "components/Footer/HomeFooterContents"
import HomeFooterCopyRight from "components/Footer/HomeFooterCopyRight"

export default function JoinPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  }, [])

  return (
    <div>
      <HomeHeader />
      <HomeSection>
        <SectionContent />
        <HomeFooterContents />
      </HomeSection>
      <HomeFooterCopyRight />
    </div>
  )
}
