import React from "react"
import PropTypes from "prop-types"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Card from "components/Card/Card.js"

import { Typography } from "@material-ui/core"
import Media from "components/Media/Media.js"

import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.js"

import moment from "moment"

const useSectionCommentsStyle = makeStyles(sectionCommentsStyle)

export default function QuotationTableItemComment({
  quotationCommentDataList,
}) {
  const sectionCommentsClasses = useSectionCommentsStyle()

  const qutationCommentList = quotationCommentDataList.map((data, i) => {
    const { comment, isOffice, createdAt } = data
    return (
      <Media
        key={i}
        title={
          <span
            style={{
              fontSize: "0.95rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1em",
              textAlign: "start",
            }}>
            {isOffice ? "Comments to Customer" : "Comments from Customer"}
            <small>· {moment(createdAt).format("YYYY-MM-DD")}</small>
          </span>
        }
        body={
          <p
            className={sectionCommentsClasses.color555}
            style={{
              fontSize: "0.85rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "0.9em",
            }}>
            {comment}
          </p>
        }
      />
    )
  })

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        style={{
          fontSize: "1.25em",
          marginTop: "1rem",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: "400",
          lineHeight: "1.5em",
          textAlign: "start",
        }}>
        COMMENTS · {qutationCommentList.length}
      </Typography>
      <Card
        style={{
          padding: "1rem",
          margin: "1rem auto",
          textAlign: "start",
          width: "100%",
          maxWidth: 1080,
        }}>
        {qutationCommentList}
      </Card>
    </>
  )
}

QuotationTableItemComment.defaultProps = {}

QuotationTableItemComment.propTypes = {
  quotationCommentDataList: PropTypes.array,
}
