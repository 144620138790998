import customAxiosInstance from "api/axios"

/**
 * @params categoryId: string
 */
export const deleteCategory = async (categoryId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "delete",
      url: `/categories/${categoryId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default deleteCategory
