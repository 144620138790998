import customAxiosInstance from "api/axios"

/**
 * @params quotationId: string
 */
export const getAdminQuotationComment = async (quotationId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/quotations/${quotationId}/comments`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default getAdminQuotationComment
