import React from "react"
import clsx from "clsx"
import { useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DashboardIcon from "@material-ui/icons/Dashboard"
import PersonIcon from "@material-ui/icons/Person"
import BuildIcon from "@material-ui/icons/Build"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
import StorageIcon from "@material-ui/icons/Storage"
import { useStyles } from "./styles"
import { NavLink as RouterLink, useHistory } from "react-router-dom"
import Link from "@material-ui/core/Link"
import { makeStyles, Menu, MenuItem, Tooltip } from "@material-ui/core"
import Button from "components/CustomButtons/Button.js"
import { AccountCircle, Person } from "@material-ui/icons"
import useWindowDimensions from "hooks/useWindowDimensions"
import LoginForm from "components/LoginForm/LoginForm"
import { useLoginTooltipStyles } from "./HomeHeader"

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js"
import { useQuery } from "@tanstack/react-query"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"

const useHeadersStyles = makeStyles(headersStyle)

export default function AdminHeader() {
  const history = useHistory()
  const { width } = useWindowDimensions()
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(width > 600)
  const [openLoginTooltip, setOpenLoginTooltip] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [userRole, setUserRole] = React.useState("")
  const authMenuOpen = Boolean(anchorEl)

  const { isLoading, data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )

  const handleLogout = () => {
    localStorage.setItem("accessToken", "")
    history.push("/admin/dashboard")
    window.location.reload()
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const classesHeaders = useHeadersStyles()
  const classesLoginTooltipStyles = useLoginTooltipStyles()

  const handleLoginTooltipOpen = () => {
    setOpenLoginTooltip(true)
  }

  const handleLoginTooltipClose = () => {
    setOpenLoginTooltip(false)
  }

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUserRole(verifyTokenAndGetUserInfoResponse.data.user.role)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && (userRole === "A" || userRole === "M"),
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}>
            <MenuIcon />
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              noWrap
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              {history.location.pathname === "/admin/dashboard"
                ? "견적 관리"
                : history.location.pathname === "/admin/user"
                ? "사용자 관리"
                : history.location.pathname === "/admin/part"
                ? "파트 관리"
                : history.location.pathname === "/admin/system/part-brand"
                ? "파트 브랜드 관리"
                : history.location.pathname === "/admin/system/category"
                ? "카테고리 관리"
                : ""}
            </Typography>
            {isLoading ? null : verifyTokenAndGetUserInfoResponse ? (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={authMenuOpen}
                  onClose={handleClose}>
                  <MenuItem onClick={handleLogout} style={{ color: "red" }}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <Tooltip
                title={
                  <LoginForm
                    admin
                    handleOpen={handleLoginTooltipOpen}
                    handleClose={handleLoginTooltipClose}
                  />
                }
                classes={{
                  tooltip: classesLoginTooltipStyles.tooltip,
                  popper: classesLoginTooltipStyles.popper,
                }}
                PopperProps={{
                  disablePortal: true,
                }}
                placement="bottom"
                arrow
                interactive
                onClose={handleLoginTooltipClose}
                open={openLoginTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener>
                <Button
                  color={"transparent"}
                  className={classesHeaders.navLink}
                  onClick={handleLoginTooltipOpen}>
                  <Person />
                  LOGIN
                  <b
                    style={{
                      transition: "all 150ms ease-in",
                      display: "inline-block",
                      width: "0",
                      height: "0",
                      marginLeft: "4px",
                      verticalAlign: "middle",
                      borderTop: "4px solid",
                      borderRight: "4px solid transparent",
                      borderLeft: "4px solid transparent",
                      transform: openLoginTooltip ? "rotate(180deg)" : "",
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {(userRole === "A" || userRole === "M") && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}>
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {[
              { name: "Dashboard", path: "/admin/dashboard" },
              { name: "User", path: "/admin/user" },
              { name: "Part", path: "/admin/part" },
              { name: "Part Brand", path: "/admin/system/part-brand" },
              { name: "Category", path: "/admin/system/category" },
            ].map((page) => (
              <Link
                key={page.name}
                to={page.path}
                style={{ color: "#000" }}
                activeStyle={{ color: "#003C8D" }}
                component={RouterLink}>
                <ListItem button>
                  <ListItemIcon>
                    {page.name === "Dashboard" ? <DashboardIcon /> : undefined}
                    {page.name === "User" ? <PersonIcon /> : undefined}
                    {page.name === "Part" ? <BuildIcon /> : undefined}
                    {page.name === "Part Brand" ? (
                      <LocalOfferIcon />
                    ) : undefined}
                    {page.name === "Category" ? <StorageIcon /> : undefined}
                  </ListItemIcon>
                  <ListItemText primary={page.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
      )}
    </>
  )
}
