import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import Datetime from "react-datetime"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import datePickerStyles from "./style"
import CustomDatePickerInput from "./CustomDatePickerInput"

const useStyles = makeStyles(datePickerStyles)

export default function CustomDatePicker(props) {
  const classes = useStyles()
  return (
    <Datetime
      {...props}
      renderInput={CustomDatePickerInput}
      className={classes.picker}
      locale="ko"
      inputProps={{
        ...props.inputProps,
        className: classes.input,
      }}
    />
  )
}

CustomDatePicker.propTypes = {
  onChange: PropTypes.func,
  variant: PropTypes.string,
  locale: PropTypes.string,
  defaultValue: PropTypes.string,
  inputProps: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  timeFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

CustomDatePicker.defaultProps = {
  timeFormat: false,
  dateFormat: "YYYY-MM-DD",
  locale: "ko",
}
