import customAxiosInstance from "api/axios"

/**
 * @params {
 *  userId: string,
 *  quotationId: string,
 *  quotationPartId: string,
 *  orderQuantity: number,
 *  quotationQuantity: number,
 *  unitPrice: number,
 *  comment: string
 * }
 */
export const updateUserQuotationPart = async ({
  userId = "",
  quotationId = "",
  quotationPartId = "",
  orderQuantity,
  quotationQuantity,
  unitPrice,
  comment,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `users/${userId}/quotations/${quotationId}/quotation_parts/${quotationPartId}`,
      data: {
        orderQuantity,
        quotationQuantity,
        unitPrice,
        comment,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateUserQuotationPart
