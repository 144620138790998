import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"
import AccountEditForm from "./AccountEditForm"

const useStyles = makeStyles(featuresStyle)

export default function SectionContent() {
  const classes = useStyles()

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          padding: "0 2rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          paddingBottom: "2rem",
          minHeight: "40rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
            style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <h3 className={classes.title}>UPDATE ACCOUNT</h3>
          </GridItem>
        </GridContainer>
        <AccountEditForm />
      </div>
    </div>
  )
}
