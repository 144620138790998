import {
  Paper,
  makeStyles,
  Typography,
  MenuItem,
  FormHelperText,
  Select,
  FormControl,
} from "@material-ui/core"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { useStyles } from "./styles"
import CustomInput from "components/CustomInput/CustomInput"

import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"
import getCategory from "api/category/getCategory"
import { useMutation, useQuery } from "@tanstack/react-query"
import getSubCategory from "api/category/getSubCategory"
import createCategory from "api/category/createCategory"
import updateCategory from "api/category/updateCategory"
import deleteCategory from "api/category/deleteCategory"
import createSubCategory from "api/category/createSubCategory"
import updateSubCategory from "api/category/updateSubCategory"
import deleteSubCategory from "api/category/deleteSubCategory"

const useSelectStyles = makeStyles(selectStyles)

const validationSchema = yup.object({
  selectedMainCategory: yup
    .string("Enter your selectedMainCategory")
    .required("selectedMainCategory is required")
    .notOneOf(["unselected"], "selectedMainCategory is required"),
  selectedSubCategory: yup
    .string("Enter your selectedSubCategory")
    .required("selectedSubCategory is required")
    .notOneOf(["unselected", "1"], "selectedSubCategory is required"),
})

const validationSchema2 = yup.object({
  mainCategoryId: yup
    .string("Enter your mainCategoryId")
    .required("mainCategoryId is required"),
  mainCategoryName: yup
    .string("Enter your mainCategoryName")
    .required("mainCategoryName is required"),
  subCategoryId: yup
    .string("Enter your subCategoryId")
    .required("subCategoryId is required"),
  subCategoryName: yup
    .string("Enter your subCategoryName")
    .required("subCategoryName is required"),
})

export default function SectionContent() {
  const [errMsg, setErrMsg] = React.useState("")
  const formik = useFormik({
    initialValues: {
      selectedMainCategory: "unselected",
      selectedSubCategory: "unselected",
    },
    validationSchema: validationSchema,
  })
  const formik2 = useFormik({
    initialValues: {
      mainCategoryName: "",
      subCategoryName: "",
    },
    validationSchema: validationSchema2,
  })
  const classes = useStyles()
  const selectClasses = useSelectStyles()

  const { data: getCategoryResponse } = useQuery(["categories"], getCategory)

  const { data: getSubCategoryResponse } = useQuery(
    ["sub_categories", formik2.values.mainCategoryId],
    ({ queryKey }) => getSubCategory(queryKey[1]),
    { enabled: formik.values.selectedMainCategory !== "unselected" },
  )

  const mainCategoryList = getCategoryResponse
    ? getCategoryResponse.data.categories.map(({ id, name }) => ({ id, name }))
    : []
  const subCategoryList = getSubCategoryResponse
    ? getSubCategoryResponse.data.subCategories.map(({ id, name }) => ({
        id,
        name,
      }))
    : []

  const createCategoryMutation = useMutation(createCategory, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const updateCategoryMutation = useMutation(updateCategory, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const deleteCategoryMutation = useMutation(deleteCategory, {
    onSuccess: () => {
      window.location.reload()
    },
  })

  const createSubCategoryMutation = useMutation(createSubCategory, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const updateSubCategoryMutation = useMutation(updateSubCategory, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const deleteSubCategoryMutation = useMutation(deleteSubCategory, {
    onSuccess: () => {
      window.location.reload()
    },
  })

  React.useEffect(() => {
    if (deleteCategoryMutation.error) {
      if (deleteCategoryMutation.error.response.status === 412)
        setErrMsg(
          "If you want to delete this main category, you must delete all sub-categories of this category.",
        )
    }
  }, [deleteCategoryMutation.error])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper elevation={0} variant="outlined" style={{ padding: "1rem" }}>
        <div
          style={{
            padding: "2rem",
          }}>
          <Typography
            style={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.5em",
            }}>
            메인
          </Typography>
          <GridContainer style={{ display: "flex", justifyContent: "end" }}>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={formik.values.selectedMainCategory !== "unselected"}
                fullWidth
                color="info"
                onClick={async () => {
                  formik2.setTouched({
                    mainCategoryName: true,
                  })
                  const errors = await formik2.validateForm()
                  if (!errors.mainCategoryName) {
                    createCategoryMutation.mutateAsync(
                      formik2.values.mainCategoryName,
                    )
                  }
                }}>
                메인 카테고리 생성
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={formik.values.selectedMainCategory === "unselected"}
                fullWidth
                color="primary"
                onClick={async () => {
                  formik2.setTouched({
                    mainCategoryName: true,
                  })
                  const errors = await formik2.validateForm()
                  if (!errors.mainCategoryName) {
                    updateCategoryMutation.mutateAsync({
                      categoryId: formik2.values.mainCategoryId,
                      name: formik2.values.mainCategoryName,
                    })
                  }
                }}>
                메인 카테고리 수정
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={formik.values.selectedMainCategory === "unselected"}
                fullWidth
                color="danger"
                onClick={() => {
                  deleteCategoryMutation.mutateAsync(
                    formik2.values.mainCategoryId,
                  )
                }}>
                메인 카테고리 삭제
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={10}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "8rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                메인 카테고리
              </Typography>
              <FormControl
                fullWidth
                className={
                  formik.touched.selectedMainCategory &&
                  Boolean(formik.errors.selectedMainCategory)
                    ? selectClasses.selectFormControlError
                    : selectClasses.selectFormControl
                }
                error={
                  formik.touched.selectedMainCategory &&
                  Boolean(formik.errors.selectedMainCategory)
                }>
                <Select
                  MenuProps={{
                    className: selectClasses.selectMenu,
                  }}
                  classes={{
                    select: selectClasses.select,
                  }}
                  value={formik.values.selectedMainCategory}
                  onChange={(e) => {
                    formik.handleChange(e)
                    if (e.target.value === "unselected") {
                      formik2.setFieldValue("mainCategoryId", "")
                      return formik2.setFieldValue("mainCategoryName", "")
                    }

                    formik.setFieldValue("selectedSubCategory", "unselected")
                    formik2.setFieldValue("subCategoryName", "")
                    const finded = mainCategoryList.find(
                      ({ id }) => id === e.target.value,
                    )
                    if (!finded) return
                    formik2.setFieldValue("mainCategoryId", finded.id)
                    formik2.setFieldValue("mainCategoryName", finded.name)
                  }}
                  inputProps={{
                    required: true,
                    id: "selectedMainCategory",
                    name: "selectedMainCategory",
                  }}>
                  <MenuItem value="unselected">메인 카테고리 선택</MenuItem>
                  {mainCategoryList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.selectedMainCategory &&
                  Boolean(formik.errors.selectedMainCategory) && (
                    <FormHelperText error style={{ textAlign: "center" }}>
                      {formik.errors.selectedMainCategory}
                    </FormHelperText>
                  )}
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={10}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "8rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                메인 카테고리 이름
              </Typography>
              <CustomInput
                error={
                  formik2.touched.mainCategoryName &&
                  Boolean(formik2.errors.mainCategoryName)
                }
                helperText={
                  formik2.touched.mainCategoryName &&
                  formik2.errors.mainCategoryName
                }
                inputProps={{
                  placeholder: "메인 카테고리 이름",
                  name: "mainCategoryName",
                  value: formik2.values.mainCategoryName,
                  onChange: formik2.handleChange,
                  error:
                    formik2.touched.mainCategoryName &&
                    Boolean(formik2.errors.mainCategoryName),
                }}
                formControlProps={{
                  fullWidth: true,
                  style: { margin: 0, padding: 0 },
                  error:
                    formik2.touched.mainCategoryName &&
                    Boolean(formik2.errors.mainCategoryName),
                }}
              />
            </GridItem>
          </GridContainer>
          {errMsg && (
            <GridContainer>
              <GridItem>
                <FormHelperText error style={{ textAlign: "center" }}>
                  {errMsg}
                </FormHelperText>
              </GridItem>
            </GridContainer>
          )}
          <Typography
            style={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.5em",
            }}>
            서브
          </Typography>
          <GridContainer style={{ display: "flex", justifyContent: "end" }}>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={
                  formik.values.selectedMainCategory === "unselected" ||
                  formik.values.selectedSubCategory !== "unselected"
                }
                fullWidth
                color="info"
                onClick={async () => {
                  formik2.setTouched({
                    mainCategory: true,
                    subCategoryName: true,
                  })
                  const errors = await formik2.validateForm()
                  if (!errors.mainCategory && !errors.subCategoryName) {
                    createSubCategoryMutation.mutateAsync({
                      categoryId: formik2.values.mainCategoryId,
                      name: formik2.values.subCategoryName,
                    })
                  }
                }}>
                서브 카테고리 생성
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={
                  formik.values.selectedMainCategory === "unselected" ||
                  formik.values.selectedSubCategory === "unselected"
                }
                fullWidth
                color="primary"
                onClick={async () => {
                  formik2.setTouched({
                    subCategoryName: true,
                  })
                  const errors = await formik2.validateForm()
                  if (!errors.subCategoryName) {
                    updateSubCategoryMutation.mutateAsync({
                      subCategoryId: formik2.values.subCategoryId,
                      name: formik2.values.subCategoryName,
                    })
                  }
                }}>
                서브 카테고리 수정
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={
                  formik.values.selectedMainCategory === "unselected" ||
                  formik.values.selectedSubCategory === "unselected"
                }
                fullWidth
                color="danger"
                onClick={() => {
                  deleteSubCategoryMutation.mutateAsync(
                    formik2.values.subCategoryId,
                  )
                }}>
                서브 카테고리 삭제
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={10}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "8rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                서브 카테고리
              </Typography>
              <FormControl
                fullWidth
                className={
                  formik.touched.selectedSubCategory &&
                  Boolean(formik.errors.selectedSubCategory)
                    ? selectClasses.selectFormControlError
                    : selectClasses.selectFormControl
                }
                error={
                  formik.touched.selectedSubCategory &&
                  Boolean(formik.errors.selectedSubCategory)
                }>
                <Select
                  MenuProps={{
                    className: selectClasses.selectMenu,
                  }}
                  classes={{
                    select: selectClasses.select,
                  }}
                  value={formik.values.selectedSubCategory}
                  onChange={(e) => {
                    formik.handleChange(e)

                    if (e.target.value === "unselected") {
                      formik2.setFieldValue("subCategoryId", "")
                      return formik2.setFieldValue("subCategoryName", "")
                    }

                    const finded = subCategoryList.find(
                      ({ id }) => id === e.target.value,
                    )
                    if (!finded) return
                    formik2.setFieldValue("subCategoryId", finded.id)
                    formik2.setFieldValue("subCategoryName", finded.name)
                  }}
                  inputProps={{
                    required: true,
                    id: "selectedSubCategory",
                    name: "selectedSubCategory",
                  }}>
                  <MenuItem value="unselected">서브 카테고리 선택</MenuItem>
                  {subCategoryList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.selectedSubCategory &&
                  Boolean(formik.errors.selectedSubCategory) && (
                    <FormHelperText error style={{ textAlign: "center" }}>
                      {formik.errors.selectedSubCategory}
                    </FormHelperText>
                  )}
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={10}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "8rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                서브 카테고리 이름
              </Typography>
              <CustomInput
                error={
                  formik2.touched.subCategoryName &&
                  Boolean(formik2.errors.subCategoryName)
                }
                helperText={
                  formik2.touched.subCategoryName &&
                  formik2.errors.subCategoryName
                }
                inputProps={{
                  placeholder: "서브 카테고리 이름",
                  name: "subCategoryName",
                  value: formik2.values.subCategoryName,
                  onChange: formik2.handleChange,
                  error:
                    formik2.touched.subCategoryName &&
                    Boolean(formik2.errors.subCategoryName),
                }}
                formControlProps={{
                  fullWidth: true,
                  style: { margin: 0, padding: 0 },
                  error:
                    formik2.touched.subCategoryName &&
                    Boolean(formik2.errors.subCategoryName),
                }}
              />
            </GridItem>
          </GridContainer>
        </div>
      </Paper>
    </main>
  )
}
