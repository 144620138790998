import { Paper } from "@material-ui/core"
import React from "react"
import { useStyles } from "./styles"
import UserDetail from "./UserDetail"
import UserList from "./UserList"

export default function SectionContent() {
  const detailRef = React.useRef()
  const classes = useStyles()
  const [selectedUser, setSelectedUser] = React.useState()

  const handleClickUserClear = () => {
    setSelectedUser()
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper elevation={0} variant="outlined" style={{ padding: "1rem" }}>
        <UserList detailRef={detailRef} onClickRow={setSelectedUser} />
      </Paper>
      <Paper
        elevation={0}
        variant="outlined"
        style={{ padding: "1rem", marginTop: "1rem" }}>
        <UserDetail
          detailRef={detailRef}
          selectedUser={selectedUser}
          handleClickUserClear={handleClickUserClear}
        />
      </Paper>
    </main>
  )
}
