import { Typography } from "@material-ui/core"

import PropTypes from "prop-types"
import React from "react"
import DashboardDetailComment from "./DashboardDetailComment"
import DashboardDetailParts from "./DashboardDetailParts"
import { useMutation, useQuery } from "@tanstack/react-query"
import getAdminQuotationPart from "api/adminQuotation/getAdminQuotationPart"

import { useFormik } from "formik"
import * as yup from "yup"
import getAdminQuotationComment from "api/adminQuotation/getAdminQuotationComment"
import getCacheData from "api/cache/getCacheData"
import setCacheData from "api/cache/setCacheData"

const QuotationPartSchema = yup.object().shape({
  comment: yup.string(),
  fkPart: yup.string(),
  fkQuotation: yup.string(),
  id: yup.string(),
  orderQuantity: yup.number(),
  quotationQuantity: yup.number(),
  unitPrice: yup.number(),
})

const BrandSchema = yup.object().shape({
  code: yup.string(),
  id: yup.string(),
  name: yup.string(),
})

const PartSchema = yup.object().shape({
  code: yup.string(),
  detail: yup.string(),
  fkPartBrand: yup.string(),
  fkSubCategory: yup.string(),
  id: yup.string(),
  isHidden: yup.number(),
  name: yup.string(),
})

const UpdatedPartsSchema = yup.object().shape({
  quotationPart: QuotationPartSchema,
  rel: yup.object().shape({
    brand: BrandSchema,
    part: PartSchema,
  }),
})

const validationSchema = yup.object({
  status: yup.string("Enter your status").required("status is required"),
  currency: yup
    .string("Enter your currency")
    .required("currency is required")
    .notOneOf(["unselected", "1"], "currency is required"),
  comment: yup.string("Enter your comment").required("comment is required"),
  updatedParts: yup.array().of(UpdatedPartsSchema),
})

export default function DashboardDetail({ selectedQuotation, detailRef }) {
  const [commentDataList, setCommentDataList] = React.useState([])

  const formik = useFormik({
    initialValues: {
      currency: "unselected",
      updatedParts: [],
    },
    validationSchema: validationSchema,
  })

  const {
    isLoading: isGetAdminQuotationPartLoading,
    data: getAdminQuotationPartResponse,
    // refetch: refetchQuotation,
  } = useQuery(
    [
      `admin_quotation_part_${
        selectedQuotation && selectedQuotation.quotation.id
      }`,
      selectedQuotation && selectedQuotation.quotation.id,
    ],
    ({ queryKey }) => getAdminQuotationPart(queryKey[1]),
    { enabled: !!selectedQuotation && !!selectedQuotation.quotation.id },
  )

  const { data: getCacheDataResponse } = useQuery(
    [
      `admin_cached_part_list_${
        selectedQuotation && selectedQuotation.quotation.id
      }`,
      selectedQuotation && selectedQuotation.quotation.id,
    ],
    ({ queryKey }) => getCacheData(queryKey[1]),
    { enabled: !!selectedQuotation && !!selectedQuotation.quotation.id },
  )

  const {
    isLoading: isGetAdminQuotationCommentLoading,
    data: getAdminQuotationCommentResponse,
  } = useQuery(
    [
      `admin_quotation_comment_${
        selectedQuotation && selectedQuotation.quotation.id
      }`,
      selectedQuotation && selectedQuotation.quotation.id,
    ],
    ({ queryKey }) => getAdminQuotationComment(queryKey[1]),
    { enabled: !!selectedQuotation && !!selectedQuotation.quotation.id },
  )

  const setCacheDataMutation = useMutation(setCacheData)

  React.useEffect(() => {
    if (
      selectedQuotation &&
      getCacheDataResponse &&
      getAdminQuotationPartResponse &&
      getAdminQuotationPartResponse.data
    ) {
      const setCechedPartListAsync = async () => {
        if (getCacheDataResponse.status === 200) {
          formik.setFieldValue(
            "currency",
            getCacheDataResponse.data.cache.data.currency,
          )
          formik.setFieldValue(
            "updatedParts",
            getCacheDataResponse.data.cache.data.updatedParts,
          )
        }
        if (getCacheDataResponse.status === 204) {
          const response = await setCacheDataMutation.mutateAsync({
            quotationId: selectedQuotation.quotation.id,
            data: {
              currency: selectedQuotation.quotation.currency || "unselected",
              updatedParts: getAdminQuotationPartResponse.data.quotationParts,
            },
          })

          formik.setFieldValue("currency", response.data.cache.data.currency)
          formik.setFieldValue(
            "updatedParts",
            response.data.cache.data.updatedParts,
          )
        }
      }
      setCechedPartListAsync()
    }
  }, [selectedQuotation, getAdminQuotationPartResponse, getCacheDataResponse])

  React.useEffect(() => {
    if (
      getAdminQuotationCommentResponse &&
      getAdminQuotationCommentResponse.data
    ) {
      setCommentDataList(
        getAdminQuotationCommentResponse.data.quotationComments,
      )
    }
  }, [getAdminQuotationCommentResponse])

  React.useEffect(() => {
    if (selectedQuotation) {
      formik.setFieldValue(
        "currency",
        selectedQuotation.quotation.currency || "unselected",
      )
      formik.setFieldValue("status", selectedQuotation.quotation.status)
    }
  }, [selectedQuotation])

  return (
    <>
      <div ref={detailRef} />
      <div
        style={{
          marginTop: "3rem",
        }}>
        {selectedQuotation ? (
          <>
            <Typography
              id="detail"
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              견적 요청 상세
            </Typography>
            {isGetAdminQuotationPartLoading ? null : (
              <DashboardDetailParts
                formik={formik}
                quotationId={selectedQuotation.quotation.id}
              />
            )}
            {isGetAdminQuotationCommentLoading ? null : (
              <DashboardDetailComment
                formik={formik}
                // refetchQuotation={refetchQuotation}
                quotationId={selectedQuotation.quotation.id}
                commentDataList={commentDataList}
                hasPartIds={formik.values.updatedParts.map((part) => {
                  const { rel } = part
                  return rel.part ? rel.part.id : part
                })}
              />
            )}
          </>
        ) : null}
      </div>
    </>
  )
}

DashboardDetail.propTypes = {
  selectedQuotation: PropTypes.object,
  detailRef: PropTypes.object,
  handleClickQuotationClear: PropTypes.func,
}
