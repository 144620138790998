import customAxiosInstance from "api/axios"

/**
 * @params {
 *    quotationId: string,
 *    data: object<any>
 * }
 */
export const setCacheData = async ({ quotationId = "", data }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/cache`,
      data: { id: quotationId, data },
    })
  } catch (e) {
    console.error(e)
  }
}

export default setCacheData
