import React from "react"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import service_process from "assets/img/service_process.png"

export default function SectionContent() {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        style={{
          padding: "0 1rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          paddingBottom: "2rem",
        }}>
        <GridContainer
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}>
          <GridItem xs={12} sm={10} md={10}>
            <h5 style={{ textAlign: "start", margin: 0, color: "#717171" }}>
              4-step process
            </h5>
            <h3 style={{ textAlign: "start", margin: 0, color: "#545556" }}>
              HOW TO USE THIS PAGE
            </h3>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <GridItem xs={12} sm={10} md={10}>
            <img
              alt="..."
              src={service_process}
              style={{
                width: "100%",
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
