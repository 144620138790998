import React from "react"
import PropTypes from "prop-types"

import ModalImage from "react-modal-image"

export default function PreviewImgItem({ file }) {
  const [src, setSrc] = React.useState("")
  React.useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          setSrc(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  return src ? (
    <ModalImage small={src} medium={src} large={src} alt="preview" />
  ) : null
}

PreviewImgItem.propTypes = {
  file: PropTypes.any,
}
