import React from "react"
import PropTypes from "prop-types"

import { useMutation } from "@tanstack/react-query"
import CustomInput from "components/CustomInput/CustomInput"
import updatePartInCart from "api/cart/updatePartInCart"

export default function CartItemUpdateForm({
  defaultQuantity = 1,
  userId,
  cartId,
}) {
  const [quantity, setQuantity] = React.useState(defaultQuantity)
  const updatePartInCartMutation = useMutation(updatePartInCart)

  return (
    <span
      key={1}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <CustomInput
        variant="outlined"
        formControlProps={{
          style: {
            padding: 0,
            margin: 0,
          },
        }}
        inputProps={{
          style: {
            textAlign: "center",
            maxWidth: "8rem",
            padding: "2px",
            paddingLeft: "0.45rem",
          },
          value: quantity,
          onChange: async (e) => {
            setQuantity(Number(e.target.value))
            if (userId)
              await updatePartInCartMutation.mutateAsync({
                userId,
                partId: cartId,
                quantity: Number(e.target.value) || quantity,
              })
            else {
              const prevCartItems =
                JSON.parse(localStorage.getItem("cartItems")) || []

              if (!prevCartItems) localStorage.setItem("cartItems", [])

              const news =
                prevCartItems.length > 0
                  ? prevCartItems.map((prev) => {
                      const { part } = prev
                      if (part.id === cartId)
                        return {
                          ...prev,
                          part: {
                            ...part,
                            quantity: Number(e.target.value),
                          },
                        }
                      return prev
                    })
                  : []
              localStorage.setItem("cartItems", JSON.stringify(news))
            }
          },
          type: "number",
          min: 1,
        }}
      />
    </span>
  )
}

CartItemUpdateForm.propTypes = {
  defaultQuantity: PropTypes.number,
  userId: PropTypes.string,
  cartId: PropTypes.string,
}
