import customAxiosInstance from "api/axios"

/**
 * @params {
 *    userId: string,
 *    fkPart: string,
 *    quantity: number
 * }
 */
export const addPartToCart = async ({ userId = "", fkPart = "", quantity }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/users/${userId}/carts`,
      data: { fkPart, quantity },
    })
  } catch (e) {
    console.error(e)
  }
}

export default addPartToCart
