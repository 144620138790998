import React from "react"
import { useHistory } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"
import { InputAdornment, List, ListItem, Tooltip } from "@material-ui/core"
import { Search, Person } from "@material-ui/icons"
import * as yup from "yup"

import logo from "assets/img/logo.png"

import Header from "components/Header/Header.js"
import Button from "components/CustomButtons/Button.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import { grayColor, hexToRgb } from "assets/jss/material-kit-pro-react.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown"
import LoginForm from "components/LoginForm/LoginForm"

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js"
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js"

import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"
import { useQuery } from "@tanstack/react-query"
import HomeHeaderCarousel from "./HomeHeaderCarousel"
import getCart from "api/cart/getCart"
import Parallax from "components/Parallax/Parallax"
import { useFormik } from "formik"
import useWindowDimensions from "hooks/useWindowDimensions"

const validationSchema = yup.object({
  search: yup.string("Enter your search"),
})

export const useLoginTooltipStyles = makeStyles(() => ({
  tooltip: {
    background: "#fff",
    minWidth: "300px",
    borderRadius: 0,
    border: "1px solid #d2d2d2",
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
  },
  popper: {},
}))

const useHeadersStyles = makeStyles(headersStyle)
const useNavbarsStyle = makeStyles(navbarsStyle)

export default function HomeHeader({ ...rest }) {
  const history = useHistory()
  const { width } = useWindowDimensions()
  const [userId, setUserId] = React.useState("")
  const [cartCountAsync, setCartCountAsync] = React.useState(0)
  const [cartCountLocal, setCartCountLocal] = React.useState(0)
  const [openLoginTooltip, setOpenLoginTooltip] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.search)
        history.push(
          `/products?name=${values.search}&code=${values.search}&detail=${values.search}`,
        )
      if (history.location.pathname === "/products") {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
      }
    },
  })

  const { isLoading, data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )

  const { data: getCartResponse } = useQuery(["cart"], () => getCart(userId), {
    enabled: !!userId,
  })

  const classesNavbars = useNavbarsStyle()
  const classesHeaders = useHeadersStyles()
  const classesLoginTooltipStyles = useLoginTooltipStyles()

  const handleLoginTooltipOpen = () => {
    setOpenLoginTooltip(true)
  }

  const handleLoginTooltipClose = () => {
    setOpenLoginTooltip(false)
  }

  React.useEffect(() => {
    const prevCartItems = JSON.parse(localStorage.getItem("cartItems")) || []
    setCartCountLocal(prevCartItems.length)
  }, [])

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUserId(verifyTokenAndGetUserInfoResponse.data.user.id)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  React.useEffect(() => {
    if (getCartResponse && getCartResponse.data) {
      setCartCountAsync(getCartResponse.data.carts.length)
    }
  }, [getCartResponse])

  return (
    <div className="cd-section" {...rest}>
      <Header
        fixed
        changeColorOnScroll={{ color: "primary", height: 300 }}
        brand={
          <img src={logo} alt="..." style={{ width: "32px", height: "32px" }} />
        }
        color="primary"
        links={
          <div className={classesHeaders.collapse}>
            <form
              onSubmit={formik.handleSubmit}
              style={width < 960 ? { marginTop: "3rem" } : { margin: 0 }}>
              <div className={classesHeaders.listWithSearch}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    focused: false,
                    className: classesNavbars.formControl,
                    error:
                      formik.touched.search && Boolean(formik.errors.search),
                    style: {
                      "&:before": {
                        borderBottom: "0",
                      },
                    },
                  }}
                  error={formik.touched.search && Boolean(formik.errors.search)}
                  helperText={formik.touched.search && formik.errors.search}
                  inputProps={{
                    required: true,
                    placeholder: "Part number or name",
                    name: "search",
                    value: formik.values.search,
                    onChange: formik.handleChange,
                    className: classesNavbars.searchInput,
                    style: {
                      color: width < 960 ? "#000" : "#fff",
                    },
                    inputProps: {
                      "aria-label": "Search",
                      className: classesNavbars.searchInput,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button justIcon simple type="submit">
                          <Search
                            className={classesNavbars.searchIcon}
                            style={{ color: width < 960 ? "#000" : "#fff" }}
                          />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </form>
            {isLoading ? null : (
              <List
                className={classesHeaders.list + " " + classesHeaders.mlAuto}>
                <ListItem
                  className={classesHeaders.listItem}
                  style={{ color: width < 960 ? "#000" : "#fff" }}>
                  <Button
                    className={classesHeaders.navLink}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}
                    color="transparent">
                    PRODUCTS
                  </Button>
                </ListItem>
                {verifyTokenAndGetUserInfoResponse ? (
                  <>
                    <ListItem
                      className={classesHeaders.listItem}
                      style={{ color: width < 960 ? "#000" : "#fff" }}>
                      <Button
                        className={classesHeaders.navLink}
                        onClick={(e) => {
                          e.preventDefault()
                          localStorage.setItem("accessToken", "")
                          if (history.location.pathname !== "/")
                            history.push("/")
                          window.location.reload()
                        }}
                        color="transparent">
                        LOGOUT
                      </Button>
                    </ListItem>
                    <ListItem
                      className={classesHeaders.listItem}
                      style={{ color: width < 960 ? "#000" : "#fff" }}>
                      <CustomDropdown
                        buttonText="MY PAGE"
                        buttonProps={{
                          color: "transparent",
                          className: classesHeaders.navLink,
                        }}
                        dropdownList={[
                          "MY ACCOUNT",
                          { divider: true },
                          "MY QUOTATION",
                        ]}
                        onClick={(path) => {
                          if (path === "MY ACCOUNT")
                            history.push("/my-page/my-account")
                          if (path === "MY QUOTATION")
                            history.push("/my-page/my-quotation")
                        }}
                      />
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem
                      className={classesHeaders.listItem}
                      style={{ color: width < 960 ? "#000" : "#fff" }}>
                      <Button
                        className={classesHeaders.navLink}
                        onClick={(e) => {
                          e.preventDefault()
                          history.push("/join")
                        }}
                        color="transparent">
                        JOIN
                      </Button>
                    </ListItem>
                    <ListItem
                      className={classesHeaders.listItem}
                      style={{ color: width < 960 ? "#000" : "#fff" }}>
                      <Tooltip
                        title={
                          <LoginForm
                            handleOpen={handleLoginTooltipOpen}
                            handleClose={handleLoginTooltipClose}
                          />
                        }
                        classes={{
                          tooltip: classesLoginTooltipStyles.tooltip,
                          popper: classesLoginTooltipStyles.popper,
                        }}
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="bottom"
                        arrow
                        interactive
                        onClose={handleLoginTooltipClose}
                        open={openLoginTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener>
                        <Button
                          color={"transparent"}
                          className={classesHeaders.navLink}
                          onClick={handleLoginTooltipOpen}>
                          <Person />
                          LOGIN
                          <b
                            style={{
                              transition: "all 150ms ease-in",
                              display: "inline-block",
                              width: "0",
                              height: "0",
                              marginLeft: "4px",
                              verticalAlign: "middle",
                              borderTop: "4px solid",
                              borderRight: "4px solid transparent",
                              borderLeft: "4px solid transparent",
                              transform: openLoginTooltip
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                  </>
                )}
                <ListItem
                  className={classesHeaders.listItem}
                  style={{ color: width < 960 ? "#000" : "#fff" }}>
                  <Button
                    className={classesHeaders.navLink}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/cart")
                    }}
                    color="transparent">
                    {`CART(${userId ? cartCountAsync : cartCountLocal})`}
                  </Button>
                </ListItem>
              </List>
            )}
          </div>
        }
      />
      {history.location.pathname === "/" ? (
        <HomeHeaderCarousel />
      ) : (
        <Parallax
          image={require("assets/img/examples/bg2.jpg")}
          filter="dark"
          small
        />
      )}
    </div>
  )
}
