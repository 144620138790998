import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import ModalImage from "react-modal-image"
import PropTypes from "prop-types"

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

export default function CartItemImg(props) {
  const classesShoppingCart = useStylesShoppingCart()
  return (
    <div className={classesShoppingCart.imgContainer}>
      <ModalImage {...props} className={classesShoppingCart.img} />
    </div>
  )
}

CartItemImg.propTypes = {
  small: PropTypes.string,
  medium: PropTypes.string,
  large: PropTypes.string,
  alt: PropTypes.string,
}
