import customAxiosInstance from "api/axios"

/**
 * @params {
 *    limit: number,
 *    offset: number,
 *    name: string,
 *    code: string,
 *    detail: string,
 *    include_hidden: string
 *    fk_category: string,
 *    fk_sub_category: string,
 *    fk_part_brand: string,
 * }
 */
export const getPart = async ({
  limit = 10,
  offset = 0,
  name,
  code,
  detail,
  include_hidden,
  fk_category,
  fk_sub_category,
  fk_part_brand,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/parts`,
      params: {
        limit,
        offset,
        name,
        code,
        detail,
        include_hidden,
        fk_category,
        fk_sub_category,
        fk_part_brand,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default getPart
