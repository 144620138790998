import React from "react"
import { Link } from "react-router-dom"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
import Close from "@material-ui/icons/Close"
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js"
import { hexToRgb } from "assets/jss/material-kit-pro-react"
import { blackColor } from "assets/jss/material-kit-pro-react"
// import { TramRounded } from "@material-ui/icons";

const useStyles = makeStyles(styles)

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const classes = useStyles()
  const [isScrolled, setIsScrolled] = React.useState(false)

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange)
      }
    }
  })
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { changeColorOnScroll } = props

    const windowsScrollTop = window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      setIsScrolled(true)
      // document.body
      //   .getElementsByTagName("header")[0]
      //   .classList.remove(classes[color]);
      // document.body
      //   .getElementsByTagName("header")[0]
      //   .classList.add(classes[changeColorOnScroll.color]);
    } else {
      setIsScrolled(false)
      // document.body
      //   .getElementsByTagName("header")[0]
      //   .classList.add(classes[color]);
      // document.body
      //   .getElementsByTagName("header")[0]
      //   .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  const { color, links, brand, fixed, absolute } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  })
  return (
    <AppBar
      className={appBarClasses}
      style={{
        top: isScrolled ? 0 : "1rem",
        zIndex: 4,
        borderRadius: isScrolled ? 0 : "2rem",
        width: isScrolled ? "100%" : "calc(90% - 2rem)",
        left: isScrolled ? 0 : "50%",
        transform: isScrolled ? "none" : "translate(-50%,0)",
        boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
      }}>
      <Toolbar className={classes.container}>
        <Link to="/" style={{ display: "flex", justifyContent: "center" }}>
          <Button className={classes.title}>{brand}</Button>
        </Link>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}>
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  )
}

Header.defaultProp = {
  color: "white",
}

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
}
