import customAxiosInstance from "api/axios"

/**
 * @params {
 *    quotationId: string,
 *    quotationPartId: string,
 *    quotationQuantity: number,
 *    unitPrice: number,
 *    comment: string,
 *    orderQuantity: number
 * }
 */
export const updateAdminQuotationPart = async ({
  quotationId = "",
  quotationPartId = "",
  quotationQuantity,
  unitPrice,
  comment,
  orderQuantity,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/quotations/${quotationId}/quotation_parts/${quotationPartId}`,
      data: { quotationQuantity, unitPrice, comment, orderQuantity },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateAdminQuotationPart
