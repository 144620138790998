import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import styles from "assets/jss/material-kit-pro-react/components/tableStyle.js"
import QuotationTableItem from "./QuotationTableItem"
import QuotationDetail from "./QuotationDetail"
import { Divider } from "@material-ui/core"

const useStyles = makeStyles(styles)

export default function QuotationTable(props) {
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
    userId,
  } = props
  const [selectedQuotationRow, setSelectedQuotationRow] = React.useState([])
  const detailRef = React.useRef()

  const classes = useStyles()

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor]}>
            <TableRow className={classes.tableRow}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [customHeadCellClasses[
                      customHeadClassesForCells.indexOf(key)
                    ]]: customHeadClassesForCells.indexOf(key) !== -1,
                    [classes.tableShoppingHead]: tableShopping,
                  })
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            const [
              ,
              quotationId,
              ,
              ,
              quotationCurrency,
              ,
              quotationStatus,
            ] = prop
            let rowColor = ""
            let rowColored = false
            if (prop.color !== undefined) {
              rowColor = prop.color
              rowColored = true
              prop = prop.data
            }
            const tableRowClasses = cx({
              [classes.tableRowHover]: hover,
              [classes[rowColor + "Row"]]: rowColored,
              [classes.tableStripedRow]: striped && key % 2 === 0,
              [classes.tableRowCursorPointer]: true,
            })
            return (
              <QuotationTableItem
                key={key}
                onClickTableData={() => {
                  setSelectedQuotationRow(prop)
                  detailRef.current.scrollIntoView()
                }}
                quotationStatus={quotationStatus}
                userId={userId}
                quotationCurrency={quotationCurrency}
                quotationId={quotationId}
                className={classes.tableRow + " " + tableRowClasses}
                cells={prop}
                {...props}
              />
            )
          })}
        </TableBody>
      </Table>
      <Divider />
      <div ref={detailRef} />
      {selectedQuotationRow.length > 0 && (
        <QuotationDetail
          detailRef={detailRef}
          selectedQuotationRow={selectedQuotationRow}
          userId={userId}
          {...props}
        />
      )}
    </div>
  )
}

QuotationTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  detailCustomHeadCellClasses: [],
  detailCustomHeadClassesForCells: [],
  detailCustomCellClasses: [],
  detailCustomClassesForCells: [],
}

QuotationTable.propTypes = {
  userId: PropTypes.string,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  detailTableHead: PropTypes.arrayOf(PropTypes.string),
  detailTableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
}
