import React from "react"
import PropTypes from "prop-types"
import getCacheData from "api/cache/getCacheData"
import { useQuery } from "@tanstack/react-query"

export default function QuotationCurrency({ quotationId }) {
  const [currency, setCurrency] = React.useState("-")
  const { data: getCacheDataResponse } = useQuery(
    [`admin_cached_part_list_${quotationId}`, quotationId],
    ({ queryKey }) => getCacheData(queryKey[1]),
    { enabled: !!quotationId },
  )

  React.useEffect(() => {
    if (getCacheDataResponse && getCacheDataResponse.data) {
      setCurrency(
        getCacheDataResponse.data.cache.data.currency &&
          getCacheDataResponse.data.cache.data.currency !== "unselected" &&
          getCacheDataResponse.data.cache.data.currency !== "1"
          ? getCacheDataResponse.data.cache.data.currency
          : "-",
      )
    }
  }, [getCacheDataResponse])

  return currency
}

QuotationCurrency.propTypes = {
  quotationId: PropTypes.string,
}
