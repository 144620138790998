import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import * as yup from "yup"

import {
  IconButton,
  Typography,
  InputAdornment,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core"
import { Close, Person, Lock } from "@material-ui/icons"

import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import { useMutation } from "@tanstack/react-query"
import login from "api/auth/login"

const validationSchema = yup.object({
  account: yup.string("Enter your account").required("Account is required"),
  password: yup.string("Enter your password").required("Password is required"),
})

export default function LoginForm({ handleClose }) {
  const history = useHistory()
  const [submitErrMsg, setSubmitErrMsg] = React.useState("")

  const formik = useFormik({
    initialValues: {
      account: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loginMutation.mutateAsync(values)
    },
  })

  const loginMutation = useMutation(login, {
    onSuccess: ({ data }) => {
      setSubmitErrMsg("")
      localStorage.setItem("accessToken", data.accessToken)
      window.location.reload()
    },
    onError: (e) => {
      console.error(e)
    },
  })

  React.useEffect(() => {
    if (loginMutation.error) {
      if (loginMutation.error.response.status === 401)
        setSubmitErrMsg(
          "Invalid authentication information. Please check and try again.",
        )
    }
  }, [loginMutation.error])

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ padding: "4px 8px", margin: 0 }}>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1rem",
        }}>
        <GridItem xs={10} sm={10} md={10}>
          <Typography
            style={{
              color: "#828282",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: "0.8rem",
              fontWeight: "400",
            }}>
            Sign in with your credentials below
          </Typography>
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
          <IconButton
            size="small"
            onClick={handleClose}
            style={{ width: "24px", height: "24px" }}>
            <Close color="action" />
          </IconButton>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginTop: "1rem" }}>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            variant="outlined"
            formControlProps={{
              fullWidth: true,
              style: {
                margin: "0.2rem 0",
                padding: 0,
              },
              error: formik.touched.account && Boolean(formik.errors.account),
            }}
            error={formik.touched.account && Boolean(formik.errors.account)}
            helperText={formik.touched.account && formik.errors.account}
            inputProps={{
              required: true,
              placeholder: "Account",
              name: "account",
              value: formik.values.account,
              onChange: formik.handleChange,
              style: { padding: "4px 2px" },
              startAdornment: (
                <InputAdornment position="start">
                  <Button justIcon simple disabled>
                    <Person style={{ color: "#000000" }} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            variant="outlined"
            formControlProps={{
              required: true,
              fullWidth: true,
              style: {
                margin: "0.2rem 0",
                padding: 0,
              },
              error: formik.touched.password && Boolean(formik.errors.password),
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            inputProps={{
              required: true,
              placeholder: "Password",
              name: "password",
              type: "password",
              autoComplete: "off",
              value: formik.values.password,
              onChange: formik.handleChange,
              style: { padding: "4px 2px" },
              startAdornment: (
                <InputAdornment position="start">
                  <Button justIcon simple disabled>
                    <Lock style={{ color: "#000000" }} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          {submitErrMsg && (
            <FormHelperText error style={{ textAlign: "center" }}>
              {submitErrMsg}
            </FormHelperText>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: "flex", justifyContent: "end" }}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ display: "flex", justifyContent: "end" }}>
          <Typography
            style={{
              color: "#00acc1",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: "0.8rem",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/forgot-password")
            }}>
            Forgot Password?
          </Typography>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginTop: "1rem" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            fullWidth
            color="primary"
            type="submit"
            disabled={loginMutation.isLoading}>
            SIGN IN
          </Button>
          {loginMutation.isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </GridItem>
      </GridContainer>
    </form>
  )
}

LoginForm.propTypes = {
  admin: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
}
