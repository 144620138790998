import customAxiosInstance from "api/axios"

// role = ADMIN, MEMBER, CUSTOMER
// short_role = A, M, C

/**
 * @params {
 *    userId: string,
 *    password: string,
 * }
 */
export const updateUserPassword = async ({ userId = "", password }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/users/${userId}/password`,
      data: {
        password,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateUserPassword
