import React from "react"
import SectionContent from "./Sections/SectionContent"

import { CssBaseline } from "@material-ui/core"
import { useStyles } from "./Sections/styles.js"
import AdminHeader from "components/Header/AdminHeader.js"
import { useQuery } from "@tanstack/react-query"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"

export default function AdminPartPage() {
  const classes = useStyles()
  const [userRole, setUserRole] = React.useState("")

  const { data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUserRole(verifyTokenAndGetUserInfoResponse.data.user.role)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AdminHeader />
      {(userRole === "A" || userRole === "M") && <SectionContent />}
    </div>
  )
}
