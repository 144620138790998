/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import "assets/scss/material-kit-pro-react.scss?v=1.9.0"
import "react-intl-tel-input/dist/main.css"
import "semantic-ui-css/semantic.min.css"

// pages for this product
import HomePage from "views/HomePage/HomePage.js"
import ProductsPage from "views/ProductsPage/ProductsPage.js"
import ErrorPage from "views/ErrorPage/ErrorPage.js"
import JoinPage from "views/JoinPage/JoinPage"
import MyAccountPage from "views/MyAccountPage/MyAccountPage"
import MyQuotationPage from "views/MyQuotationPage/MyQuotationPage"
import CartPage from "views/CartPage/CartPage"
import AdminDashboardPage from "views/AdminDashboardPage/AdminDashboardPage"
import AdminUserPage from "views/AdminUserPage/AdminUserPage"
import AdminPartBrandPage from "views/AdminPartBrandPage/AdminPartBrandPage"
import AdminPartPage from "views/AdminPartPage/AdminPartPage"
import AdminCategoryPage from "views/AdminCategoryPage/AdminCategoryPage"
import ForgotPasswordPage from "views/ForgotPasswordPage/ForgotPasswordPage"
import AdminPage from "views/AdminPage/AdminPage"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// Create a client
const queryClient = new QueryClient()

const Routes = () => (
  <QueryClientProvider client={queryClient}>
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route path="/cart">
        <CartPage />
      </Route>
      <Route path="/join">
        <JoinPage />
      </Route>
      <Route path="/products">
        <ProductsPage />
      </Route>
      <Route path="/forgot-password">
        <ForgotPasswordPage />
      </Route>
      <Route path="/my-page/my-account">
        <MyAccountPage />
      </Route>
      <Route path="/my-page/my-quotation">
        <MyQuotationPage />
      </Route>
      <Route exact path="/admin">
        <AdminPage />
      </Route>
      <Route path="/admin/dashboard">
        <AdminDashboardPage />
      </Route>
      <Route path="/admin/part">
        <AdminPartPage />
      </Route>
      <Route path="/admin/user">
        <AdminUserPage />
      </Route>
      <Route path="/admin/system/category">
        <AdminCategoryPage />
      </Route>
      <Route path="/admin/system/part-brand">
        <AdminPartBrandPage />
      </Route>
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  </QueryClientProvider>
)

ReactDOM.render(
  <Router>
    <Routes />
  </Router>,
  document.getElementById("root"),
)
