import customAxiosInstance from "api/axios"

/**
 * @params {
 *  userId: string,
 *  quotationId: string,
 *  comment: string
 * }
 */
export const createUserQuotationComment = async ({
  userId = "",
  quotationId = "",
  comment,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/users/${userId}/quotations/${quotationId}/comments`,
      data: { comment },
    })
  } catch (e) {
    console.error(e)
  }
}

export default createUserQuotationComment
