import React from "react"
import Carousel from "react-slick"
import { useHistory } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"

import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import useWindowDimensions from "hooks/useWindowDimensions"

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js"

import placeholderbg from "assets/img/custom_image_placeholder.png"
import custombg1source from "assets/img/custom-bg-1.png"
import custombg2source from "assets/img/custom-bg-2.png"
import custombg3source from "assets/img/custom-bg-3.png"
import custombg4source from "assets/img/custom-bg-4.png"
import custombg5source from "assets/img/custom-bg-5.png"
import custombg6source from "assets/img/custom-bg-6.png"
import useProgressiveImage from "hooks/useProgressiveImage"
import { hexToRgb } from "assets/jss/material-kit-pro-react"
import { blackColor } from "assets/jss/material-kit-pro-react"

const useHeadersStyles = makeStyles(headersStyle)

export default function HomeHeaderCarousel() {
  const custombg1 = useProgressiveImage(custombg1source)
  const custombg2 = useProgressiveImage(custombg2source)
  const custombg3 = useProgressiveImage(custombg3source)
  const custombg4 = useProgressiveImage(custombg4source)
  const custombg5 = useProgressiveImage(custombg5source)
  const custombg6 = useProgressiveImage(custombg6source)
  const history = useHistory()
  const { width } = useWindowDimensions()
  const classesHeaders = useHeadersStyles()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  return (
    <Carousel {...settings}>
      <div>
        <div
          className={classesHeaders.pageHeader}
          style={{ backgroundImage: `url("${custombg1 || placeholderbg}")` }}>
          <div className={classesHeaders.container}>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <GridItem xs={8} sm={8} md={10}>
                <h3
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "1rem" : "2rem",
                    margin: 0,
                    textAlign: "center",
                  }}>
                  SINCE 2004
                </h3>
                <h1
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "2rem" : "3rem",
                    marginTop: "8px",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}>
                  E-CHEOL AUTOPARTS
                </h1>
                <h4
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "0.8rem" : "1rem",
                    textAlign: "center",
                  }}>
                  Our Company opened in 2004 and provide good quality products
                  at reasonable prices. We handle parts for Hyundai, Kia, GM
                  Daewoo, Ssangyong, and Samsung as well as parts for imported
                  vehicles.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="secondary"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      boxShadow:
                        "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}>
                    View Products
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div>
        <div
          className={classesHeaders.pageHeader}
          style={{ backgroundImage: `url("${custombg2 || placeholderbg}")` }}>
          <div className={classesHeaders.container}>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <GridItem xs={8} sm={8} md={10}>
                <h3
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "1rem" : "2rem",
                    margin: 0,
                    textAlign: "center",
                  }}>
                  SINCE 2004
                </h3>
                <h1
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "2rem" : "3rem",
                    marginTop: "8px",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}>
                  E-CHEOL AUTOPARTS
                </h1>
                <h4
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "0.8rem" : "1rem",
                    textAlign: "center",
                  }}>
                  Our Company opened in 2004 and provide good quality products
                  at reasonable prices. We handle parts for Hyundai, Kia, GM
                  Daewoo, Ssangyong, and Samsung as well as parts for imported
                  vehicles.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="secondary"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      boxShadow:
                        "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}>
                    View Products
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div>
        <div
          className={classesHeaders.pageHeader}
          style={{ backgroundImage: `url("${custombg3 || placeholderbg}")` }}>
          <div className={classesHeaders.container}>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <GridItem xs={8} sm={8} md={10}>
                <h3
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "1rem" : "2rem",
                    margin: 0,
                    textAlign: "center",
                  }}>
                  SINCE 2004
                </h3>
                <h1
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "2rem" : "3rem",
                    marginTop: "8px",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}>
                  E-CHEOL AUTOPARTS
                </h1>
                <h4
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "0.8rem" : "1rem",
                    textAlign: "center",
                  }}>
                  Our Company opened in 2004 and provide good quality products
                  at reasonable prices. We handle parts for Hyundai, Kia, GM
                  Daewoo, Ssangyong, and Samsung as well as parts for imported
                  vehicles.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="secondary"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      boxShadow:
                        "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}>
                    View Products
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div>
        <div
          className={classesHeaders.pageHeader}
          style={{ backgroundImage: `url("${custombg4 || placeholderbg}")` }}>
          <div className={classesHeaders.container}>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <GridItem xs={8} sm={8} md={10}>
                <h3
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "1rem" : "2rem",
                    margin: 0,
                    textAlign: "center",
                  }}>
                  SINCE 2004
                </h3>
                <h1
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "2rem" : "3rem",
                    marginTop: "8px",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}>
                  E-CHEOL AUTOPARTS
                </h1>
                <h4
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "0.8rem" : "1rem",
                    textAlign: "center",
                  }}>
                  Our Company opened in 2004 and provide good quality products
                  at reasonable prices. We handle parts for Hyundai, Kia, GM
                  Daewoo, Ssangyong, and Samsung as well as parts for imported
                  vehicles.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="secondary"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      boxShadow:
                        "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}>
                    View Products
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div>
        <div
          className={classesHeaders.pageHeader}
          style={{ backgroundImage: `url("${custombg5 || placeholderbg}")` }}>
          <div className={classesHeaders.container}>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <GridItem xs={8} sm={8} md={10}>
                <h3
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "1rem" : "2rem",
                    margin: 0,
                    textAlign: "center",
                  }}>
                  SINCE 2004
                </h3>
                <h1
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "2rem" : "3rem",
                    marginTop: "8px",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}>
                  E-CHEOL AUTOPARTS
                </h1>
                <h4
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "0.8rem" : "1rem",
                    textAlign: "center",
                  }}>
                  Our Company opened in 2004 and provide good quality products
                  at reasonable prices. We handle parts for Hyundai, Kia, GM
                  Daewoo, Ssangyong, and Samsung as well as parts for imported
                  vehicles.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="secondary"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      boxShadow:
                        "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}>
                    View Products
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div>
        <div
          className={classesHeaders.pageHeader}
          style={{ backgroundImage: `url("${custombg6 || placeholderbg}")` }}>
          <div className={classesHeaders.container}>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <GridItem xs={8} sm={8} md={10}>
                <h3
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "1rem" : "2rem",
                    margin: 0,
                    textAlign: "center",
                  }}>
                  SINCE 2004
                </h3>
                <h1
                  className={classesHeaders.title}
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "2rem" : "3rem",
                    marginTop: "8px",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}>
                  E-CHEOL AUTOPARTS
                </h1>
                <h4
                  style={{
                    color: "#fff",
                    fontSize: width < 600 ? "0.8rem" : "1rem",
                    textAlign: "center",
                  }}>
                  Our Company opened in 2004 and provide good quality products
                  at reasonable prices. We handle parts for Hyundai, Kia, GM
                  Daewoo, Ssangyong, and Samsung as well as parts for imported
                  vehicles.
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="secondary"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      boxShadow:
                        "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.8)",
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/products")
                    }}>
                    View Products
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Carousel>
  )
}
