import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"

const useStyles = makeStyles(featuresStyle)

export default function SectionContent() {
  const classes = useStyles()
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-2rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          padding: "0 2rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          paddingBottom: "2rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}>
            <h2 className={classes.title}>THIS IS ERROR PAGE</h2>
            {/* <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              product. Keep you user engaged by providing meaningful
              information. Remember that by this time, the user is curious,
              otherwise he wouldn{"'"}t scroll to get here. Add a button if you
              want the user to see more.
            </h5> */}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
