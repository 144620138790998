import customAxiosInstance from "api/axios"

/**
 * @params { userId: string, quotationId: string }
 */
export const getUserQuotationComment = async ({
  userId = "",
  quotationId = "",
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/users/${userId}/quotations/${quotationId}/comments`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default getUserQuotationComment
