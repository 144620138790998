import customAxiosInstance from "api/axios"

/**
 * @params {
 *    quotationId: string
 * }
 */
export const getCacheData = async (quotationId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/cache/${quotationId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default getCacheData
