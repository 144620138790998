import * as yup from "yup"

export const accountEditFormValidationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .required("Last name is required"),
  id: yup.string("Enter your id").required("ID is required"),
  password: yup.string("Enter your password"),
  confirmPassword: yup
    .string("Enter your Confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: yup.object().required("Mobile number is required"),
  country: yup.string("Enter your country").required("Country is required"),
  address: yup.string("Enter your address").required("Address is required"),
  city: yup.string("Enter your city").required("City is required"),
  province: yup.string("Enter your province").required("Province is required"),
  postalCode: yup
    .string("Enter your postal code")
    .required("Postal Code is required"),
})

export default accountEditFormValidationSchema
