import customAxiosInstance from "api/axios"

/**
 * @params {
 *    userId: string,
 *    deliveryType: string,
 *    eta: date
 * }
 */
export const createUserQuotation = async ({
  userId = "",
  deliveryType,
  eta,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/users/${userId}/quotations`,
      data: {
        deliveryType,
        eta,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default createUserQuotation
