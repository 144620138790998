import {
  FormHelperText,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import React from "react"
import moment from "moment"
import * as yup from "yup"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import CustomInput from "components/CustomInput/CustomInput"
import Media from "components/Media/Media"
import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.js"
import Card from "components/Card/Card"
import Table from "components/Table/Table.js"
import CustomModal from "components/Modal/Modal"
import Button from "components/CustomButtons/Button.js"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import getPart from "api/part/getPart"
import { useFormik } from "formik"
import { useQuery } from "@tanstack/react-query"

const useSectionCommentsStyle = makeStyles(sectionCommentsStyle)
const useStylesShoppingCart = makeStyles(shoppingCartStyle)

const validationSchema = yup.object({
  partName: yup.string("Enter your partName").required("partName is required"),
  partCode: yup.string("Enter your partCode").required("partCode is required"),
})

export default function DashboardDetailComment({
  formik,
  quotationId,
  commentDataList,
  // refetchQuotation,
  hasPartIds = [],
}) {
  const [partDataList, setPartDataList] = React.useState([])
  const [selectedPartIds, setSelectedPartIds] = React.useState([])
  const [errMsg, setErrMsg] = React.useState("")
  const [page, setPage] = React.useState(1)
  const [perPage] = React.useState(10)

  const formik2 = useFormik({
    initialValues: {
      partName: "",
      partCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  const sectionCommentsClasses = useSectionCommentsStyle()
  const shoppingCartClasses = useStylesShoppingCart()

  const { data: getPartResponse, refetch } = useQuery(
    [
      "parts",
      {
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    ],
    ({ queryKey }) =>
      getPart({
        ...queryKey[1],
        code: formik2.values.partCode,
        name: formik2.values.partName,
      }),
  )

  const disabledEditQuotation =
    formik.values.status === "Quoted" ||
    formik.values.status === "Re-Quoted" ||
    formik.values.status === "Approved by customer" ||
    formik.values.status === "Rejected by customer" ||
    formik.values.status === "Cancelled by customer"

  const commentList = commentDataList.map((data) => {
    const { id, isOffice, createdAt, comment } = data
    return (
      <Media
        key={id}
        title={
          <span
            style={{
              fontSize: "0.95rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1em",
            }}>
            {isOffice ? "Comments to Customer" : "Comments from Customer"}
            <small>· {moment(createdAt).format("YYYY-MM-DD")}</small>
          </span>
        }
        body={
          <p
            className={sectionCommentsClasses.color555}
            style={{
              fontSize: "0.85rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "0.9em",
            }}>
            {comment}
          </p>
        }
      />
    )
  })

  const partList = partDataList.map((data) => {
    const { part, rel } = data
    const { brand } = rel
    const hasPartId = selectedPartIds.includes(part.id)
    return [
      hasPartId ? (
        <CheckCircleOutlineIcon key={part.id} style={{ color: "#b2b2b2" }} />
      ) : (
        <IconButton
          key={part.id}
          aria-label="expand row"
          size="small"
          onClick={async () => {
            if (!hasPartId) {
              await formik.setFieldValue("updatedParts", [
                ...formik.values.updatedParts,
                {
                  quotationPart: {
                    comment: "",
                    fkPart: part.id,
                    fkQuotation: quotationId,
                    orderQuantity: 0,
                    quotationQuantity: 0,
                    unitPrice: 0,
                  },
                  rel: { part, brand },
                },
              ])
              setSelectedPartIds((prev) => [...prev, part.id])
            }
          }}>
          <AddCircleOutlineIcon color="primary" />
        </IconButton>
      ),
      part.code,
      part.name,
      part.isHidden ? "N" : "Y",
    ]
  })

  React.useEffect(() => {
    if (getPartResponse) {
      setPartDataList(getPartResponse.data.parts)
    }
  }, [getPartResponse])

  React.useEffect(() => {
    if (hasPartIds) {
      setSelectedPartIds(hasPartIds)
    }
  }, [hasPartIds])

  const partTotalCount = getPartResponse ? getPartResponse.data.total : 0
  const lastPage = Math.ceil(Number(partTotalCount / perPage))

  return (
    <>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 0",
          }}>
          {commentList}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <Card
            style={{
              padding: "1rem",
              margin: "1rem 0",
            }}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                error: formik.touched.comment && Boolean(formik.errors.comment),
                disabled: disabledEditQuotation,
              }}
              disabled={disabledEditQuotation}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
              inputProps={{
                disabled: disabledEditQuotation,
                multiline: true,
                name: "comment",
                value: formik.values.comment,
                onChange: formik.handleChange,
                placeholder: "input any request comments...",
                rows: 5,
              }}
            />
          </Card>
        </GridItem>
      </GridContainer>
      {disabledEditQuotation ? null : (
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem xs={12} sm={12} md={2}>
            <CustomModal
              title="파트 추가"
              body={
                <>
                  <GridContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}>
                      <Typography
                        style={{
                          color: "#003C8D",
                          marginRight: "1rem",
                          minWidth: "4rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "400",
                          lineHeight: "1.5em",
                        }}>
                        파트 번호
                      </Typography>
                      <CustomInput
                        error={
                          formik2.touched.partCode &&
                          Boolean(formik2.errors.partCode)
                        }
                        helperText={
                          formik2.touched.partCode && formik2.errors.partCode
                        }
                        inputProps={{
                          placeholder: "파트 번호",
                          name: "partCode",
                          value: formik2.values.partCode,
                          onChange: formik2.handleChange,
                          error:
                            formik2.touched.partCode &&
                            Boolean(formik2.errors.partCode),
                        }}
                        formControlProps={{
                          fullWidth: true,
                          style: { margin: 0, padding: 0 },
                          error:
                            formik2.touched.partCode &&
                            Boolean(formik2.errors.partCode),
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        marginBottom: "1rem",
                      }}>
                      <Typography
                        style={{
                          color: "#003C8D",
                          marginRight: "1rem",
                          minWidth: "4rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "400",
                          lineHeight: "1.5em",
                        }}>
                        파트명
                      </Typography>
                      <CustomInput
                        error={
                          formik2.touched.partName &&
                          Boolean(formik2.errors.partName)
                        }
                        helperText={
                          formik2.touched.partName && formik2.errors.partName
                        }
                        inputProps={{
                          placeholder: "파트명",
                          name: "partName",
                          value: formik2.values.partName,
                          onChange: formik2.handleChange,
                          error:
                            formik2.touched.partName &&
                            Boolean(formik2.errors.partName),
                        }}
                        formControlProps={{
                          fullWidth: true,
                          style: { margin: 0, padding: 0 },
                          error:
                            formik2.touched.partName &&
                            Boolean(formik2.errors.partName),
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        fullWidth
                        color="primary"
                        onClick={() => {
                          refetch()
                        }}>
                        Search
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}>
                      <Typography
                        style={{
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "400",
                          lineHeight: "1.5em",
                        }}>
                        {(page - 1) * perPage + 1} -{" "}
                        {partTotalCount <= (page - 1) * perPage + perPage
                          ? partTotalCount
                          : (page - 1) * perPage + perPage}{" "}
                        of {partTotalCount}
                      </Typography>
                      <IconButton
                        disabled={page <= 1}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                          if (page > 1) setPage(page - 1)
                        }}>
                        <ChevronLeftIcon />
                      </IconButton>
                      <IconButton
                        disabled={page >= lastPage}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                          if (page < lastPage) setPage(page + 1)
                        }}>
                        <ChevronRightIcon />
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                  {errMsg && (
                    <GridContainer>
                      <GridItem>
                        <FormHelperText error style={{ textAlign: "center" }}>
                          {errMsg}
                        </FormHelperText>
                      </GridItem>
                    </GridContainer>
                  )}
                  <GridContainer>
                    <GridItem>
                      <Card
                        style={{
                          maxHeight: "15rem",
                          overflow: "scroll",
                          borderRadius: 0,
                        }}
                        plain>
                        <div
                          style={{
                            width: "100%",
                            height: "1px",
                            background: "#ddd",
                          }}
                        />
                        <Table
                          tableHead={[" ", "파트번호", "파트명", "상태"]}
                          tableData={partList}
                          tableShopping
                          customHeadCellClasses={[
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                          ]}
                          customHeadClassesForCells={[0, 1, 2, 3]}
                          customCellClasses={[
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                            shoppingCartClasses.textCenter +
                              " " +
                              shoppingCartClasses.borderBox +
                              " " +
                              shoppingCartClasses.detail,
                          ]}
                          customClassesForCells={[0, 1, 2, 3]}
                        />
                      </Card>
                    </GridItem>
                  </GridContainer>
                </>
              }
              close={false}
              confirmText="OK"
              buttonProps={{
                fullWidth: true,
              }}
              onConfirm={() => {
                formik2.resetForm()
                setErrMsg("")
                setSelectedPartIds([])
                // refetchQuotation();
              }}>
              파트 추가
            </CustomModal>
          </GridItem>
        </GridContainer>
      )}
    </>
  )
}

DashboardDetailComment.propTypes = {
  formik: PropTypes.object,
  quotationId: PropTypes.string,
  commentDataList: PropTypes.array,
  refetchQuotation: PropTypes.func,
  hasPartIds: PropTypes.array,
}
