import customAxiosInstance from "api/axios"

/**
 * @params {
 *  userId: string,
 *  quotationId: string
 *  currency: string,
 *  status: string.
 * }
 */
export const updateUserQuotation = async ({
  userId = "",
  quotationId = "",
  currency = null,
  status,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `users/${userId}/quotations/${quotationId}`,
      data: {
        currency,
        status,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateUserQuotation
