import customAxiosInstance from "api/axios"

/**
 * @params {
 *    email: string
 * }
 */
export const findPassword = async ({ email }) => {
  try {
    return customAxiosInstance.request({
      method: "post",
      url: `/password`,
      data: { email },
    })
  } catch (e) {
    console.error(e)
  }
}

export default findPassword
