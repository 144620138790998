import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.js"

const useStyles = makeStyles(sectionsPageStyle)

export default function HomeSection(props) {
  const { children } = props
  const classes = useStyles()
  return (
    <div
      style={{
        background: "#eee",
        padding: "0 3%",
      }}>
      <div className={classes.main}>{children}</div>
    </div>
  )
}

HomeSection.propTypes = {
  children: PropTypes.node,
}
