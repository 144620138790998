import customAxiosInstance from "api/axios"

/**
 * @params {
 *    partId: string,
 *    fkPartBrand: string,
 *    fkSubCategory: string,
 *    code: string,
 *    name: string,
 *    aliases: string,
 *    detail: string,
 *    isHidden: boolean
 * }
 */
export const updatePart = async ({
  partId = "",
  fkPartBrand,
  fkSubCategory,
  code,
  name,
  aliases,
  detail,
  isHidden,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/parts/${partId}`,
      data: {
        fkPartBrand,
        fkSubCategory,
        code,
        name,
        aliases,
        detail,
        isHidden,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updatePart
