import React from "react"
import * as yup from "yup"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"

import Button from "components/CustomButtons/Button.js"
import Datetime from "react-datetime"
import CustomDropdown from "components/CustomDropdown/CustomDropdown"
import CustomInput from "components/CustomInput/CustomInput"
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js"
import moment from "moment"
import QuotationTable from "./QuotationTable"
import { FormControl, Typography } from "@material-ui/core"
import { useQuery } from "@tanstack/react-query"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"
import getUserQuotation from "api/userQuotation/getUserQuotation"
import { QuotationStatus } from "constants/statuses"
import { useFormik } from "formik"

const datePickerStyle = {
  picker: {
    bordercolor: "#003C8D",
  },
  input: {
    bordercolor: "#003C8D",
  },
}
const useDatePickerStyle = makeStyles(datePickerStyle)
const useStyles = makeStyles(featuresStyle)
const useStylesShoppingCart = makeStyles(shoppingCartStyle)
const useNavbarsStyle = makeStyles(navbarsStyle)

const validationSchema = yup.object({
  status: yup.string("Enter your status"),
  referNo: yup.string("Enter your referNo"),
  startRequestQuotationDate: yup.string("Enter your status"),
  endRequestQuotationDate: yup.string("Enter your status"),
})

export default function SectionContent() {
  const [quotationDataList, setQuotationDataList] = React.useState([])
  const [
    filteredQuotationDataList,
    setFilteredQuotationDataList,
  ] = React.useState([])
  const [user, setUser] = React.useState()

  const formik = useFormik({
    initialValues: {
      referNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {
        status,
        referNo,
        startRequestQuotationDate,
        endRequestQuotationDate,
      } = values
      if (
        !status &&
        !referNo &&
        !startRequestQuotationDate &&
        !endRequestQuotationDate
      )
        return setFilteredQuotationDataList(quotationDataList)

      return setFilteredQuotationDataList(
        quotationDataList
          .filter((part) => {
            if (status && status !== "unselected") {
              const { quotation } = part
              return quotation.status === status
            }
            return true
          })
          .filter((part) => {
            if (referNo) {
              const { quotation } = part
              return quotation.id.includes(referNo)
            }
            return true
          })
          .filter((part) => {
            if (startRequestQuotationDate) {
              const {
                quotation: { requestedAt },
              } = part
              return moment(requestedAt).isAfter(
                startRequestQuotationDate.startOf("day"),
              )
            }
            return true
          })
          .filter((part) => {
            if (endRequestQuotationDate) {
              const {
                quotation: { requestedAt },
              } = part
              return moment(requestedAt).isBefore(
                endRequestQuotationDate.endOf("day"),
              )
            }
            return true
          }),
      )
    },
  })

  const classes = useStyles()
  const classesDatePicker = useDatePickerStyle()
  const classesShoppingCart = useStylesShoppingCart()
  const classesNavbars = useNavbarsStyle()

  const { data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )

  const { data: getUserQuotationResponse } = useQuery(
    ["user_quotation", user && user.id],
    ({ queryKey }) => getUserQuotation(queryKey[1]),
    { enabled: !!user },
  )

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUser(verifyTokenAndGetUserInfoResponse.data.user)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  React.useEffect(() => {
    if (getUserQuotationResponse && getUserQuotationResponse.data) {
      setQuotationDataList(getUserQuotationResponse.data.quotations)
    }
  }, [getUserQuotationResponse])

  const quotationList = filteredQuotationDataList.map(
    ({ quotation, rel }, i) => [
      i + 1,
      quotation.id,
      quotation.deliveryType,
      moment(quotation.eta).format("YYYY-MM-DD"),
      quotation.currency ? quotation.currency : "-",
      rel.totalAmount,
      quotation.status,
      moment(quotation.requestedAt).format("YYYY-MM-DD"),
      quotation.respondedAt
        ? moment(quotation.respondedAt).format("YYYY-MM-DD")
        : "-",
    ],
  )

  React.useEffect(() => {
    if (quotationDataList) setFilteredQuotationDataList(quotationDataList)
  }, [quotationDataList])

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          width: "100%",
          padding: "0 2rem",
          background: "#fff",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          paddingBottom: "2rem",
          minHeight: "40rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
            style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <h3 className={classes.title}>MY QUOTATION</h3>
          </GridItem>
        </GridContainer>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: "100%",
          }}>
          <GridContainer style={{ display: "flex", alignItems: "center" }}>
            <GridItem xs={12} sm={12} md={2}>
              <CustomDropdown
                buttonText={formik.values.status}
                placeholder={"SELECT STATUS"}
                buttonProps={{
                  color: "transparent",
                  style: {
                    borderBottom: "1px solid black",
                    padding: "0.4rem 0.8rem",
                    width: "100%",
                    justifyContent: "space-between",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    marginBottom: "1px",
                  },
                }}
                dropdownList={QuotationStatus}
                onClick={(value) => {
                  formik.setFieldValue("status", value)
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl>
                <Datetime
                  closeOnSelect
                  locale="ko"
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  className={classesDatePicker.picker}
                  value={formik.values.startRequestQuotationDate}
                  onChange={(value) => {
                    formik.setFieldValue("startRequestQuotationDate", value)
                  }}
                  inputProps={{
                    name: "startRequestQuotationDate",
                    autoComplete: "off",
                    placeholder: "START DATE ▾",
                    style: {
                      marginTop: "1px",
                      fontSize: "14px",
                      cursor: "pointer",
                      textAlign: "center",
                    },
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl>
                <Datetime
                  closeOnSelect
                  locale="ko"
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  className={classesDatePicker.picker}
                  value={formik.values.endRequestQuotationDate}
                  onChange={(value) => {
                    formik.setFieldValue("endRequestQuotationDate", value)
                  }}
                  inputProps={{
                    name: "endRequestQuotationDate",
                    autoComplete: "off",
                    placeholder: "END DATE ▾",
                    style: {
                      marginTop: "1px",
                      fontSize: "14px",
                      cursor: "pointer",
                      textAlign: "center",
                    },
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Typography
                style={{
                  textAlign: "end",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                ※ Shipping fee not included
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "end" }}>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                inputRootCustomClassesHeaders={
                  classesNavbars.inputRootCustomClassesHeaders
                }
                formControlProps={{
                  className: classesNavbars.formControl,
                  fullWidth: true,
                  error:
                    formik.touched.referNo && Boolean(formik.errors.referNo),
                }}
                inputProps={{
                  placeholder: "REFER NO",
                  fullWidth: true,
                  inputProps: {
                    "aria-label": "Search",
                    className: classesNavbars.searchInput,
                  },
                  name: "referNo",
                  value: formik.values.referNo,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.referNo && Boolean(formik.errors.referNo),
                }}
                value={formik.values.referNo}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button type="submit" fullWidth color="primary">
                Search
              </Button>
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <QuotationTable
              userId={user && user.id}
              tableHead={[
                "No",
                "REFER No",
                "DELEVERY TYPE",
                "ETA",
                "CURRENCY",
                "TOTAL AMT",
                "STATUS",
                "REQUEST DATE",
                "RESPONSE DATE",
              ]}
              tableData={quotationList}
              tableShopping
              customHeadCellClasses={[
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              customCellClasses={[
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter +
                  " " +
                  classesShoppingCart.detail,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter,
                classesShoppingCart.textCenter +
                  " " +
                  classesShoppingCart.textBoldAndDanger,
                classesShoppingCart.textCenter +
                  " " +
                  classesShoppingCart.detail,
                classesShoppingCart.textCenter +
                  " " +
                  classesShoppingCart.detail,
                classesShoppingCart.textCenter +
                  " " +
                  classesShoppingCart.detail,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              striped
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
