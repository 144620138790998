import customAxiosInstance from "api/axios"

/**
 * @params {
 *    quotationId: string,
 *    fkPart: string,
 * }
 */
export const addAdminQuotationPart = async ({
  quotationId = "",
  fkPart = "",
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/quotations/${quotationId}/quotation_parts`,
      data: { fkPart },
    })
  } catch (e) {
    console.error(e)
  }
}

export default addAdminQuotationPart
