import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import * as yup from "yup"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import Datetime from "react-datetime"
import _ from "lodash"
import Table from "components/Table/Table.js"
import part_placeholder from "assets/img/part_placeholder.png"
import CustomDropdown from "components/CustomDropdown/CustomDropdown"
import useWindowDimensions from "hooks/useWindowDimensions"
import { useHistory } from "react-router-dom"
import { FormHelperText, Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import Card from "components/Card/Card.js"
import CustomInput from "components/CustomInput/CustomInput"
import CustomModal from "components/Modal/Modal"
import "moment/locale/ko"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"
import { useMutation, useQuery } from "@tanstack/react-query"
import getCart from "api/cart/getCart"
import CartItemImg from "./CartItemImg"
import CartItemUpdateForm from "./CartItemUpdateForm"
import deletePartInCart from "api/cart/deletePartInCart"
import moment from "moment"
import createUserQuotation from "api/userQuotation/createUserQuotation"
import createUserQuotationComment from "api/userQuotation/createUserQuotationComment"
import { useFormik } from "formik"
import { QuotationDeliveryType } from "constants/deliveryTypes"
import updatePartInCart from "api/cart/updatePartInCart"
import addPartToCart from "api/cart/addPartToCart"

const datePickerStyle = {
  picker: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    border: 0,
    borderBottom: "1px solid black",
    padding: "0.4rem 0.8rem",
    paddingBottom: "0.4rem",
    fontSize: "13px",
    width: "100%",
    margin: "0.3125rem 1px",
    marginBottom: "0.2125rem",
    color: "black",
    cursor: "pointer",
    textAlign: "center",
    "&::placeholder": {
      fontSize: "13px",
      color: "black",
    },
  },
  errorInput: {
    border: 0,
    borderBottom: "1px solid rgb(244, 67, 54)",
    padding: "0.4rem 0.8rem",
    paddingBottom: "0.4rem",
    fontSize: "13px",
    width: "100%",
    margin: "0.3125rem 1px",
    marginBottom: "0.2125rem",
    color: "rgb(244, 67, 54)",
    cursor: "pointer",
    textAlign: "center",
    "&::placeholder": {
      fontSize: "13px",
      color: "rgb(244, 67, 54)",
    },
  },
}
const useStyles = makeStyles(featuresStyle)
const useStylesShoppingCart = makeStyles(shoppingCartStyle)
const useDatePickerStyle = makeStyles(datePickerStyle)

const validationSchema = yup.object({
  deliveryType: yup
    .string("Enter your deliveryType")
    .required("DeliveryType is required"),
  eta: yup.string("Enter your ETA").required("ETA is required"),
  comment: yup.string("Enter your comment"),
})

export const isStartToToday = (current) => {
  return current.isAfter(moment().subtract(1, "day"))
}

export default function SectionContent() {
  const history = useHistory()
  const { width } = useWindowDimensions()
  const [submitErrMsg, setSubmitErrMsg] = React.useState("")
  const [userId, setUserId] = React.useState("")
  const [localCartDataList, setLocalCartDataList] = React.useState([])
  const [asyncCartDataList, setAsyncCartDataList] = React.useState([])
  const deletePartInCartMutation = useMutation(deletePartInCart, {
    onError: (err) => {
      console.info(err)
    },
  })
  const createUserQuotationMutation = useMutation(createUserQuotation)
  const createUserQuotationCommentMutation = useMutation(
    createUserQuotationComment,
  )
  const addPartToCartMutation = useMutation(addPartToCart)

  const formik = useFormik({
    initialValues: {
      comment: "",
      // eta: moment().add(1, "day"),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!userId) return alert("Thank you for using it after sign in.")
      const response = await createUserQuotationMutation.mutateAsync({
        userId,
        deliveryType: values.deliveryType,
        eta: values.eta.format(),
      })
      if (values.comment) {
        await createUserQuotationCommentMutation.mutateAsync({
          userId,
          quotationId: response.data.quotation.id,
          comment: values.comment,
        })
      }
    },
  })

  const classes = useStyles()
  const classesShoppingCart = useStylesShoppingCart()
  const classesDatePicker = useDatePickerStyle()

  const { isLoading, data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )
  const { data: getCartResponse } = useQuery(["cart"], () => getCart(userId), {
    enabled: !!userId,
  })
  const updatePartInCartMutation = useMutation(updatePartInCart)

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUserId(verifyTokenAndGetUserInfoResponse.data.user.id)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  const syncCart = async () => {
    const localCartIds = localCartDataList.map(({ part }) => part.id)
    const asyncCartIds = asyncCartDataList.map(({ rel }) => rel.part.id)
    const existedIds = _.difference(asyncCartIds, localCartIds)
    const nonExistedIds = _.difference(localCartIds, asyncCartIds)
    await Promise.all(
      existedIds.map((partId) => {
        const findedAsync = asyncCartDataList.find(
          ({ rel }) => rel.part.id === partId,
        )
        const findedLocal = localCartDataList.find(
          ({ part }) => part.id === partId,
        )

        return updatePartInCartMutation.mutateAsync({
          userId,
          partId,
          quantity: findedAsync.cart.quantity + findedLocal.part.quantity,
        })
      }),
    )
    await Promise.all(
      nonExistedIds.map((fkPart) => {
        const findedLocal = localCartDataList.find(
          ({ part }) => part.id === fkPart,
        )

        return addPartToCartMutation.mutateAsync({
          userId,
          fkPart: fkPart,
          quantity: findedLocal.part.quantity,
        })
      }),
    )
    localStorage.setItem("cartItems", JSON.stringify([]))
    window.location.reload()
  }

  React.useEffect(() => {
    if (getCartResponse && getCartResponse.data) {
      setAsyncCartDataList(getCartResponse.data.carts)
    }
  }, [getCartResponse])

  React.useEffect(() => {
    if (createUserQuotationMutation.error) {
      if (createUserQuotationMutation.error.response.status === 412)
        setSubmitErrMsg(
          "Your cart is currently empty. Please check again and request again.",
        )
    }
  }, [createUserQuotationMutation.error])

  React.useEffect(() => {
    const prevCartItems = JSON.parse(localStorage.getItem("cartItems")) || []

    if (!prevCartItems) localStorage.setItem("cartItems", [])

    setLocalCartDataList(prevCartItems)
  }, [])

  React.useEffect(() => {
    if (userId && localCartDataList.length > 0) syncCart()
  }, [userId, localCartDataList])

  React.useEffect(() => {
    if (!isLoading && !verifyTokenAndGetUserInfoResponse) {
      alert("로그인이 필요한 기능입니다. 로그인 후 이용해주시면 감사합니다.")
      history.push("/")
    }
  }, [userId])

  const cartList = userId
    ? asyncCartDataList.map(({ cart, rel }, i) => {
        const { quantity, fkUser, fkPart } = cart
        const {
          part: { code, detail, name },
          images,
          brand,
          subCategory,
        } = rel
        const imagePaths = images.map(
          ({ path }) => `https://assets.echeol.com/${path}`,
        )

        return [
          i + 1,
          <CartItemImg
            key={fkPart}
            small={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            medium={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            large={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            alt="part_image"
          />,
          code,
          name,
          subCategory.name,
          brand.name,
          detail,
          <CartItemUpdateForm
            key={fkPart}
            defaultQuantity={quantity}
            userId={fkUser}
            cartId={fkPart}
          />,
          <CustomModal
            key={fkPart}
            title=""
            body="Are you sure you want to delete it?"
            buttonProps={{
              link: true,
              round: true,
              size: "sm",
              fullWidth: true,
              className: classes.actionButton,
            }}
            onConfirm={async (isConfirm) => {
              if (isConfirm) {
                setAsyncCartDataList((prev) =>
                  prev.filter(({ rel }) => rel.part.id !== fkPart),
                )
                await deletePartInCartMutation.mutateAsync({
                  userId: fkUser,
                  partId: fkPart,
                })
              }
            }}>
            <Close />
          </CustomModal>,
        ]
      })
    : localCartDataList.map(({ part, rel }, i) => {
        const { quantity, id, code, detail, name } = part
        const { images, brand, subCategory } = rel
        const imagePaths = images.map(
          ({ path }) => `https://assets.echeol.com/${path}`,
        )
        return [
          i + 1,
          <CartItemImg
            key={id}
            small={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            medium={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            large={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            alt="part_image"
          />,
          code,
          name,
          subCategory.name,
          brand.name,
          detail,
          <CartItemUpdateForm
            key={id}
            defaultQuantity={quantity || 0}
            cartId={id}
          />,
          <CustomModal
            key={id}
            title=""
            body="Are you sure you want to delete it?"
            buttonProps={{
              link: true,
              round: true,
              size: "sm",
              fullWidth: true,
              className: classes.actionButton,
            }}
            onConfirm={(isConfirm) => {
              if (isConfirm) {
                const prevCartItems =
                  JSON.parse(localStorage.getItem("cartItems")) || []

                if (!prevCartItems) localStorage.setItem("cartItems", [])

                const filtered = prevCartItems
                  ? prevCartItems.filter((prev) => prev.part.id !== id)
                  : []

                localStorage.setItem("cartItems", JSON.stringify(filtered))
                window.location.reload()
              }
            }}>
            <Close />
          </CustomModal>,
        ]
      })

  React.useEffect(() => {
    formik.validateForm()
  }, [])

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          padding: "0 2rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          paddingBottom: "2rem",
          minHeight: "40rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
            style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <h3 className={classes.title}>MY CART</h3>
          </GridItem>
        </GridContainer>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: width < 600 ? "center" : "end",
              }}>
              <CustomDropdown
                placeholder={"DELIVERY TYPE *"}
                buttonText={formik.values.deliveryType}
                buttonProps={{
                  color: "transparent",
                  style: formik.errors.deliveryType
                    ? {
                        borderBottom: "1px solid #f44336",
                        padding: "0.4rem 0.8rem",
                        fontSize: "13px",
                        width: "100%",
                        color: "#f44336",
                        justifyContent: "space-between",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }
                    : {
                        borderBottom: "1px solid black",
                        padding: "0.4rem 0.8rem",
                        fontSize: "13px",
                        width: "100%",
                        justifyContent: "space-between",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                }}
                dropdownList={QuotationDeliveryType}
                onClick={(value) => {
                  formik.setFieldValue("deliveryType", value)
                }}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: width < 600 ? "center" : "start",
              }}>
              <Datetime
                isValidDate={isStartToToday}
                closeOnSelect
                locale="ko"
                dateFormat={"YYYY-MM-DD"}
                timeFormat={false}
                className={classesDatePicker.picker}
                value={formik.values.eta}
                onChange={(value) => {
                  formik.setFieldValue("eta", value)
                }}
                inputProps={{
                  required: true,
                  name: "eta",
                  autoComplete: "off",
                  placeholder: "ETA * ▾",
                  className: formik.errors.eta
                    ? classesDatePicker.errorInput
                    : classesDatePicker.input,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              {!!formik.errors.deliveryType && !!formik.errors.eta && (
                <FormHelperText error>
                  DELIVERY TYPE and ETA are required.
                </FormHelperText>
              )}
              {!!formik.errors.deliveryType && !formik.errors.eta && (
                <FormHelperText error>
                  DELIVERY TYPE is required.
                </FormHelperText>
              )}
              {!formik.errors.deliveryType && !!formik.errors.eta && (
                <FormHelperText error>ETA is required.</FormHelperText>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: width < 600 ? "center" : "end",
              }}>
              <FormHelperText>
                ※ DELIVERY TYPE and ETA are required.
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {isLoading ? null : cartList.length > 0 ? (
                <Table
                  tableHead={[
                    "No",
                    "",
                    "PART NUMBER",
                    "PART NAME",
                    "CATEGORY",
                    "BRAND",
                    "DETAIL",
                    "QTY",
                  ]}
                  tableData={cartList}
                  tableShopping
                  customHeadCellClasses={[
                    classesShoppingCart.textCenter,
                    classesShoppingCart.textCenter,
                    classesShoppingCart.description +
                      " " +
                      classesShoppingCart.textCenter,
                    classesShoppingCart.description +
                      " " +
                      classesShoppingCart.textCenter,
                    classesShoppingCart.textCenter,
                    classesShoppingCart.textCenter,
                    classesShoppingCart.textCenter,
                    classesShoppingCart.textCenter,
                  ]}
                  customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                  customCellClasses={[
                    classesShoppingCart.textCenter,
                    classesShoppingCart.contentCenter,
                    classesShoppingCart.detail,
                    classesShoppingCart.detail,
                    classesShoppingCart.detail,
                    classesShoppingCart.detail,
                    classesShoppingCart.detail,
                  ]}
                  customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                />
              ) : (
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{
                    marginTop: "1rem",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}>
                  Empty or is loading...
                </Typography>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Card
                style={{
                  padding: "1rem",
                  margin: "1rem 0",
                }}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    error:
                      formik.touched.comment && Boolean(formik.errors.comment),
                  }}
                  error={
                    formik.touched.comment && Boolean(formik.errors.comment)
                  }
                  helperText={formik.touched.comment && formik.errors.comment}
                  inputProps={{
                    multiline: true,
                    name: "comment",
                    value: formik.values.comment,
                    onChange: formik.handleChange,
                    placeholder: "input any request comments...",
                    rows: 5,
                  }}
                />
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              {submitErrMsg && (
                <FormHelperText error style={{ textAlign: "center" }}>
                  {submitErrMsg}
                </FormHelperText>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: width < 600 ? "center" : "end",
              }}>
              <CustomModal
                title=""
                body="Are you sure you want to empty the cart?"
                onConfirm={async (isConfirm) => {
                  if (isConfirm)
                    if (getCartResponse) {
                      await Promise.all(
                        getCartResponse.data.carts.map(
                          ({ cart: { fkUser, fkPart } }) =>
                            deletePartInCartMutation.mutateAsync({
                              userId: fkUser,
                              partId: fkPart,
                            }),
                        ),
                      )
                    }
                  window.location.reload()
                }}>
                REMOVE CART
              </CustomModal>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: width < 600 ? "center" : "start",
              }}>
              <CustomModal
                title=""
                disabled={
                  !formik.dirty &&
                  (Boolean(formik.errors.deliveryType) ||
                    Boolean(formik.errors.eta))
                }
                body="A quote has been sent to the manager. Would you like to check the quotation status?"
                confirmText="OK"
                buttonProps={{
                  type: "submit",
                  fullWidth: true,
                  disabled: cartList.length < 1,
                }}
                onClick={async () => {
                  const errors = await formik.validateForm()
                  if (!errors) return
                }}
                onConfirm={(isConfirm) => {
                  if (isConfirm) history.push("/my-page/my-quotation")
                }}>
                REQUEST QUOTATION
              </CustomModal>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    </div>
  )
}
