import customAxiosInstance from "api/axios"

/**
 * @params partBrandId: string
 */
export const deletePartBrand = async (partBrandId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "delete",
      url: `/part_brands/${partBrandId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default deletePartBrand
