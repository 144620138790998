import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import { Dropdown, Menu } from "semantic-ui-react"
import { Grid } from "semantic-ui-react"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import Table from "components/Table/Table.js"
import part_placeholder from "assets/img/part_placeholder.png"
import Pagination from "components/Pagination/Pagination"
import CustomDropdown from "components/CustomDropdown/CustomDropdown"
import useWindowDimensions from "hooks/useWindowDimensions"
import { useQueries, useQuery } from "@tanstack/react-query"
import _ from "lodash"
import getPart from "api/part/getPart"
import ProductItemToCartForm from "./ProductItemToCartForm"
import ProductItemImg from "./ProductItemImg"
import { Typography } from "@material-ui/core"
import getPartBrand from "api/partBrand/getPartBrand"
import getCategory from "api/category/getCategory"
import getSubCategory from "api/category/getSubCategory"
import { useLocation } from "react-router-dom"

const useStyles = makeStyles(featuresStyle)
const useStylesShoppingCart = makeStyles(shoppingCartStyle)

function useQueryString() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function SectionContent() {
  const q = useQueryString()
  const { width } = useWindowDimensions()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const [category, setCategory] = React.useState({
    type: "",
    id: "",
    name: "ALL CATEGORY",
  })
  const [brands, setBrands] = React.useState({ id: "", name: "ALL BRANDS" })

  const { data: getBrandResponse } = useQuery(["brands"], getPartBrand)
  const { data: getCategoryResponse } = useQuery(["categories"], getCategory)
  const getSubCategoryResponses = useQueries({
    queries: getCategoryResponse
      ? getCategoryResponse.data.categories.map(({ id }) => ({
          queryKey: [`sub_categories_${id}`, id],
          queryFn: ({ queryKey }) => getSubCategory(queryKey[1]),
          enabled: !!id,
        }))
      : [],
  })
  const { isLoading, data: getPartResponse } = useQuery(
    [
      "parts",
      {
        code: q.get("code"),
        name: q.get("name"),
        detail: q.get("detail"),
        limit: perPage,
        offset: (page - 1) * perPage,
        fk_part_brand: brands.id,
        include_hidden: "0",
        ...(category.type
          ? category.type === "main"
            ? { fk_category: category.id }
            : { fk_sub_category: category.id }
          : undefined),
      },
    ],
    ({ queryKey }) => getPart(queryKey[1]),
  )

  const classes = useStyles()
  const classesShoppingCart = useStylesShoppingCart()
  const productTotalCount = getPartResponse ? getPartResponse.data.total : 0
  const lastPage = Math.ceil(Number(productTotalCount / perPage))

  const onClickPage = (pageInfo) => setPage(Number(pageInfo.text))
  const onClickPrevPage = () => (page > 5 ? setPage(page - 5) : setPage(1))
  const onClickNextPage = () => setPage(page + 5)

  const brandList = getBrandResponse
    ? _.orderBy(
        getBrandResponse.data.partBrands.map(({ id, name }) => ({ id, name })),
        ["name"],
        ["asc"],
      )
    : []
  const categoryList = getCategoryResponse
    ? getCategoryResponse.data.categories.map(({ id, name }) => ({ id, name }))
    : []
  const subCategoryList = getSubCategoryResponses
    ? getSubCategoryResponses.map(({ data: res }) =>
        res
          ? res.data.subCategories.map(({ fkCategory, id, name }) => ({
              pid: fkCategory,
              key: id,
              id,
              name,
              text: name,
              value: id,
            }))
          : [],
      )
    : []

  const mergedCategoryList = categoryList.map((main) => ({
    main,
    sub: subCategoryList.flat(1).filter(({ pid }) => main.id === pid),
  }))
  const productList = getPartResponse
    ? getPartResponse.data.parts.map(({ part, rel }) => {
        const { id, code, detail, name, aliases } = part
        const { images, brand, subCategory } = rel
        const imagePaths = images.map(
          ({ path }) => `https://assets.echeol.com/${path}`,
        )

        return [
          <ProductItemImg
            key={id}
            mainSrc={imagePaths.length > 0 ? imagePaths[0] : part_placeholder}
            images={imagePaths}
            alt="part_image"
          />,
          <span
            key={id}
            style={{
              maxWidth: "10rem",
              whiteSpace: "initial",
            }}>
            <div
              style={{
                fontWeight: 500,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}>
              {code}
            </div>
            {aliases &&
              aliases.split(",").map((alias, i) => (
                <div
                  key={i}
                  style={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  }}>
                  {alias}
                </div>
              ))}
          </span>,
          name,
          subCategory.name,
          brand.name,
          detail,
          <ProductItemToCartForm
            key={id}
            productInfo={{ part, rel }}
            productId={id}
          />,
        ]
      })
    : []

  const pages = [
    { disabled: page < 3, text: "«", onClick: onClickPrevPage },
    {
      active: page < 3 ? page === 1 : false,
      text: page > 2 ? page - 2 : 1,
      onClick: onClickPage,
    },
    {
      disabled: lastPage < (page > 2 ? page - 1 : 2),
      active: page < 3 ? page === 2 : false,
      text: page > 2 ? page - 1 : 2,
      onClick: onClickPage,
    },
    {
      disabled: lastPage < (page > 2 ? page : 3),
      active: page > 2,
      text: page > 2 ? page : 3,
      onClick: onClickPage,
    },
    {
      disabled: lastPage < (page > 2 ? page + 1 : 4),
      active: false,
      text: page > 2 ? page + 1 : 4,
      onClick: onClickPage,
    },
    {
      disabled: lastPage < (page > 2 ? page + 2 : 5),
      active: false,
      text: page > 2 ? page + 2 : 5,
      onClick: onClickPage,
    },
    { disabled: page > lastPage - 2, text: "»", onClick: onClickNextPage },
  ]

  React.useEffect(() => {
    setPage(1)
  }, [category])
  React.useEffect(() => {
    setPage(1)
  }, [brands])
  React.useEffect(() => {
    setPage(1)
  }, [perPage])

  React.useEffect(() => {
    setPage(1)
  }, [q])

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          padding: "0 2rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          minHeight: "40rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}>
            <h3
              className={classes.title}
              style={{ marginTop: "2rem", marginBottom: "1rem" }}>
              OUR PRODUCTS
            </h3>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={2}
            md={2}
            style={{
              display: "flex",
              justifyContent: width < 600 ? "center" : "start",
            }}>
            <CustomDropdown
              hoverColor="dark"
              buttonText={perPage}
              buttonProps={{
                color: "transparent",
                style: {
                  borderBottom: "1px solid black",
                  padding: "0.4rem 0.8rem",
                  width: "100%",
                  fontSize: "1rem",
                  justifyContent: "space-between",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              dropdownList={["5", "10", "25", "50", "100"]}
              onClick={setPerPage}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={4}
            md={4}
            style={{
              display: "flex",
              justifyContent: width < 600 ? "center" : "end",
            }}>
            <Menu>
              <Dropdown
                placeholder="ALL CATEGORY"
                upward={false}
                className={classes.before0 + " " + "item"}
                value={category.id}
                text={category.name}
                style={{
                  width: "100%",
                  fontSize: "12px",
                  background: "#fff",
                  borderTopRightRadius: ".28571429rem",
                  borderBottomRightRadius: ".28571429rem",
                }}>
                <Dropdown.Menu>
                  <Dropdown.Header>Categories</Dropdown.Header>
                  <Dropdown.Item
                    value={""}
                    text={"ALL CATEGORY"}
                    item
                    style={{
                      cursor: "pointer",
                      padding: 0,
                      margin: 0,
                      background: "#fff",
                    }}
                    onClick={(e, data) => {
                      setCategory({
                        type: "",
                        id: data.value,
                        name: data.text,
                      })
                    }}
                  />
                  {mergedCategoryList.map(({ main, sub }) => (
                    <Dropdown
                      fluid
                      key={main.id}
                      value={main.id}
                      text={main.name}
                      item={true}
                      style={{
                        width: "300px",
                        background: "#fff",
                      }}
                      onClose={(e) => {
                        if (e) {
                          e.persist()
                          const findedSubCategory = sub.find(
                            ({ text }) => text === e.target.innerText,
                          )
                          const categoryInfo = findedSubCategory
                            ? {
                                type: "sub",
                                id: findedSubCategory.value,
                                name: findedSubCategory.text,
                              }
                            : {
                                type: "main",
                                id: main.id,
                                name: main.name,
                              }
                          setCategory(categoryInfo)
                        }
                      }}>
                      <Dropdown.Menu
                        style={{ minHeight: `${sub.length * 30.85}px` }}>
                        {sub.map(({ text, value }) => (
                          <Dropdown.Item
                            key={text}
                            text={text}
                            value={value}
                            style={{ height: "30.85px" }}
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </GridItem>
          <GridItem
            xs={12}
            sm={6}
            md={6}
            style={{
              display: "flex",
              justifyContent: width < 600 ? "center" : "start",
            }}>
            <Menu>
              <Dropdown
                placeholder="ALL BRANDS"
                upward={false}
                className={classes.before0 + " " + "item"}
                value={brands.id}
                text={brands.name}
                style={{
                  width: "100%",
                  fontSize: "12px",
                  background: "#fff",
                  borderTopRightRadius: ".28571429rem",
                  borderBottomRightRadius: ".28571429rem",
                }}>
                <Dropdown.Menu
                  style={{
                    width: "300px",
                    background: "#fff",
                  }}>
                  <Dropdown.Header>Brands</Dropdown.Header>
                  <Grid columns={2}>
                    <Grid.Column
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: 0,
                      }}>
                      <Dropdown.Item
                        value={""}
                        text={"ALL BRANDS"}
                        item
                        className={classes.before0}
                        style={{
                          cursor: "pointer",
                          padding: 0,
                          margin: ".92857143em 1.14285714em",
                          background: "#fff",
                        }}
                        onClick={(e, data) => {
                          setBrands({ id: data.value, name: data.text })
                        }}
                      />
                      {brandList
                        .slice(0, Math.ceil((brandList.length + 1) / 2))
                        .map(({ id, name }) => (
                          <Dropdown.Item
                            key={id}
                            value={id}
                            text={name}
                            item
                            className={classes.before0}
                            style={{
                              cursor: "pointer",
                              padding: 0,
                              margin: ".92857143em 1.14285714em",
                              background: "#fff",
                            }}
                            onClick={(e, data) => {
                              setBrands({ id: data.value, name: data.text })
                            }}
                          />
                        ))}
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}>
                      {brandList
                        .slice(Math.ceil((brandList.length + 1) / 2))
                        .map(({ id, name }) => (
                          <Dropdown.Item
                            key={id}
                            value={id}
                            text={name}
                            item
                            className={classes.before0}
                            style={{
                              cursor: "pointer",
                              padding: 0,
                              margin: ".92857143em 1.14285714em",
                              background: "#fff",
                            }}
                            onClick={(e, data) => {
                              setBrands({ id: data.value, name: data.text })
                            }}
                          />
                        ))}
                    </Grid.Column>
                  </Grid>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {isLoading ? null : productList.length > 0 ? (
              <Table
                tableHead={[
                  "",
                  "PART NUMBER",
                  "PART NAME",
                  "CATEGORY",
                  "BRAND",
                  "DETAIL",
                  "QTY",
                ]}
                tableData={productList}
                tableShopping
                customHeadCellClasses={[
                  classesShoppingCart.textCenter,
                  classesShoppingCart.textCenter,
                  classesShoppingCart.description +
                    " " +
                    classesShoppingCart.textCenter,
                  classesShoppingCart.description +
                    " " +
                    classesShoppingCart.textCenter,
                  classesShoppingCart.textCenter,
                  classesShoppingCart.textCenter,
                  classesShoppingCart.textCenter,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                customCellClasses={[
                  classesShoppingCart.contentCenter,
                  classesShoppingCart.detail,
                  classesShoppingCart.detail,
                  classesShoppingCart.detail,
                  classesShoppingCart.detail,
                  classesShoppingCart.detail,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5]}
              />
            ) : (
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{
                  marginTop: "1rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                Does not exist. Please search again after checking text.
              </Typography>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}>
            {isLoading ? null : (
              <Pagination className={classes.textCenter} pages={pages} />
            )}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
