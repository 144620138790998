import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import PropTypes from "prop-types"
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js"

import style from "assets/jss/material-kit-pro-react/modalStyle.js"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const useStyles = makeStyles(style)

export default function CustomModal(props) {
  const {
    buttonText,
    close,
    confirmText,
    buttonProps,
    title,
    body,
    onConfirm,
    onClick,
    children,
    disabled,
  } = props
  const [openDialog, setOpenDialog] = React.useState(false)
  const classes = useStyles()
  return (
    <div>
      <Button
        color="primary"
        onClick={() => {
          onClick()
          if (!disabled) {
            setOpenDialog(true)
          }
        }}
        {...buttonProps}>
        {children ? children : buttonText ? buttonText : ""}
      </Button>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-slide-title"
          disableTypography
          className={classes.modalHeader}>
          {/* <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setOpenDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button> */}
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent
          id="alert-slide-description"
          className={classes.modalBody}>
          {body}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {close && (
            <Button onClick={() => setOpenDialog(false)} color="danger">
              Close
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              onConfirm(true)
              setOpenDialog(false)
            }}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

CustomModal.defaultProps = {
  buttonText: "Sample Modal",
  title: "Modal Title",
  body: "",
  confirmText: "confirm",
  onClick: () => {},
  close: true,
  disabled: false,
}

CustomModal.propTypes = {
  disabled: PropTypes.bool,
  close: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.node,
  confirmText: PropTypes.string,
  onClick: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
}
