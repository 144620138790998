import { IconButton, makeStyles, Typography } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import PropTypes from "prop-types"

import moment from "moment"
import CustomInput from "components/CustomInput/CustomInput"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import { useQuery } from "@tanstack/react-query"
import getPart from "api/part/getPart"

export const strToShort10 = (str) => {
  return str.replace(/(.{10})..+/, "$1…")
}
export const strToShort20 = (str) => {
  return str.replace(/(.{20})..+/, "$1…")
}

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

const validationSchema = yup.object({
  search: yup.string("Enter your search").required("search is required"),
})

export default function PartList({ detailRef, onClickRow }) {
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: validationSchema,
  })
  const [page, setPage] = React.useState(1)
  const [perPage] = React.useState(10)

  const shoppingCartClasses = useStylesShoppingCart()

  const { isLoading, data: getPartResponse, refetch } = useQuery(
    [
      "parts",
      {
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    ],
    ({ queryKey }) =>
      getPart({
        ...queryKey[1],
        name: formik.values.search,
        code: formik.values.search,
        detail: formik.values.search,
      }),
  )

  const productTotalCount = getPartResponse ? getPartResponse.data.total : 0
  const lastPage = Math.ceil(Number(productTotalCount / perPage))

  const partList = getPartResponse
    ? getPartResponse.data.parts.map(({ part, rel }, i) => {
        const { code, isHidden, name, detail, updatedAt } = part
        const { brand, subCategory } = rel

        return [
          (page - 1) * perPage + (i + 1),
          code,
          name,
          subCategory.name,
          brand.name,
          detail,
          isHidden ? "OFF" : "ON",
          moment(updatedAt).format("YYYY-MM-DD HH-mm-ss"),
        ]
      })
    : []

  return (
    <>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
        }}>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}>
          <Typography
            style={{
              color: "#003C8D",
              marginRight: "1rem",
              minWidth: "4rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.5em",
            }}>
            파트 정보
          </Typography>
          <CustomInput
            error={formik.touched.search && Boolean(formik.errors.search)}
            helperText={formik.touched.search && formik.errors.search}
            inputProps={{
              placeholder: "파트 번호, 파트 명, 디테일",
              name: "search",
              value: formik.values.search,
              onChange: formik.handleChange,
              error: formik.touched.search && Boolean(formik.errors.search),
            }}
            formControlProps={{
              fullWidth: true,
              style: { margin: 0, padding: 0 },
              error: formik.touched.search && Boolean(formik.errors.search),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}></GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={2}
          style={{ display: "flex", justifyContent: "end" }}>
          <Button
            fullWidth
            color="primary"
            onClick={() => {
              refetch()
            }}>
            Search
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}>
          {isLoading ? null : (
            <>
              <Typography
                style={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                {(page - 1) * perPage + 1} -{" "}
                {productTotalCount <= (page - 1) * perPage + perPage
                  ? productTotalCount
                  : (page - 1) * perPage + perPage}{" "}
                of {productTotalCount}
              </Typography>
              <IconButton
                disabled={page <= 1}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  if (page > 1) setPage(page - 1)
                }}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                disabled={page >= lastPage}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  if (page < lastPage) setPage(page + 1)
                }}>
                <ChevronRightIcon />
              </IconButton>
            </>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {isLoading ? null : partList.length > 0 ? (
            <>
              <div
                style={{ width: "100%", height: "1px", background: "#ddd" }}
              />
              <Table
                tableHead={[
                  "번호",
                  "파트번호",
                  "파트명",
                  "카테고리",
                  "브랜드",
                  "디테일",
                  "상태",
                  "수정일",
                ]}
                tableShopping
                tableData={partList}
                customHeadCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                customCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                onClickTableData={(row) => {
                  const [order] = row

                  const finded = getPartResponse.data.parts.find(
                    (_, i) => i === order - 1 - (page - 1) * perPage,
                  )
                  onClickRow(finded)
                  detailRef.current.scrollIntoView()
                }}
              />
            </>
          ) : (
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{
                marginTop: "1rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
                textAlign: "center",
              }}>
              상품이 존재하지 않습니다.
            </Typography>
          )}
        </GridItem>
      </GridContainer>
    </>
  )
}
PartList.defaultProps = {
  onClickRow: () => {},
}

PartList.propTypes = {
  detailRef: PropTypes.object,
  onClickRow: PropTypes.func,
}
