import React from "react"
import { useStyles } from "./styles"

import PartList from "./PartList"
import PartDetail from "./PartDetail"
import { Paper } from "@material-ui/core"

export default function SectionContent() {
  const classes = useStyles()
  const detailRef = React.useRef()
  const [selectedPart, setSelectedPart] = React.useState()

  const handleClickPartClear = () => {
    setSelectedPart()
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper elevation={0} variant="outlined" style={{ padding: "1rem" }}>
        <PartList detailRef={detailRef} onClickRow={setSelectedPart} />
      </Paper>
      <Paper
        elevation={0}
        variant="outlined"
        style={{ padding: "1rem", marginTop: "1rem" }}>
        <PartDetail
          detailRef={detailRef}
          selectedPart={selectedPart}
          handleClickPartClear={handleClickPartClear}
        />
      </Paper>
    </main>
  )
}
