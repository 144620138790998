import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"

import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"

import CustomInput from "components/CustomInput/CustomInput"
import { useFormik } from "formik"
import * as yup from "yup"
import Button from "components/CustomButtons/Button.js"
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import CustomIntlTelInput from "components/CustomIntlTelInput/CustomIntlTelInput"
import { useMutation } from "@tanstack/react-query"
import createUser from "api/user/createUser"
import { useHistory } from "react-router-dom"
import { intlTelCountry } from "constants/intlTelCountry"

const useStyles = makeStyles(featuresStyle)
const useSelectStyles = makeStyles(selectStyles)

const MobileSchema = yup.object().shape({
  phone: yup.string().required("phone is required"),
  country: yup.string(),
  countryCode: yup.string(),
})

const validationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .required("Last name is required"),
  id: yup.string("Enter your id").required("ID is required"),
  password: yup.string("Enter your password").required("Password is required"),
  confirmPassword: yup
    .string("Enter your Confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: MobileSchema,
  country: yup.string("Enter your country").required("Country is required"),
  address: yup.string("Enter your address").required("Address is required"),
  city: yup.string("Enter your city").required("City is required"),
  province: yup.string("Enter your province").required("Province is required"),
  postalCode: yup
    .string("Enter your postal code")
    .required("Postal Code is required"),
})

export default function SectionContent() {
  const history = useHistory()
  const [submitErrMsg, setSubmitErrMsg] = React.useState("")
  const joinMutation = useMutation(createUser, {
    onSuccess: () => {
      alert("Sign up is complete. Please use the service after sign in.")
      history.push("/")
    },
  })

  React.useEffect(() => {
    if (joinMutation.error) {
      if (joinMutation.error.response.status === 409)
        setSubmitErrMsg("Duplicate account or id. Please check and try again.")
    }
  }, [joinMutation.error])

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      id: "",
      password: "",
      confirmPassword: "",
      email: "",
      mobile: {
        country: "kr",
        countryCode: "+82",
        phone: "",
      },
      country: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { id, confirmPassword, mobile, ...rest } = values

      confirmPassword // unused

      const joinInfo = {
        ...rest,
        phone: mobile.phone,
        countryCode: mobile.countryCode,
        account: id,
        role: "C",
        isValid: 1,
      }
      joinMutation.mutateAsync(joinInfo)
    },
  })

  const classes = useStyles()
  const selectClasses = useSelectStyles()

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          padding: "0 2rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          paddingBottom: "2rem",
          minHeight: "40rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
            style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <h3 className={classes.title}>CREATE ACCOUNT</h3>
          </GridItem>
        </GridContainer>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={6}
              sm={5}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="First name"
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                inputProps={{
                  required: true,
                  name: "firstName",
                  value: formik.values.firstName,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.firstName &&
                    Boolean(formik.errors.firstName),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.firstName &&
                    Boolean(formik.errors.firstName),
                }}
              />
            </GridItem>
            <GridItem
              xs={6}
              sm={5}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Last name"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                inputProps={{
                  required: true,
                  name: "lastName",
                  value: formik.values.lastName,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.lastName && Boolean(formik.errors.lastName),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.lastName && Boolean(formik.errors.lastName),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="ID"
                error={formik.touched.id && Boolean(formik.errors.id)}
                helperText={formik.touched.id && formik.errors.id}
                inputProps={{
                  required: true,
                  name: "id",
                  autoComplete: "off",
                  value: formik.values.id,
                  onChange: formik.handleChange,
                  error: formik.touched.id && Boolean(formik.errors.id),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error: formik.touched.id && Boolean(formik.errors.id),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                inputProps={{
                  required: true,
                  name: "password",
                  type: "password",
                  autoComplete: "off",
                  value: formik.values.password,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.password && Boolean(formik.errors.password),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.password && Boolean(formik.errors.password),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Confirm Password"
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                inputProps={{
                  required: true,
                  name: "confirmPassword",
                  type: "password",
                  autoComplete: "off",
                  value: formik.values.confirmPassword,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                inputProps={{
                  required: true,
                  name: "email",
                  value: formik.values.email,
                  onChange: formik.handleChange,
                  error: formik.touched.email && Boolean(formik.errors.email),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error: formik.touched.email && Boolean(formik.errors.email),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem xs={12} sm={10} md={6}>
              <InputLabel
                htmlFor="mobile"
                style={{
                  textAlign: "start",
                  fontSize: "14px",
                  color: "#aaa",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.62857",
                }}>
                Mobile *
              </InputLabel>
              <CustomIntlTelInput
                error={Boolean(formik.errors.mobile)}
                helperText={formik.errors.mobile && formik.errors.mobile.phone}
                inputProps={{
                  autoPlaceholder: false,
                  formatOnInit: false,
                  preferredCountries: ["kr"],
                  onSelectFlag: (phoneNum, countryObj) => {
                    const { iso2: country, dialCode } = countryObj
                    formik.setFormikState((prev) => ({
                      ...prev,
                      values: {
                        ...prev.values,
                        mobile: {
                          ...prev.values.mobile,
                          country,
                          countryCode: `+${dialCode}`,
                        },
                      },
                    }))
                  },
                  onPhoneNumberChange: (isValid, phone) =>
                    formik.setFormikState((prev) => ({
                      ...prev,
                      values: {
                        ...prev.values,
                        mobile: {
                          ...prev.values.mobile,
                          phone,
                        },
                      },
                    })),
                  value: formik.values.mobile.phone,
                  fieldName: "mobile",
                  fieldId: "mobile",
                  telInputProps: {
                    style: {
                      width: "100%",
                      border: 0,
                      color: "#3C4858",
                      borderBottom: "1px solid #D2D2D2",
                      paddingBottom: "2px",
                      fontSize: "18px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: "400",
                      lineHeight: "1.62857",
                      // "&:before": { borderBottom: "1px solid #D2D2D2" },
                      // "&:after": { borderBottom: "2px solid #3f51b5" },
                    },
                  },
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  style: { paddingTop: "4px" },
                  error: formik.touched.mobile && Boolean(formik.errors.mobile),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <FormControl
                fullWidth
                className={
                  formik.errors.country
                    ? selectClasses.selectFormControlError
                    : selectClasses.selectFormControl
                }
                error={Boolean(formik.errors.country)}>
                <InputLabel
                  htmlFor="country"
                  className={selectClasses.selectLabel}>
                  Country *
                </InputLabel>
                <Select
                  MenuProps={{
                    className: selectClasses.selectMenu,
                  }}
                  classes={{
                    select: selectClasses.selectCountry,
                  }}
                  error={Boolean(formik.errors.country)}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  inputProps={{
                    required: true,
                    id: "country",
                    name: "country",
                  }}>
                  <MenuItem
                    disabled
                    classes={{
                      root: selectClasses.selectMenuItem,
                    }}>
                    Select Country
                  </MenuItem>
                  {intlTelCountry.getCountries().map(({ name, iso2 }) => (
                    <MenuItem
                      key={iso2}
                      classes={{
                        root: selectClasses.selectMenuItem,
                        selected: selectClasses.selectMenuItemSelected,
                      }}
                      value={iso2}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.country ? (
                  <FormHelperText error style={{ textAlign: "center" }}>
                    {formik.errors.country}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Detailed Address"
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                inputProps={{
                  required: true,
                  name: "address",
                  value: formik.values.address,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.address && Boolean(formik.errors.address),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.address && Boolean(formik.errors.address),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="City"
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                inputProps={{
                  required: true,
                  name: "city",
                  value: formik.values.city,
                  onChange: formik.handleChange,
                  error: formik.touched.city && Boolean(formik.errors.city),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error: formik.touched.city && Boolean(formik.errors.city),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Province"
                error={
                  formik.touched.province && Boolean(formik.errors.province)
                }
                helperText={formik.touched.province && formik.errors.province}
                inputProps={{
                  required: true,
                  name: "province",
                  value: formik.values.province,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.province && Boolean(formik.errors.province),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.province && Boolean(formik.errors.province),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Postal Code"
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                helperText={
                  formik.touched.postalCode && formik.errors.postalCode
                }
                inputProps={{
                  required: true,
                  name: "postalCode",
                  value: formik.values.postalCode,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error:
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              {submitErrMsg && (
                <FormHelperText error style={{ textAlign: "center" }}>
                  {submitErrMsg}
                </FormHelperText>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}>
            <GridItem
              xs={4}
              sm={3}
              md={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  formik.resetForm()
                }}>
                Clear
              </Button>
            </GridItem>
            <GridItem
              xs={8}
              sm={7}
              md={4}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={joinMutation.isLoading}
                type="submit">
                Create
              </Button>
              {joinMutation.isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </GridItem>
          </GridContainer>
        </form>
      </div>
    </div>
  )
}
