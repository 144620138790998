import customAxiosInstance from "api/axios"

/**
 * @params {
 *    quotationId: string,
 *    quotationPartId: string,
 * }
 */
export const deleteAdminQuotationPart = async ({
  quotationId = "",
  quotationPartId = "",
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "delete",
      url: `/quotations/${quotationId}/quotation_parts/${quotationPartId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default deleteAdminQuotationPart
