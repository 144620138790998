import customAxiosInstance from "api/axios"

/**
 * @params categoryId: string
 */
export const getSubCategory = async (categoryId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/categories/${categoryId}/sub_categories`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default getSubCategory
