import customAxiosInstance from "api/axios"

/**
 * @params { partBrandId: string, code: string, name: srting }
 */
export const updatePartBrand = async ({ partBrandId, code, name }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/part_brands/${partBrandId}`,
      data: { code, name },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updatePartBrand
