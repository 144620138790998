export default {
  picker: {},
  input: {
    textAlign: "center",
    cursor: "pointer",
    "&:after": {
      borderBottom: "2px solid #003C8D",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2",
    },
    "&:before": {
      borderBottom: "1px solid #D2D2D2",
    },
  },
}
