import customAxiosInstance from "api/axios"

/**
 * @params {
 *    fkPartBrand: string,
 *    fkSubCategory: string,
 *    code: string,
 *    name: string,
 *    aliases: string,
 *    detail: string,
 *    isHidden: boolean
 * }
 */
export const createPart = async ({
  fkPartBrand = "",
  fkSubCategory = "",
  code,
  name,
  aliases,
  detail,
  isHidden,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/parts`,
      data: {
        fkPartBrand,
        fkSubCategory,
        code,
        name,
        aliases,
        detail,
        isHidden,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default createPart
