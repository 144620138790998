/**
 * @params  {
 *  presignedUrl: string,
 *  file: File
 * }
 */
export const uploadImg = async ({ presignedUrl = "", file }) => {
  try {
    const response = await fetch(presignedUrl, {
      method: "put",
      body: file,
    })
    return await response.text()
  } catch (err) {
    console.log("Error creating presigned URL", err)
  }
}

export default uploadImg
