import customAxiosInstance from "api/axios"

/**
 * @params {
 *    userId: string,
 *    partId: string
 * }
 */
export const deletePartInCart = async ({ userId = "", partId = "" }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "delete",
      url: `/users/${userId}/carts/${partId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default deletePartInCart
