import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import ImageViewer from "react-simple-image-viewer"
import PropTypes from "prop-types"

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

export default function ProductItemImg({ mainSrc, images = [], ...props }) {
  const classesShoppingCart = useStylesShoppingCart()
  const [currentImage, setCurrentImage] = React.useState(0)
  const [isViewerOpen, setIsViewerOpen] = React.useState(false)
  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  return (
    <div
      className={classesShoppingCart.imgContainer}
      onClick={() => openImageViewer(0)}>
      <img
        width="70"
        key={0}
        src={mainSrc}
        className={classesShoppingCart.img}
        {...props}
      />
      {isViewerOpen && images.length > 0 && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}
    </div>
  )
}

ProductItemImg.propTypes = {
  mainSrc: PropTypes.string,
  images: PropTypes.array,
  alt: PropTypes.string,
}
