import customAxiosInstance from "api/axios"

/**
 * @params {
 *    account: string,
 *    password: string
 * }
 */
export const login = async ({ account, password }) => {
  try {
    return customAxiosInstance.request({
      method: "post",
      url: `/auth`,
      data: { account, password },
    })
  } catch (e) {
    console.error(e)
  }
}

export default login
