import { dangerColor } from "assets/jss/material-kit-pro-react"
import {
  container,
  title,
  cardTitle,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  grayColor,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js"

import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.js"
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js"

const styles = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  cardTitle,
  ...buttonGroup,
  ...tooltips,
  container: {
    ...container,
    zIndex: 1,
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  imgContainer: {
    width: "70px",
    height: "70px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    "& img": {
      // width: "100%",
    },
  },
  description: {
    maxWidth: "150px",
  },
  detail: {
    maxWidth: "10rem",
    whiteSpace: "initial",
  },
  email: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  contentCenter: {
    "& > div,& > span,& > img": {
      margin: "0 auto",
    },
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.7em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tdNameAnchor: {
    color: grayColor[1],
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.95em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "150px",
    fontWeight: "300",
    fontSize: "1.325em !important",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tdNumberSmall: {
    marginRight: "3px",
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0",
    },
  },
  customFont: {
    fontSize: "16px !important",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  actionButton: {
    margin: "0px",
    padding: "5px",
  },
  textCenter: {
    textAlign: "center",
  },
  textBoldAndDanger: {
    fontWeight: "bold",
    color: dangerColor[0],
  },
  borderBox: {
    borderTop: "1px solid " + grayColor[6],
    borderBottom: "1px solid " + grayColor[6],
    borderRight: "1px solid " + grayColor[6],
    "&:nth-child(1)": {
      borderLeft: "1px solid " + grayColor[6],
    },
  },
  textRight: {
    textAlign: "right",
  },
  categoryList: {
    background: grayColor[2],
    color: "#000",
    fontSize: "14px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}

export default styles
