import React from "react"
import PropTypes from "prop-types"

import * as yup from "yup"
import Card from "components/Card/Card.js"
import { Box, CircularProgress, Paper } from "@material-ui/core"

import Button from "components/CustomButtons/Button.js"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import CustomInput from "components/CustomInput/CustomInput"
import QuotationTableItemComment from "./QuotationTableItemComment"
import QuotationTableItemDetail from "./QuotationTableItemDetail"
import { useMutation, useQuery } from "@tanstack/react-query"
import getUserQuotationComment from "api/userQuotation/getUserQuotationComment"
import getUserQuotationPart from "api/userQuotation/getUserQuotationPart"
import createUserQuotationComment from "api/userQuotation/createUserQuotationComment"
import updateUserQuotation from "api/userQuotation/updateUserQuotation"
import { useFormik } from "formik"
import updateUserQuotationPart from "api/userQuotation/updateUserQuotationPart"
import setCacheData from "api/cache/setCacheData"

const QuotationPartSchema = yup.object().shape({
  comment: yup.string(),
  fkPart: yup.string(),
  fkQuotation: yup.string(),
  id: yup.string(),
  orderQuantity: yup.number(),
  quotationQuantity: yup.number(),
  unitPrice: yup.number(),
})

const BrandSchema = yup.object().shape({
  code: yup.string(),
  id: yup.string(),
  name: yup.string(),
})

const PartSchema = yup.object().shape({
  code: yup.string(),
  detail: yup.string(),
  fkPartBrand: yup.string(),
  fkSubCategory: yup.string(),
  id: yup.string(),
  isHidden: yup.number(),
  name: yup.string(),
})

const UpdatedPartsSchema = yup.object().shape({
  quotationPart: QuotationPartSchema,
  rel: yup.object().shape({
    brand: BrandSchema,
    part: PartSchema,
  }),
})

const validationSchema = yup.object({
  comment: yup.string("Enter your comment"),
  updatedParts: yup.array().of(UpdatedPartsSchema),
})

export default function QuotationDetail({ userId, selectedQuotationRow }) {
  const [
    ,
    quotationId,
    ,
    ,
    quotationCurrency,
    ,
    quotationStatus,
  ] = selectedQuotationRow
  const [
    quotationCommentDataList,
    setQuotationCommentDataList,
  ] = React.useState([])
  const [isRequesting, setIsRequesting] = React.useState(false)
  const [isRejecting, setIsRejecting] = React.useState(false)
  const [isApproving, setIsApproving] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      comment: "",
      updatedParts: [],
    },
    validationSchema: validationSchema,
  })

  const { data: getUserQuotationCommentResponse } = useQuery(
    [`user_quotation_comment_${quotationId}`],
    () => getUserQuotationComment({ userId, quotationId }),
  )
  const { data: getUserQuotationPartResponse } = useQuery(
    [`user_quotation_part_${quotationId}`],
    () => getUserQuotationPart({ userId, quotationId }),
  )
  const createUserQuotationCommentMutation = useMutation(
    createUserQuotationComment,
  )
  const updateUserQuotationMutation = useMutation(updateUserQuotation)
  const updateUserQuotationPartMutation = useMutation(updateUserQuotationPart)
  const setCacheDataMutation = useMutation(setCacheData)

  React.useEffect(() => {
    if (getUserQuotationPartResponse && getUserQuotationPartResponse.data) {
      formik.setFieldValue(
        "updatedParts",
        getUserQuotationPartResponse.data.quotationParts,
      )
    }
  }, [getUserQuotationPartResponse])

  React.useEffect(() => {
    if (
      getUserQuotationCommentResponse &&
      getUserQuotationCommentResponse.data
    ) {
      setQuotationCommentDataList(
        getUserQuotationCommentResponse.data.quotationComments,
      )
    }
  }, [getUserQuotationCommentResponse])

  return (
    <>
      <Paper
        elevation={0}
        style={{
          padding: "1rem",
          margin: "2rem auto",
          textAlign: "start",
          width: "100%",
          maxWidth: 1080,
        }}>
        {formik.values.updatedParts && (
          <QuotationTableItemDetail
            formik={formik}
            userId={userId}
            quotationId={quotationId}
            quotationStatus={quotationStatus}
            quotationCurrency={quotationCurrency}
          />
        )}
        {quotationCommentDataList && (
          <QuotationTableItemComment
            quotationCommentDataList={quotationCommentDataList}
          />
        )}
        {quotationStatus === "Cancelled by customer" ? null : (
          <Box sx={{ margin: "2rem 0" }}>
            <GridContainer
              style={{ display: "flex", justifyContent: "center" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Card
                  style={{
                    padding: "1rem",
                    margin: "1rem 0",
                  }}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      error:
                        formik.touched.comment &&
                        Boolean(formik.errors.comment),
                    }}
                    error={
                      formik.touched.comment && Boolean(formik.errors.comment)
                    }
                    helperText={formik.touched.comment && formik.errors.comment}
                    inputProps={{
                      multiline: true,
                      name: "comment",
                      value: formik.values.comment,
                      onChange: formik.handleChange,
                      placeholder: "input any request comments...",
                      rows: 5,
                    }}
                  />
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer
              style={{ display: "flex", justifyContent: "center" }}>
              {quotationStatus === "Quoted" ||
              quotationStatus === "Re-Quoted" ? (
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    fullWidth
                    onClick={async () => {
                      await updateUserQuotationMutation.mutateAsync({
                        userId,
                        quotationId,
                        currency: quotationCurrency,
                        status: "Cancelled by customer",
                      })
                      alert("Your quote has been successfully canceled.")
                      window.location.reload()
                    }}>
                    CANCEL
                  </Button>
                </GridItem>
              ) : null}
              <GridItem xs={12} sm={12} md={2}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={async () => {
                    setIsRequesting(true)
                    if (
                      quotationStatus === "Quoted" ||
                      quotationStatus === "Re-Quoted"
                    ) {
                      await updateUserQuotationMutation.mutateAsync({
                        userId,
                        quotationId,
                        currency: quotationCurrency,
                        status: "Re-Requested",
                      })
                    }
                    await Promise.all(
                      formik.values.updatedParts.map((data) => {
                        const { quotationPart } = data
                        return updateUserQuotationPartMutation.mutateAsync({
                          userId,
                          quotationId,
                          quotationPartId: quotationPart.id,
                          comment: quotationPart.comment,
                          orderQuantity: quotationPart.orderQuantity,
                          quotationQuantity: quotationPart.quotationQuantity,
                          unitPrice: quotationPart.unitPrice,
                        })
                      }),
                    )

                    await setCacheDataMutation.mutateAsync({
                      quotationId,
                      data: {
                        currency: quotationCurrency,
                        updatedParts: formik.values.updatedParts,
                      },
                    })
                    if (formik.values.comment) {
                      setQuotationCommentDataList((prev) => [
                        ...prev,
                        {
                          comment: formik.values.comment,
                          fkQuotation: "",
                          fkUser: "",
                          id: "",
                          isOffice: 0,
                        },
                      ])
                      await createUserQuotationCommentMutation.mutateAsync({
                        userId,
                        quotationId,
                        comment: formik.values.comment,
                      })
                      alert("Your quote has been sent successfully.")
                    } else {
                      if (
                        !(
                          quotationStatus === "Quoted" ||
                          quotationStatus === "Re-Quoted"
                        )
                      )
                        alert("comment is empty.")
                    }
                    setIsRequesting(false)
                    await formik.setFieldValue("comment", "")
                    window.location.reload()
                  }}>
                  {quotationStatus === "Quoted" ||
                  quotationStatus === "Re-Quoted"
                    ? "RE-REQUEST"
                    : "SEND"}
                </Button>
                {isRequesting && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </GridItem>
              {quotationStatus === "Quoted" ||
              quotationStatus === "Re-Quoted" ? (
                <>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      color="danger"
                      fullWidth
                      onClick={async () => {
                        setIsRejecting(true)
                        await updateUserQuotationMutation.mutateAsync({
                          userId,
                          quotationId,
                          currency: quotationCurrency,
                          status: "Rejected by customer",
                        })
                        if (formik.values.comment) {
                          setQuotationCommentDataList((prev) => [
                            ...prev,
                            {
                              comment: formik.values.comment,
                              fkQuotation: "",
                              fkUser: "",
                              id: "",
                              isOffice: 0,
                            },
                          ])
                        }
                        await createUserQuotationCommentMutation.mutateAsync({
                          userId,
                          quotationId,
                          comment: formik.values.comment,
                        })
                        setIsRejecting(false)
                        alert("rejected the quote.")
                        window.location.reload()
                      }}>
                      REJECT
                    </Button>
                    {isRejecting && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      color="info"
                      fullWidth
                      onClick={async () => {
                        setIsApproving(true)
                        await updateUserQuotationMutation.mutateAsync({
                          userId,
                          quotationId,
                          currency: quotationCurrency,
                          status: "Approved by customer",
                        })
                        if (formik.values.comment) {
                          setQuotationCommentDataList((prev) => [
                            ...prev,
                            {
                              comment: formik.values.comment,
                              fkQuotation: "",
                              fkUser: "",
                              id: "",
                              isOffice: 0,
                            },
                          ])
                        }
                        await createUserQuotationCommentMutation.mutateAsync({
                          userId,
                          quotationId,
                          comment: formik.values.comment,
                        })
                        setIsApproving(false)
                        alert("Approved the quote.")
                        window.location.reload()
                      }}>
                      APPROVE
                    </Button>
                    {isApproving && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </GridItem>
                </>
              ) : null}
            </GridContainer>
          </Box>
        )}
      </Paper>
    </>
  )
}

QuotationDetail.defaultProps = {
  selectedQuotationRow: [],
  tableHeaderColor: "gray",
  hover: false,
  className: "",
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  detailCustomHeadCellClasses: [],
  detailCustomHeadClassesForCells: [],
  detailCustomCellClasses: [],
  detailCustomClassesForCells: [],
  quotationCurrency: null,
}

QuotationDetail.propTypes = {
  detailRef: PropTypes.object,
  selectedQuotationRow: PropTypes.array,
  userId: PropTypes.string,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  detailTableHead: PropTypes.arrayOf(PropTypes.string),
  detailTableData: PropTypes.array,
  hover: PropTypes.bool,
  className: PropTypes.string,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  cells: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
}
