import customAxiosInstance from "api/axios"

/**
 * @params { categoryId: string, name: stirng }
 */
export const updateCategory = async ({ categoryId = "", name }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/categories/${categoryId}`,
      data: {
        name,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateCategory
