import React from "react"
import PropTypes from "prop-types"
import getCacheData from "api/cache/getCacheData"
import { useQuery } from "@tanstack/react-query"

export default function QuotationTotalAmount({ quotationId }) {
  const [totalAmount, setTotalAmount] = React.useState(0)
  const { data: getCacheDataResponse } = useQuery(
    [`admin_cached_part_list_${quotationId}`, quotationId],
    ({ queryKey }) => getCacheData(queryKey[1]),
    { enabled: !!quotationId },
  )

  React.useEffect(() => {
    if (getCacheDataResponse && getCacheDataResponse.data) {
      if (getCacheDataResponse.data.cache.data.updatedParts.length > 0)
        setTotalAmount(
          getCacheDataResponse.data.cache.data.updatedParts
            .map(
              ({ quotationPart }) =>
                quotationPart.quotationQuantity * quotationPart.unitPrice,
            )
            .reduce((a, b) => a + b),
        )
    }
  }, [getCacheDataResponse])

  return totalAmount
}

QuotationTotalAmount.propTypes = {
  quotationId: PropTypes.string,
}
