import customAxiosInstance from "api/axios"

/**
 * @params {
 *    partId: string,
 *    name: string
 * }
 */
export const addPartImg = async ({ partId = "", name }) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/parts/${partId}/images`,
      data: { name },
    })
  } catch (e) {
    console.error(e)
  }
}

export default addPartImg
