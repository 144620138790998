import { IconButton, makeStyles, Typography } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
// import SearchIcon from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import CustomInput from "components/CustomInput/CustomInput"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import PropTypes from "prop-types"
import { useQuery } from "@tanstack/react-query"
import getUser from "api/user/getUser"
import moment from "moment"

const getFullnameRole = (role) => {
  switch (role) {
    case "A":
      return "Admin"
    case "M":
      return "Member"
    case "C":
      return "Customer"
    default:
      return "Unknown"
  }
}

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

const validationSchema = yup.object({
  userName: yup.string("Enter your userName"),
  userId: yup.string("Enter your userId"),
})

export default function UserList({ detailRef, onClickRow }) {
  const [userDataList, setUserDataList] = React.useState([])
  const [filteredUserDataList, setFilteredUserDataList] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [perPage] = React.useState(10)
  const formik = useFormik({
    initialValues: {
      userName: "",
      userId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { userName, userId } = values
      if (!userName && !userId) setFilteredUserDataList(userDataList)
      if (userName)
        setFilteredUserDataList(
          userDataList.filter(
            (user) =>
              user.firstName.includes(userName) ||
              user.lastName.includes(userName),
          ),
        )
      if (userId)
        setFilteredUserDataList(
          userDataList.filter((user) => user.account.includes(userId)),
        )
    },
  })

  const shoppingCartClasses = useStylesShoppingCart()

  const { isLoading: isGetUserLoading, data: getUserResponse } = useQuery(
    ["user"],
    getUser,
  )

  React.useEffect(() => {
    if (getUserResponse) {
      setUserDataList(getUserResponse.data.users)
      setFilteredUserDataList(getUserResponse.data.users)
    }
  }, [getUserResponse])

  const userList = filteredUserDataList.map((data) => {
    const { user, rel } = data
    const {
      firstName,
      lastName,
      account,
      email,
      countryCode,
      phone,
      role,
      isValid,
      updatedAt,
    } = user
    const { last_modified_by } = rel
    const fullnameRole = getFullnameRole(role)
    return [
      firstName,
      lastName,
      account,
      fullnameRole,
      `${countryCode} ${phone}`,
      email,
      isValid ? "Y" : "N",
      moment(updatedAt).format("YYYY-MM-DD HH-mm-ss"),
      last_modified_by.account,
    ]
  })
  const userTotalCount = filteredUserDataList.length
  const lastPage = Math.ceil(Number(userTotalCount / perPage))

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ margin: 0 }}>
        <GridContainer
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <GridItem
            xs={12}
            sm={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "4rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              사용자명
            </Typography>
            <CustomInput
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
              inputProps={{
                placeholder: "사용자명",
                name: "userName",
                value: formik.values.userName,
                onChange: formik.handleChange,
                error:
                  formik.touched.userName && Boolean(formik.errors.userName),
              }}
              formControlProps={{
                fullWidth: true,
                style: { margin: 0, padding: 0 },
                error:
                  formik.touched.userName && Boolean(formik.errors.userName),
              }}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "4rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              사용자 ID
            </Typography>
            <CustomInput
              error={formik.touched.userId && Boolean(formik.errors.userId)}
              helperText={formik.touched.userId && formik.errors.userId}
              inputProps={{
                placeholder: "사용자 ID",
                name: "userId",
                value: formik.values.userId,
                onChange: formik.handleChange,
                error: formik.touched.userId && Boolean(formik.errors.userId),
              }}
              formControlProps={{
                fullWidth: true,
                style: { margin: 0, padding: 0 },
                error: formik.touched.userId && Boolean(formik.errors.userId),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}></GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={2}
            style={{ display: "flex", justifyContent: "end" }}>
            <Button fullWidth type="submit" color="primary">
              Search
            </Button>
          </GridItem>
        </GridContainer>
      </form>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}>
          <Typography
            style={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.5em",
            }}>
            {(page - 1) * perPage + 1} -{" "}
            {userTotalCount <= (page - 1) * perPage + perPage
              ? userTotalCount
              : (page - 1) * perPage + perPage}{" "}
            of {userTotalCount}
          </Typography>
          <IconButton
            disabled={page <= 1}
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              if (page > 1) setPage(page - 1)
            }}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            disabled={page >= lastPage}
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              if (page < lastPage) setPage(page + 1)
            }}>
            <ChevronRightIcon />
          </IconButton>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginBottom: "2rem" }}>
        <GridItem xs={12} sm={12} md={12}>
          {isGetUserLoading ? null : (
            <>
              <div
                style={{ width: "100%", height: "1px", background: "#ddd" }}
              />
              <Table
                tableHead={[
                  "이름",
                  "성",
                  "사용자ID",
                  "역할",
                  "전화",
                  "이메일",
                  "유효",
                  "수정일",
                  "수정자",
                ]}
                tableShopping
                tableData={userList.filter(
                  (u, i) => i >= (page - 1) * perPage && i < page * perPage,
                )}
                customHeadCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                customCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail +
                    " " +
                    shoppingCartClasses.email,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                onClickTableData={(row) => {
                  const [, , userId] = row
                  const finded = getUserResponse.data.users.find(
                    ({ user: { account } }) => account === userId,
                  )
                  onClickRow(finded)
                  detailRef.current.scrollIntoView()
                }}
              />
            </>
          )}
        </GridItem>
      </GridContainer>
    </>
  )
}

UserList.defaultProps = {
  onClickRow: () => {},
}

UserList.propTypes = {
  detailRef: PropTypes.object,
  onClickRow: PropTypes.func,
}
