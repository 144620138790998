import customAxiosInstance from "api/axios"

/**
 * @params {
 *  quotationId: string,
 *  currency: string
 *  status: string
 * }
 */
export const updateAdminQuotationStatus = async ({
  quotationId = "",
  currency,
  status,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/quotations/${quotationId}`,
      data: { currency, status },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updateAdminQuotationStatus
