import customAxiosInstance from "api/axios"

/**
 * @params quotationId: string
 */
export const getAdminQuotationPart = async (quotationId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/quotations/${quotationId}/quotation_parts`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default getAdminQuotationPart
