import React from "react"
import PropTypes from "prop-types"

import { makeStyles, Tooltip, Typography } from "@material-ui/core"

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"

import Table from "components/Table/Table"
import Card from "components/Card/Card.js"
import CustomInput from "components/CustomInput/CustomInput"
import { Close } from "@material-ui/icons"
import { useMutation } from "@tanstack/react-query"
import deleteUserQuotationPart from "api/userQuotation/deleteUserQuotationPart"
import CustomModal from "components/Modal/Modal"
import setCacheData from "api/cache/setCacheData"

const useStyles = makeStyles(featuresStyle)
const useStylesShoppingCart = makeStyles(shoppingCartStyle)

export default function QuotationTableItemDetail({
  formik,
  userId,
  quotationId,
  quotationStatus,
  quotationCurrency = "-",
}) {
  const [updatePartLoading, setUpdatePartLoading] = React.useState(false)
  const classes = useStyles()
  const classesShoppingCart = useStylesShoppingCart()

  const setCacheDataMutation = useMutation(setCacheData)
  const deleteUserQuotationPartMutation = useMutation(deleteUserQuotationPart)

  const quotationPartList = formik.values.updatedParts.map(
    ({ quotationPart, rel }, i) => {
      const { part, brand } = rel
      const disalbedInputOrderQuntity =
        quotationStatus === "Cancelled by customer" ||
        quotationStatus === "Requested" ||
        quotationStatus === "Re-Requested" ||
        quotationStatus === "Rejected by customer" ||
        quotationStatus === "Approved by customer"

      return [
        i + 1,
        part ? part.code : "-",
        part.name ? part.name : "-",
        part ? part.detail : "-",
        brand ? brand.name : "-",
        disalbedInputOrderQuntity ? (
          quotationPart.orderQuantity
        ) : (
          <span
            key={1}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <CustomInput
              disabled={disalbedInputOrderQuntity}
              variant="outlined"
              inputProps={{
                disabled: disalbedInputOrderQuntity,
                style: {
                  textAlign: "center",
                  maxWidth: "9rem",
                  paddingLeft: "0.7rem",
                },
                className: "test",
                type: "number",
                min: 1,
                defaultValue: quotationPart.orderQuantity,
                onChange: async (e) => {
                  const filtered = formik.values.updatedParts.map((prev) =>
                    quotationPart.id === prev.quotationPart.id
                      ? {
                          ...prev,
                          quotationPart: {
                            ...prev.quotationPart,
                            orderQuantity: Number(e.target.value),
                          },
                        }
                      : prev,
                  )
                  await formik.setFieldValue("updatedParts", filtered)
                },
              }}
            />
          </span>
        ),
        quotationPart.quotationQuantity,
        quotationPart.unitPrice,
        quotationPart.quotationQuantity * quotationPart.unitPrice,
        quotationCurrency,
        quotationPart.comment ? quotationPart.comment : "-",
        disalbedInputOrderQuntity ? null : (
          <Tooltip
            key={1}
            id="close3"
            title="Remove item"
            placement="left"
            classes={{ tooltip: classes.tooltip }}>
            <CustomModal
              title=""
              body="Are you sure you want to delete it?"
              buttonProps={{
                link: true,
                round: true,
                size: "sm",
                fullWidth: true,
                className: classes.actionButton,
              }}
              onConfirm={async () => {
                setUpdatePartLoading(true)
                const filtered = formik.values.updatedParts.filter(
                  (prev) => quotationPart.id !== prev.quotationPart.id,
                )
                await formik.setFieldValue("updatedParts", filtered)
                await setCacheDataMutation.mutateAsync({
                  quotationId,
                  data: {
                    currency: formik.values.currency,
                    updatedParts: filtered,
                  },
                })
                await deleteUserQuotationPartMutation.mutateAsync({
                  userId,
                  quotationId,
                  quotationPartId: quotationPart.id,
                })
                setUpdatePartLoading(false)
              }}>
              <Close />
            </CustomModal>
          </Tooltip>
        ),
      ]
    },
  )

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        style={{
          fontSize: "1.25em",
          marginTop: "1rem",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: "400",
          lineHeight: "1.5em",
          textAlign: "start",
        }}>
        DETAILS · {quotationPartList.length}
      </Typography>
      <Card
        style={{
          padding: "1rem",
          margin: "1rem auto",
          textAlign: "start",
          width: "100%",
          maxWidth: 1080,
        }}>
        <Table
          striped
          tableHead={[
            "No",
            "PART No",
            "PART NAME",
            "DETAIL",
            "BRAND",
            "REQUEST QTY",
            "POSSIBLE QTY",
            "UNIT PRICE",
            "TOTAL AMT",
            "CURRENCY",
            "COMMENTS",
            "",
          ]}
          tableData={updatePartLoading ? [] : quotationPartList}
          tableShopping
          customHeadCellClasses={[
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
          ]}
          customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          customCellClasses={[
            classesShoppingCart.textCenter,
            classesShoppingCart.detail + " " + classesShoppingCart.textCenter,
            classesShoppingCart.detail + " " + classesShoppingCart.textCenter,
            classesShoppingCart.detail + " " + classesShoppingCart.textCenter,
            classesShoppingCart.detail + " " + classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
            classesShoppingCart.detail + " " + classesShoppingCart.textCenter,
            classesShoppingCart.textCenter,
          ]}
          customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        />
      </Card>
    </>
  )
}

QuotationTableItemDetail.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  className: "",
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  detailCustomHeadCellClasses: [],
  detailCustomHeadClassesForCells: [],
  detailCustomCellClasses: [],
  detailCustomClassesForCells: [],
}

QuotationTableItemDetail.propTypes = {
  formik: PropTypes.object,
  userId: PropTypes.string,
  quotationStatus: PropTypes.string,
  quotationCurrency: PropTypes.string,
  quotationId: PropTypes.string,
  partDataList: PropTypes.array,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  detailTableHead: PropTypes.arrayOf(PropTypes.string),
  detailTableData: PropTypes.array,
  hover: PropTypes.bool,
  className: PropTypes.string,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  cells: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
}
