import {
  makeStyles,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@material-ui/core"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import CustomInput from "components/CustomInput/CustomInput"
import CustomIntlTelInput from "components/CustomIntlTelInput/CustomIntlTelInput"
import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"
import switchStyles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js"
import PropTypes from "prop-types"
import { intlTelCountry } from "constants/intlTelCountry"
import createUser from "api/user/createUser"
import { useMutation } from "@tanstack/react-query"
import updateUserPassword from "api/user/updateUserPassword"
import updateUserInfo from "api/user/updateUserInfo"
import { dangerColor } from "assets/jss/material-kit-pro-react"

const useSelectStyles = makeStyles(selectStyles)
const useSwitchStyles = makeStyles(switchStyles)

const validationSchema = yup.object({
  role: yup
    .string("Enter your role")
    .required("Role is required")
    .notOneOf(["unselected", "1"], "Role is required"),
  firstName: yup
    .string("Enter your first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .required("Last name is required"),
  id: yup.string("Enter your id").required("ID is required"),
  password: yup.string("Enter your password"),
  confirmPassword: yup
    .string("Enter your Confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: yup.object({
    country: yup.string().required(),
    countryCode: yup.number().required(),
    phone: yup.string().required(),
  }),
  country: yup
    .string("Enter your country")
    .required("Country is required")
    .notOneOf(["unselected", "1"], "Country is required"),
  address: yup.string("Enter your address").required("Address is required"),
  city: yup.string("Enter your city").required("City is required"),
  province: yup.string("Enter your province").required("Province is required"),
  postalCode: yup
    .string("Enter your postal code")
    .required("Postal Code is required"),
  isValid: yup.bool().required(),
})

export default function UserDetail({
  detailRef,
  selectedUser,
  handleClickUserClear,
}) {
  const [errMsg, setErrMsg] = React.useState("")
  const formik = useFormik({
    initialValues: {
      role: "unselected",
      firstName: "",
      lastName: "",
      id: "",
      password: "",
      confirmPassword: "",
      email: "",
      mobile: { country: "kr", countryCode: "+82", phone: "" },
      country: "unselected",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      isValid: false,
    },
    validationSchema: validationSchema,
  })

  const selectClasses = useSelectStyles()
  const switchClasses = useSwitchStyles()

  const createUserMutation = useMutation(createUser, {
    onSuccess: () => {
      setErrMsg("")
      window.location.reload()
    },
  })
  const updateUserInfoMutation = useMutation(updateUserInfo, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const updateUserPasswordMutation = useMutation(updateUserPassword)

  React.useEffect(() => {
    if (selectedUser) {
      const { user } = selectedUser
      const mobileCountry = intlTelCountry.getCountryWithCode(user.countryCode)
      const selectedCountry = intlTelCountry.getCountryWithISO2(user.country)
      formik.setValues({
        role: user.role,
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.account,
        password: "",
        confirmPassword: "",
        email: user.email,
        mobile: {
          country: mobileCountry ? mobileCountry.iso2 : "kr",
          countryCode: user.countryCode,
          phone: user.phone,
        },
        country: selectedCountry ? selectedCountry.iso2 : "kr",
        address: user.address,
        city: user.city,
        province: user.province,
        postalCode: user.postalCode,
        isValid: Boolean(user.isValid),
      })
    }
  }, [selectedUser])

  React.useEffect(() => {
    if (createUserMutation.error) {
      if (createUserMutation.error.response.status === 409)
        setErrMsg(
          "Duplicate user account(id). Please check again after confirmation.",
        )
    }
  }, [createUserMutation.error])

  return (
    <>
      <div ref={detailRef}></div>
      <div
        style={{
          marginTop: "3rem",
          padding: "2rem",
        }}>
        <Typography
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.5em",
          }}>
          사용자 상세
        </Typography>
        <GridContainer style={{ display: "flex", justifyContent: "end" }}>
          <GridItem xs={12} sm={12} md={1}>
            <Button
              disabled={Boolean(selectedUser)}
              fullWidth
              color="info"
              onClick={async () => {
                formik.setTouched({
                  role: true,
                  firstName: true,
                  lastName: true,
                  id: true,
                  password: true,
                  confirmPassword: true,
                  email: true,
                  mobile: { phone: true },
                  country: true,
                  address: true,
                  city: true,
                  province: true,
                  postalCode: true,
                  isValid: false,
                })
                const errors = await formik.validateForm()
                if (
                  !errors.role &&
                  !errors.firstName &&
                  !errors.lastName &&
                  !errors.id &&
                  !errors.password &&
                  !errors.confirmPassword &&
                  !errors.email &&
                  !errors.country &&
                  !errors.address &&
                  !errors.city &&
                  !errors.province &&
                  !errors.postalCode
                ) {
                  const userInfo = {
                    account: formik.values.id,
                    password: formik.values.password,
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    role: formik.values.role,
                    phone: formik.values.mobile.phone,
                    countryCode: formik.values.mobile.countryCode,
                    email: formik.values.email,
                    country: formik.values.country,
                    address: formik.values.address,
                    city: formik.values.city,
                    province: formik.values.province,
                    postalCode: formik.values.postalCode,
                    isValid: formik.values.isValid ? 1 : 0,
                  }
                  createUserMutation.mutateAsync(userInfo)
                }
              }}>
              생성
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={1}>
            <Button
              disabled={!selectedUser}
              fullWidth
              color="primary"
              onClick={async () => {
                formik.setTouched({
                  role: true,
                  firstName: true,
                  lastName: true,
                  id: true,
                  password: true,
                  confirmPassword: true,
                  email: true,
                  mobile: { phone: true },
                  country: true,
                  address: true,
                  city: true,
                  province: true,
                  postalCode: true,
                  isValid: false,
                })
                const errors = await formik.validateForm()
                if (
                  !errors.role &&
                  !errors.firstName &&
                  !errors.lastName &&
                  !errors.id &&
                  !errors.password &&
                  !errors.confirmPassword &&
                  !errors.email &&
                  !errors.country &&
                  !errors.address &&
                  !errors.city &&
                  !errors.province &&
                  !errors.postalCode &&
                  selectedUser
                ) {
                  if (formik.values.password && formik.values.confirmPassword) {
                    await updateUserPasswordMutation.mutateAsync({
                      userId: selectedUser.user.id,
                      password: formik.values.password,
                    })
                  }
                  const userInfo = {
                    userId: selectedUser.user.id,
                    account: formik.values.id,
                    password: formik.values.password,
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    role: formik.values.role,
                    phone: formik.values.mobile.phone,
                    countryCode: formik.values.mobile.countryCode,
                    email: formik.values.email,
                    country: formik.values.country,
                    address: formik.values.address,
                    city: formik.values.city,
                    province: formik.values.province,
                    postalCode: formik.values.postalCode,
                    isValid: formik.values.isValid ? 1 : 0,
                  }
                  await updateUserInfoMutation.mutateAsync(userInfo)
                }
              }}>
              수정
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              disabled={!selectedUser}
              fullWidth
              color="dribbble"
              onClick={() => {
                formik.resetForm()
                handleClickUserClear()
              }}>
              초기화
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={12}
            md={8}
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "3rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              On/Off
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  id="isValid"
                  name="isValid"
                  checked={formik.values.isValid}
                  onChange={formik.handleChange}
                  value={formik.values.isValid}
                  classes={{
                    switchBase: switchClasses.switchBase,
                    checked: switchClasses.switchChecked,
                    thumb: switchClasses.switchIcon,
                    track: switchClasses.switchBar,
                  }}
                />
              }
              classes={{
                label: switchClasses.label,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <FormControl
              fullWidth
              className={
                formik.touched.role && Boolean(formik.errors.role)
                  ? selectClasses.selectFormControlError
                  : selectClasses.selectFormControl
              }
              error={formik.touched.role && Boolean(formik.errors.role)}>
              <InputLabel
                htmlFor="role"
                style={{
                  color:
                    formik.touched.role && Boolean(formik.errors.role)
                      ? dangerColor[0]
                      : "#aaa",
                }}
                error={formik.touched.role && Boolean(formik.errors.role)}>
                Role
              </InputLabel>
              <Select
                MenuProps={{
                  className: selectClasses.selectMenu,
                }}
                classes={{
                  select: selectClasses.select,
                }}
                value={formik.values.role}
                onChange={formik.handleChange}
                inputProps={{
                  required: true,
                  id: "role",
                  name: "role",
                }}>
                <MenuItem
                  disabled
                  value="unselected"
                  classes={{
                    root: selectClasses.selectMenuItem,
                  }}>
                  Select Role *
                </MenuItem>
                <MenuItem
                  classes={{
                    root: selectClasses.selectMenuItem,
                    selected: selectClasses.selectMenuItemSelected,
                  }}
                  value="A">
                  Admin
                </MenuItem>
                <MenuItem
                  classes={{
                    root: selectClasses.selectMenuItem,
                    selected: selectClasses.selectMenuItemSelected,
                  }}
                  value="M">
                  Member
                </MenuItem>
                <MenuItem
                  classes={{
                    root: selectClasses.selectMenuItem,
                    selected: selectClasses.selectMenuItemSelected,
                  }}
                  value="C">
                  Customer
                </MenuItem>
              </Select>
              {formik.touched.role && Boolean(formik.errors.role) ? (
                <FormHelperText error style={{ textAlign: "center" }}>
                  {formik.touched.role && formik.errors.role}
                </FormHelperText>
              ) : null}
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="ID"
              disabled={Boolean(selectedUser)}
              error={formik.touched.id && Boolean(formik.errors.id)}
              helperText={formik.touched.id && formik.errors.id}
              inputProps={{
                disabled: Boolean(selectedUser),
                name: "id",
                autoComplete: "off",
                value: formik.values.id,
                onChange: formik.handleChange,
                error: formik.touched.id && Boolean(formik.errors.id),
              }}
              formControlProps={{
                fullWidth: true,
                error: formik.touched.id && Boolean(formik.errors.id),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={6}
            sm={5}
            md={4}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="First name"
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              inputProps={{
                required: !selectedUser,
                name: "firstName",
                value: formik.values.firstName,
                onChange: formik.handleChange,
                error:
                  formik.touched.firstName && Boolean(formik.errors.firstName),
              }}
              formControlProps={{
                fullWidth: true,
                error:
                  formik.touched.firstName && Boolean(formik.errors.firstName),
              }}
            />
          </GridItem>
          <GridItem
            xs={6}
            sm={5}
            md={4}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Last name"
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              inputProps={{
                required: !selectedUser,
                name: "lastName",
                value: formik.values.lastName,
                onChange: formik.handleChange,
                error:
                  formik.touched.lastName && Boolean(formik.errors.lastName),
              }}
              formControlProps={{
                fullWidth: true,
                error:
                  formik.touched.lastName && Boolean(formik.errors.lastName),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            {errMsg && (
              <FormHelperText error style={{ textAlign: "center" }}>
                {errMsg}
              </FormHelperText>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              inputProps={{
                required: !selectedUser,
                name: "password",
                type: "password",
                value: formik.values.password,
                onChange: formik.handleChange,
                error:
                  formik.touched.password && Boolean(formik.errors.password),
              }}
              formControlProps={{
                fullWidth: true,
                error:
                  formik.touched.password && Boolean(formik.errors.password),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Confirm Password"
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              inputProps={{
                required: !selectedUser,
                name: "confirmPassword",
                type: "password",
                autoComplete: "off",
                value: formik.values.confirmPassword,
                onChange: formik.handleChange,
                error:
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword),
              }}
              formControlProps={{
                fullWidth: true,
                error:
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              inputProps={{
                required: !selectedUser,
                name: "email",
                value: formik.values.email,
                onChange: formik.handleChange,
                error: formik.touched.email && Boolean(formik.errors.email),
              }}
              formControlProps={{
                fullWidth: true,
                error: formik.touched.email && Boolean(formik.errors.email),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem xs={12} sm={10} md={8}>
            <InputLabel
              htmlFor="mobile"
              style={{
                textAlign: "start",
                fontSize: "14px",
                color:
                  formik.touched.mobile &&
                  formik.touched.mobile.phone &&
                  Boolean(formik.errors.mobile) &&
                  Boolean(formik.errors.mobile.phone)
                    ? dangerColor[0]
                    : "#aaa",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.62857",
              }}
              error={
                formik.touched.mobile &&
                formik.touched.mobile.phone &&
                Boolean(formik.errors.mobile) &&
                Boolean(formik.errors.mobile.phone)
              }>
              Mobile
            </InputLabel>
            <CustomIntlTelInput
              error={
                formik.touched.mobile &&
                formik.touched.mobile.phone &&
                Boolean(formik.errors.mobile) &&
                Boolean(formik.errors.mobile.phone)
              }
              helperText={
                formik.touched.mobile &&
                formik.touched.mobile.phone &&
                formik.errors.mobile &&
                formik.errors.mobile.phone
              }
              inputProps={{
                defaultCountry: selectedUser
                  ? intlTelCountry.getCountryWithCode(
                      selectedUser.user.countryCode,
                    ).iso2
                  : "kr",
                autoPlaceholder: false,
                formatOnInit: false,
                preferredCountries: ["kr"],
                onSelectFlag: (phoneNum, countryObj) => {
                  const { iso2: country, dialCode } = countryObj
                  formik.setFormikState((prev) => ({
                    ...prev,
                    values: {
                      ...prev.values,
                      mobile: {
                        ...prev.values.mobile,
                        country,
                        countryCode: `+${dialCode}`,
                      },
                    },
                  }))
                },
                onPhoneNumberChange: (isValid, phone) => {
                  formik.setFormikState((prev) => ({
                    ...prev,
                    values: {
                      ...prev.values,
                      mobile: {
                        ...prev.values.mobile,
                        phone,
                      },
                    },
                  }))
                },
                value: formik.values.mobile.phone,
                fieldName: "mobile.phone",
                fieldId: "mobile.phone",
                telInputProps: {
                  style: {
                    width: "100%",
                    border: 0,
                    color: "#3C4858",
                    borderBottom: "1px solid #D2D2D2",
                    paddingBottom: "2px",
                    fontSize: "18px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: "400",
                    lineHeight: "1.62857",
                    // "&:before": { borderBottom: "1px solid #D2D2D2" },
                    // "&:after": { borderBottom: "2px solid #3f51b5" },
                  },
                },
              }}
              formControlProps={{
                fullWidth: true,
                style: { paddingTop: "4px" },
                className:
                  formik.touched.mobile &&
                  formik.touched.mobile.phone &&
                  Boolean(formik.errors.mobile) &&
                  Boolean(formik.errors.mobile.phone)
                    ? selectClasses.selectFormControlError
                    : selectClasses.selectFormControl,
                error:
                  formik.touched.mobile &&
                  formik.touched.mobile.phone &&
                  Boolean(formik.errors.mobile) &&
                  Boolean(formik.errors.mobile.phone),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <FormControl
              fullWidth
              className={
                formik.touched.country && Boolean(formik.errors.country)
                  ? selectClasses.selectFormControlError
                  : selectClasses.selectFormControl
              }
              error={formik.touched.country && Boolean(formik.errors.country)}>
              <InputLabel
                htmlFor="country"
                style={{
                  color:
                    formik.touched.country && Boolean(formik.errors.country)
                      ? dangerColor[0]
                      : "#aaa",
                }}
                error={
                  formik.touched.country && Boolean(formik.errors.country)
                }>
                Country
              </InputLabel>
              <Select
                MenuProps={{
                  className: selectClasses.selectMenu,
                }}
                classes={{
                  select: selectClasses.select,
                }}
                value={formik.values.country}
                onChange={formik.handleChange}
                inputProps={{
                  required: !selectedUser,
                  id: "country",
                  name: "country",
                }}>
                <MenuItem
                  disabled
                  value="unselected"
                  classes={{
                    root: selectClasses.selectMenuItem,
                  }}>
                  Select Country *
                </MenuItem>
                {intlTelCountry.getCountries().map(({ name, iso2 }) => (
                  <MenuItem
                    key={iso2}
                    classes={{
                      root: selectClasses.selectMenuItem,
                      selected: selectClasses.selectMenuItemSelected,
                    }}
                    value={iso2}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.country && Boolean(formik.errors.country) ? (
                <FormHelperText error style={{ textAlign: "center" }}>
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              ) : null}
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Detailed Address"
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              inputProps={{
                required: !selectedUser,
                name: "address",
                value: formik.values.address,
                onChange: formik.handleChange,
                error: formik.touched.address && Boolean(formik.errors.address),
              }}
              formControlProps={{
                fullWidth: true,
                error: formik.touched.address && Boolean(formik.errors.address),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="City"
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              inputProps={{
                required: !selectedUser,
                name: "city",
                value: formik.values.city,
                onChange: formik.handleChange,
                error: formik.touched.city && Boolean(formik.errors.city),
              }}
              formControlProps={{
                fullWidth: true,
                error: formik.touched.city && Boolean(formik.errors.city),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Province"
              error={formik.touched.province && Boolean(formik.errors.province)}
              helperText={formik.touched.province && formik.errors.province}
              inputProps={{
                required: !selectedUser,
                name: "province",
                value: formik.values.province,
                onChange: formik.handleChange,
                error:
                  formik.touched.province && Boolean(formik.errors.province),
              }}
              formControlProps={{
                fullWidth: true,
                error:
                  formik.touched.province && Boolean(formik.errors.province),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <CustomInput
              labelText="Postal Code"
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              inputProps={{
                required: !selectedUser,
                name: "postalCode",
                value: formik.values.postalCode,
                onChange: formik.handleChange,
                error:
                  formik.touched.postalCode &&
                  Boolean(formik.errors.postalCode),
              }}
              formControlProps={{
                fullWidth: true,
                error:
                  formik.touched.postalCode &&
                  Boolean(formik.errors.postalCode),
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    </>
  )
}

UserDetail.propTypes = {
  selectedUser: PropTypes.object,
  detailRef: PropTypes.object,
  handleClickUserClear: PropTypes.func,
}
