import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import Input from "@material-ui/core/Input"
import OutlinedInput from "@material-ui/core/OutlinedInput"
// @material-ui/icons
// import Clear from "@material-ui/icons/Clear";
// import Check from "@material-ui/icons/Check";
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js"

const useStyles = makeStyles(styles)

export default function CustomInput(props) {
  const {
    formControlProps,
    labelText,
    helperText,
    id,
    disabled,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    variant,
  } = props
  const classes = useStyles()
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  })
  const inputClasses = classNames({
    [classes.input]: !disabled,
    [classes.disabledInput]: disabled,
    [classes.whiteInput]: white,
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl,
    )
  } else {
    formControlClasses = classes.formControl
  }
  let newInputProps = {
    maxLength: inputProps ? inputProps.maxLength : undefined,
    minLength: inputProps ? inputProps.minLength : undefined,
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      {variant === "outlined" ? (
        <OutlinedInput
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            // underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
          inputProps={{ ...newInputProps, ...inputProps }}
        />
      ) : (
        <Input
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
          inputProps={{ ...newInputProps, ...inputProps }}
        />
      )}
      {/* {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null} */}
      {helperText !== undefined ? (
        <FormHelperText error style={{ textAlign: "center" }}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  helperText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  white: PropTypes.bool,
}
