import customAxiosInstance from "api/axios"

/**
 * @params userId: string
 */
export const getCart = async (userId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "get",
      url: `/users/${userId}/carts`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default getCart
