import { blackColor } from "assets/jss/material-kit-pro-react"
import { hexToRgb } from "assets/jss/material-kit-pro-react"
import { main } from "assets/jss/material-kit-pro-react.js"

const sectionsPageStyle = {
  main: {
    ...main,
    borderBottomRightRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.27)",
  },
}

export default sectionsPageStyle
