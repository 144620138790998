import {
  MenuItem,
  Select,
  makeStyles,
  FormControl,
  FormHelperText,
  Tooltip,
  CircularProgress,
} from "@material-ui/core"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js"
import React from "react"
import CustomInput from "components/CustomInput/CustomInput"
import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"

import { CSVLink } from "react-csv"

import Button from "components/CustomButtons/Button.js"

import { QuotationCurrency } from "constants/currencies"
import moment from "moment"
import { useMutation, useQuery } from "@tanstack/react-query"
import updateAdminQuotationStatus from "api/adminQuotation/updateAdminQuotationStatus"
import createAdminQuotationComment from "api/adminQuotation/createAdminQuotationComment"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"
import { Close } from "@material-ui/icons"
import deleteAdminQuotationPart from "api/adminQuotation/deleteAdminQuotationPart"
import setCacheData from "api/cache/setCacheData"
import CustomModal from "components/Modal/Modal"
import addAdminQuotationPart from "api/adminQuotation/addAdminQuotationPart"
import getAdminQuotationPart from "api/adminQuotation/getAdminQuotationPart"
import updateAdminQuotationPart from "api/adminQuotation/updateAdminQuotationPart"

const useStyles = makeStyles(featuresStyle)
const useSelectStyles = makeStyles(selectStyles)
const useStylesShoppingCart = makeStyles(shoppingCartStyle)

const csvHeaders = [
  { label: "번호", key: "no" },
  { label: "파트번호", key: "partCode" },
  { label: "파트명", key: "partName" },
  { label: "브랜드", key: "brand" },
  { label: "주문수량", key: "orderQuantity" },
  { label: "견적수량", key: "quotationQuantity" },
  { label: "견적가격", key: "unitPrice" },
  { label: "합계", key: "totalAmount" },
  { label: "답변", key: "comment" },
  { label: "디테일", key: "detail" },
]

export default function DashboardDetailParts({ quotationId, formik }) {
  const [isSaving, setIsSaving] = React.useState(false)
  const [isQuoting, setIsQuoting] = React.useState(false)
  const [userId, setUserId] = React.useState("")
  const [originQuotationParts, setOriginQuotationParts] = React.useState([])

  const classes = useStyles()
  const selectClasses = useSelectStyles()
  const shoppingCartClasses = useStylesShoppingCart()

  const { data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )

  const { data: getAdminQuotationPartResponse } = useQuery(
    [`admin_quotation_part_${quotationId}`, quotationId],
    ({ queryKey }) => getAdminQuotationPart(queryKey[1]),
    { enabled: !!quotationId },
  )

  const addAdminQuotationPartMutation = useMutation(addAdminQuotationPart)
  const updateAdminQuotationPartMutation = useMutation(updateAdminQuotationPart)
  const createAdminQuotationCommentMutation = useMutation(
    createAdminQuotationComment,
  )
  const updateAdminQuotationStatusMutation = useMutation(
    updateAdminQuotationStatus,
  )
  const deleteAdminQuotationPartMutation = useMutation(deleteAdminQuotationPart)

  const setCacheDataMutation = useMutation(setCacheData)

  const disabledEditQuotation =
    formik.values.status === "Quoted" ||
    formik.values.status === "Re-Quoted" ||
    formik.values.status === "Approved by customer" ||
    formik.values.status === "Rejected by customer" ||
    formik.values.status === "Cancelled by customer"

  const partList = formik.values.updatedParts.map((data, i) => {
    const { quotationPart, rel } = data
    const { part, brand } = rel
    return [
      i + 1,
      part ? part.code : "-",
      part.name ? part.name : "-",
      brand ? brand.name : "-",
      quotationPart.orderQuantity,
      disabledEditQuotation ? (
        quotationPart.quotationQuantity
      ) : (
        <span
          key={quotationPart.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CustomInput
            variant="outlined"
            disabled={disabledEditQuotation}
            formControlProps={{
              style: { margin: 0, padding: 0 },
            }}
            inputProps={{
              disabled: disabledEditQuotation,
              style: {
                textAlign: "center",
                padding: 0,
                paddingLeft: "0.4rem",
                width: "5rem",
                fontSize: "12px",
              },
              className: "test",
              value: quotationPart.quotationQuantity,
              type: "number",
              min: 1,
              onChange: (e) => {
                const filtered = formik.values.updatedParts.map((prev) =>
                  quotationPart.fkPart === prev.quotationPart.fkPart
                    ? {
                        ...prev,
                        quotationPart: {
                          ...prev.quotationPart,
                          quotationQuantity: Number(e.target.value),
                        },
                      }
                    : prev,
                )
                formik.setFieldValue("updatedParts", filtered)
              },
              onFocus: (e) => e.target.select(),
            }}
          />
        </span>
      ),
      disabledEditQuotation ? (
        quotationPart.unitPrice
      ) : (
        <span
          key={quotationPart.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CustomInput
            variant="outlined"
            formControlProps={{
              style: { margin: 0, padding: 0 },
            }}
            disabled={disabledEditQuotation}
            inputProps={{
              disabled: disabledEditQuotation,
              style: {
                textAlign: "center",
                padding: 0,
                paddingLeft: "0.4rem",
                width: "5rem",
                fontSize: "12px",
              },
              className: "test",
              value: quotationPart.unitPrice,
              type: "number",
              min: 1,
              onChange: (e) => {
                const filtered = formik.values.updatedParts.map((prev) =>
                  quotationPart.fkPart === prev.quotationPart.fkPart
                    ? {
                        ...prev,
                        quotationPart: {
                          ...prev.quotationPart,
                          unitPrice: Number(e.target.value),
                        },
                      }
                    : prev,
                )
                formik.setFieldValue("updatedParts", filtered)
              },
              onFocus: (e) => e.target.select(),
            }}
          />
        </span>
      ),
      disabledEditQuotation ? (
        quotationPart.quotationQuantity * quotationPart.unitPrice
      ) : (
        <span
          key={quotationPart.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CustomInput
            variant="outlined"
            formControlProps={{
              style: { margin: 0, padding: 0 },
            }}
            inputProps={{
              disabled: true,
              style: {
                background: "#b2b2b2",
                textAlign: "center",
                padding: 0,
                paddingLeft: "0.4rem",
                width: "5rem",
                fontSize: "12px",
              },
              className: "test",
              value: quotationPart.quotationQuantity * quotationPart.unitPrice,
              type: "number",
              min: 1,
            }}
          />
        </span>
      ),
      disabledEditQuotation ? (
        quotationPart.comment
      ) : (
        <span
          key={quotationPart.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CustomInput
            variant="outlined"
            disabled={disabledEditQuotation}
            formControlProps={{
              style: { margin: 0, padding: 0 },
            }}
            inputProps={{
              disabled: disabledEditQuotation,
              multiline: true,
              style: {
                textAlign: "center",
                padding: 0,
                minWidth: "5rem",
                fontSize: "12px",
              },
              className: "test",
              value: quotationPart.comment,
              onChange: (e) => {
                const filtered = formik.values.updatedParts.map((prev) =>
                  quotationPart.fkPart === prev.quotationPart.fkPart
                    ? {
                        ...prev,
                        quotationPart: {
                          ...prev.quotationPart,
                          comment: e.target.value,
                        },
                      }
                    : prev,
                )
                formik.setFieldValue("updatedParts", filtered)
              },
              onFocus: (e) => e.target.select(),
            }}
          />
        </span>
      ),
      part.detail,
      <Tooltip
        key={quotationPart.id}
        title="Remove Part"
        classes={{ tooltip: classes.tooltip }}>
        <CustomModal
          title=""
          body="정말로 삭제하시겠습니까?"
          buttonProps={{
            link: true,
            round: true,
            size: "sm",
            fullWidth: true,
            className: classes.actionButton,
            disabled: disabledEditQuotation,
          }}
          onConfirm={async () => {
            const filtered = formik.values.updatedParts.filter(
              (prev) => quotationPart.fkPart !== prev.quotationPart.fkPart,
            )
            await formik.setFieldValue("updatedParts", filtered)
          }}>
          <Close />
        </CustomModal>
      </Tooltip>,
    ]
  })

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUserId(verifyTokenAndGetUserInfoResponse.data.user.id)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  React.useEffect(() => {
    if (getAdminQuotationPartResponse && getAdminQuotationPartResponse.data) {
      setOriginQuotationParts(getAdminQuotationPartResponse.data.quotationParts)
    }
  }, [getAdminQuotationPartResponse])

  return (
    <>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl
            fullWidth
            className={
              formik.touched.currency && Boolean(formik.errors.currency)
                ? selectClasses.selectFormControlError
                : selectClasses.selectFormControl
            }
            error={formik.touched.currency && Boolean(formik.errors.currency)}>
            <Select
              disabled={disabledEditQuotation}
              MenuProps={{
                className: selectClasses.selectMenu,
              }}
              classes={{
                select: selectClasses.select,
              }}
              value={formik.values.currency}
              onChange={formik.handleChange}
              inputProps={{
                disabled: disabledEditQuotation,
                required: true,
                id: "currency",
                name: "currency",
              }}>
              <MenuItem
                disabled
                value="unselected"
                classes={{
                  root: selectClasses.selectMenuItem,
                }}>
                Select Currency *
              </MenuItem>
              {QuotationCurrency.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.currency && Boolean(formik.errors.currency) && (
              <FormHelperText error style={{ textAlign: "center" }}>
                {formik.errors.currency}
              </FormHelperText>
            )}
          </FormControl>
        </GridItem>
        {formik.values.status === "Requested" ||
        formik.values.status === "Re-Requested" ? (
          <GridItem xs={12} sm={12} md={4} />
        ) : (
          <GridItem xs={12} sm={12} md={disabledEditQuotation ? 7 : 5} />
        )}
        {disabledEditQuotation ? null : (
          <>
            <GridItem
              xs={12}
              sm={12}
              md={1}
              style={{ display: "flex", justifyContent: "end" }}>
              <Button
                fullWidth
                disabled={isSaving}
                color="primary"
                onClick={async () => {
                  setIsSaving(true)
                  await setCacheDataMutation.mutateAsync({
                    quotationId,
                    data: {
                      currency: formik.values.currency,
                      updatedParts: formik.values.updatedParts,
                    },
                  })

                  setIsSaving(false)
                  window.location.reload()
                }}>
                저장
              </Button>
              {isSaving && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={2}
              style={{ display: "flex", justifyContent: "end" }}>
              <Button
                fullWidth
                disabled={isQuoting}
                color="dribbble"
                onClick={async () => {
                  const error = await formik.setTouched({ currency: true })
                  if (error.currency) return
                  setIsQuoting(true)
                  if (formik.values.status === "Cancelled by customer") {
                    alert("사용자가 취소한 견적이므로 발송되지 않습니다.")
                    setIsQuoting(false)
                    return window.location.reload()
                  }
                  await Promise.all(
                    originQuotationParts.map(({ quotationPart }) =>
                      deleteAdminQuotationPartMutation.mutateAsync({
                        quotationId,
                        quotationPartId: quotationPart.id,
                      }),
                    ),
                  )
                  const responses = await Promise.all(
                    formik.values.updatedParts.map(({ quotationPart }) =>
                      addAdminQuotationPartMutation.mutateAsync({
                        quotationId,
                        fkPart: quotationPart.fkPart,
                      }),
                    ),
                  )
                  await Promise.all(
                    responses.map(({ data: { quotationPart } }) => {
                      const finded = formik.values.updatedParts.find(
                        (updatedParts) =>
                          updatedParts.quotationPart.fkPart ===
                          quotationPart.fkPart,
                      )
                      return updateAdminQuotationPartMutation.mutateAsync({
                        quotationId,
                        quotationPartId: quotationPart.id,
                        quotationQuantity:
                          finded.quotationPart.quotationQuantity,
                        unitPrice: finded.quotationPart.unitPrice,
                        comment: finded.quotationPart.comment,
                        orderQuantity: finded.quotationPart.orderQuantity,
                      })
                    }),
                  )
                  if (formik.values.comment) {
                    await createAdminQuotationCommentMutation.mutateAsync({
                      quotationId,
                      fkUser: userId,
                      comment: formik.values.comment,
                    })
                  }
                  await updateAdminQuotationStatusMutation.mutateAsync({
                    quotationId,
                    currency: formik.values.currency,
                    status:
                      formik.values.status === "Requested"
                        ? "Quoted"
                        : "Re-Quoted",
                  })

                  await setCacheDataMutation.mutateAsync({
                    quotationId,
                    data: {
                      currency: formik.values.currency,
                      updatedParts: formik.values.updatedParts,
                    },
                  })
                  formik.setFieldValue("updatedParts", [])
                  setIsQuoting(false)
                  return window.location.reload()
                }}>
                저장 & 견적송신
              </Button>
              {isQuoting && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </GridItem>
          </>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={2}
          style={{ display: "flex", justifyContent: "end" }}>
          <CSVLink
            filename={`견적 요청 상세 ${quotationId} - ${moment().format(
              "YYYY-MM-DD, HH-mm-ss",
            )}`}
            data={formik.values.updatedParts.map((data, i) => {
              const { quotationPart, rel } = data
              const { part, brand } = rel
              return {
                no: i + 1,
                partCode: part ? part.code : "-",
                partName: part ? part.name : "-",
                brand: brand ? brand.name : "-",
                orderQuantity: quotationPart.orderQuantity,
                quotationQuantity: quotationPart.quotationQuantity,
                unitPrice: quotationPart.unitPrice,
                totalAmount:
                  quotationPart.quotationQuantity * quotationPart.unitPrice,
                comment: quotationPart.comment,
                detail: part.detail,
              }
            })}
            headers={csvHeaders}>
            <Button fullWidth color="github">
              인쇄
            </Button>
          </CSVLink>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {partList.length > 0 ? (
            <>
              <div
                style={{ width: "100%", height: "1px", background: "#ddd" }}
              />
              <Table
                tableHead={[
                  "번호",
                  "파트번호",
                  "파트명",
                  "브랜드",
                  "주문수량",
                  "견적수량",
                  "견적가격",
                  "합계",
                  "답변",
                  "디테일",
                  "",
                ]}
                tableShopping
                tableData={partList}
                customHeadCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                customCellClasses={[
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                  shoppingCartClasses.textCenter +
                    " " +
                    shoppingCartClasses.borderBox +
                    " " +
                    shoppingCartClasses.detail,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              />
            </>
          ) : null}
        </GridItem>
      </GridContainer>
    </>
  )
}

DashboardDetailParts.propTypes = {
  formik: PropTypes.object,
  quotationId: PropTypes.string,
}
