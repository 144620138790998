import {
  Paper,
  makeStyles,
  Typography,
  MenuItem,
  FormHelperText,
  Select,
  FormControl,
} from "@material-ui/core"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { useStyles } from "./styles"
import CustomInput from "components/CustomInput/CustomInput"

import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"
import { useMutation, useQuery } from "@tanstack/react-query"
import getPartBrand from "api/partBrand/getPartBrand"
import createPartBrand from "api/partBrand/createPartBrand"
import updatePartBrand from "api/partBrand/updatePartBrand"
import deletePartBrand from "api/partBrand/deletePartBrand"

const useSelectStyles = makeStyles(selectStyles)

const validationSchema = yup.object({
  partBrand: yup
    .string("Enter your partBrand")
    .required("partBrand is required")
    .notOneOf(["unselected", "1"], "partBrand is required"),
})

const validationSchema2 = yup.object({
  partBrandId: yup.string("Enter your partBrandId"),
  partBrandCode: yup
    .string("Enter your partBrandCode")
    .required("partBrandCode is required"),
  partBrandName: yup
    .string("Enter your partBrandName")
    .required("partBrandName is required"),
})

export default function SectionContent() {
  const [errMsg, setErrMsg] = React.useState("")
  const formik = useFormik({
    initialValues: {
      partBrand: "unselected",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  const formik2 = useFormik({
    initialValues: {
      partBrandId: "",
      partBrandCode: "",
      partBrandName: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  const classes = useStyles()
  const selectClasses = useSelectStyles()

  const { data: getPartBrandResponse } = useQuery(["part_brand"], getPartBrand)

  const partBrandList = getPartBrandResponse
    ? getPartBrandResponse.data.partBrands
    : []

  const createPartBrandMutation = useMutation(createPartBrand, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const updatePartBrandMutation = useMutation(updatePartBrand, {
    onSuccess: () => {
      window.location.reload()
    },
  })
  const deletePartBrandMutation = useMutation(deletePartBrand, {
    onSuccess: () => {
      window.location.reload()
    },
  })

  React.useEffect(() => {
    if (deletePartBrandMutation.error) {
      if (deletePartBrandMutation.error.response.status === 412)
        setErrMsg(
          "If you want to delete this part brand, you must delete all parts of this part brand.",
        )
    }
  }, [deletePartBrandMutation.error])

  React.useEffect(() => {
    if (createPartBrandMutation.error) {
      if (createPartBrandMutation.error.response.status === 409)
        setErrMsg(
          "Duplicate part brand. Please check again after confirmation.",
        )
    }
  }, [createPartBrandMutation.error])

  React.useEffect(() => {
    if (updatePartBrandMutation.error) {
      if (updatePartBrandMutation.error.response.status === 409)
        setErrMsg(
          "Duplicate part brand. Please check again after confirmation.",
        )
    }
  }, [updatePartBrandMutation.error])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper elevation={0} variant="outlined" style={{ padding: "1rem" }}>
        <div
          style={{
            padding: "2rem",
          }}>
          <GridContainer style={{ display: "flex", justifyContent: "end" }}>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={formik.values.partBrand !== "unselected"}
                fullWidth
                color="info"
                onClick={async () => {
                  formik2.setTouched({
                    partBrandCode: true,
                    partBrandName: true,
                  })
                  const errors = await formik2.validateForm()
                  if (!errors.partBrandCode && !errors.partBrandName) {
                    createPartBrandMutation.mutateAsync({
                      code: formik2.values.partBrandCode,
                      name: formik2.values.partBrandName,
                    })
                  }
                }}>
                파트 브랜드 생성
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={formik.values.partBrand === "unselected"}
                fullWidth
                color="primary"
                onClick={async () => {
                  formik2.setTouched({
                    partBrandCode: true,
                    partBrandName: true,
                  })
                  const errors = await formik2.validateForm()
                  if (!errors.partBrandCode && !errors.partBrandName) {
                    updatePartBrandMutation.mutateAsync({
                      partBrandId: formik2.values.partBrandId,
                      code: formik2.values.partBrandCode,
                      name: formik2.values.partBrandName,
                    })
                  }
                }}>
                파트 브랜드 수정
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                disabled={formik.values.partBrand === "unselected"}
                fullWidth
                color="danger"
                onClick={() => {
                  deletePartBrandMutation.mutateAsync(
                    formik2.values.partBrandId,
                  )
                }}>
                파트 브랜드 삭제
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "7rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                파트 브랜드
              </Typography>
              <FormControl
                fullWidth
                className={
                  formik.touched.partBrand && Boolean(formik.errors.partBrand)
                    ? selectClasses.selectFormControlError
                    : selectClasses.selectFormControl
                }
                error={
                  formik.touched.partBrand && Boolean(formik.errors.partBrand)
                }>
                <Select
                  MenuProps={{
                    className: selectClasses.selectMenu,
                  }}
                  classes={{
                    select: selectClasses.select,
                  }}
                  error={
                    formik.touched.partBrand && Boolean(formik.errors.partBrand)
                  }
                  value={formik.values.partBrand}
                  onChange={(e) => {
                    formik.handleChange(e)
                    if (e.target.value !== "unselected") {
                      const finded = partBrandList.find(
                        ({ id }) => id === e.target.value,
                      )
                      if (!finded) return
                      formik2.setFieldValue("partBrandId", finded.id)
                      formik2.setFieldValue("partBrandCode", finded.code)
                      formik2.setFieldValue("partBrandName", finded.name)
                    }
                  }}
                  inputProps={{
                    required: true,
                    id: "partBrand",
                    name: "partBrand",
                  }}>
                  <MenuItem value="unselected">파트 브랜드 선택</MenuItem>
                  {partBrandList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.partBrand && Boolean(formik.errors.partBrand) && (
                  <FormHelperText error style={{ textAlign: "center" }}>
                    {formik.errors.partBrand}
                  </FormHelperText>
                )}
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "7rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                파트 브랜드 코드
              </Typography>
              <CustomInput
                error={
                  formik2.touched.partBrandCode &&
                  Boolean(formik2.errors.partBrandCode)
                }
                helperText={
                  formik2.touched.partBrandCode && formik2.errors.partBrandCode
                }
                inputProps={{
                  placeholder: "파트 브랜드 코드",
                  name: "partBrandCode",
                  value: formik2.values.partBrandCode,
                  onChange: (e) => {
                    formik2.handleChange(e)
                    formik.setTouched({ partBrandCode: true })
                  },
                  error:
                    formik2.touched.partBrandCode &&
                    Boolean(formik2.errors.partBrandCode),
                }}
                formControlProps={{
                  fullWidth: true,
                  style: { margin: 0, padding: 0 },
                  error:
                    formik2.touched.partBrandCode &&
                    Boolean(formik2.errors.partBrandCode),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2} />
            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography
                style={{
                  color: "#003C8D",
                  marginRight: "1rem",
                  minWidth: "7rem",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}>
                파트 브랜드 이름
              </Typography>
              <CustomInput
                error={
                  formik2.touched.partBrandName &&
                  Boolean(formik2.errors.partBrandName)
                }
                helperText={
                  formik2.touched.partBrandName && formik2.errors.partBrandName
                }
                inputProps={{
                  placeholder: "파트 브랜드 이름",
                  name: "partBrandName",
                  value: formik2.values.partBrandName,
                  onChange: (e) => {
                    formik2.handleChange(e)
                    formik.setTouched({ partBrandName: true })
                  },
                  error:
                    formik2.touched.partBrandName &&
                    Boolean(formik2.errors.partBrandName),
                }}
                formControlProps={{
                  fullWidth: true,
                  style: { margin: 0, padding: 0 },
                  error:
                    formik2.touched.partBrandName &&
                    Boolean(formik2.errors.partBrandName),
                }}
              />
            </GridItem>
          </GridContainer>
          {errMsg && (
            <GridContainer>
              <GridItem>
                <FormHelperText error style={{ textAlign: "center" }}>
                  {errMsg}
                </FormHelperText>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </Paper>
    </main>
  )
}
