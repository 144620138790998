import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"

import CustomInput from "components/CustomInput/CustomInput"
import { useFormik } from "formik"
import * as yup from "yup"
import Button from "components/CustomButtons/Button.js"
import { CircularProgress, FormHelperText } from "@material-ui/core"
import { useMutation } from "@tanstack/react-query"
import { useHistory } from "react-router-dom"
import findPassword from "api/auth/findPassword"

const useStyles = makeStyles(featuresStyle)

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
})

export default function SectionContent() {
  const history = useHistory()
  const [submitErrMsg, setSubmitErrMsg] = React.useState("")
  const findPasswordMutation = useMutation(findPassword, {
    onSuccess: () => {
      alert(
        "We have sent the registered member's password recovery information to the entered email address. Please check your email.",
      )
      history.push("/")
    },
  })

  React.useEffect(() => {
    if (findPasswordMutation.error) {
      if (findPasswordMutation.error.response.status === 404)
        setSubmitErrMsg("This email does not exist.")
    }
  }, [findPasswordMutation.error])

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      findPasswordMutation.mutateAsync(values)
    },
  })

  const classes = useStyles()

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-5rem",
        background: "inhert",
      }}>
      <div
        className={classes.features1}
        style={{
          padding: "0 2rem",
          background: "#fff",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          paddingBottom: "2rem",
          minHeight: "40rem",
        }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
            style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <h3 className={classes.title}>FORGOT PASSWORD</h3>
          </GridItem>
        </GridContainer>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem
              xs={12}
              sm={10}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <CustomInput
                labelText="Email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                inputProps={{
                  required: true,
                  name: "email",
                  value: formik.values.email,
                  onChange: formik.handleChange,
                  error: formik.touched.email && Boolean(formik.errors.email),
                }}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  error: formik.touched.email && Boolean(formik.errors.email),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              {submitErrMsg && (
                <FormHelperText error style={{ textAlign: "center" }}>
                  {submitErrMsg}
                </FormHelperText>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}>
            <GridItem
              xs={8}
              sm={7}
              md={4}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={findPasswordMutation.isLoading}
                type="submit">
                Find Password
              </Button>
              {findPasswordMutation.isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </GridItem>
          </GridContainer>
        </form>
      </div>
    </div>
  )
}
