import customAxiosInstance from "api/axios"

/**
 * @params name: stirng
 */
export const createCategory = async (name) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "post",
      url: `/categories`,
      data: {
        name,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default createCategory
