import {
  IconButton,
  makeStyles,
  Typography,
  MenuItem,
  FormHelperText,
  Select,
  FormControl,
  CircularProgress,
} from "@material-ui/core"
import PropTypes from "prop-types"
import Close from "@material-ui/icons/Close"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
// core components
import ModalImage from "react-modal-image"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import CustomInput from "components/CustomInput/CustomInput"
import selectStyles from "assets/jss/material-kit-pro-react/customSelectStyle.js"
import switchStyles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js"
import Card from "components/Card/Card"

import { useMutation, useQuery } from "@tanstack/react-query"
import getPartBrand from "api/partBrand/getPartBrand"
import getCategory from "api/category/getCategory"
import getSubCategory from "api/category/getSubCategory"
import createPart from "api/part/createPart"
import updatePart from "api/part/updatePart"
import deletePart from "api/part/deletePart"
import RelPartForm from "./RelPartForm"
import PartImageForm from "./PartImageForm"
import addPartImg from "api/part/addPartImg"
import uploadImg from "api/file/uploadImg"
import deleteImg from "api/file/deleteImg"

const useSelectStyles = makeStyles(selectStyles)
const useSwitchStyles = makeStyles(switchStyles)

const validationSchema = yup.object({
  isShow: yup.bool().required(),
  partName: yup.string("Enter your partName").required("partName is required"),
  partCode: yup.string("Enter your partCode").required("partCode is required"),
  partBrand: yup
    .string("Enter your partBrand")
    .required("partBrand is required")
    .notOneOf(["unselected", "1"], "partBrand is required"),
  partBrandId: yup
    .string("Enter your partBrandId")
    .required("partBrandId is required"),
  partBrandName: yup
    .string("Enter your partBrandName")
    .required("partBrandName is required"),
  mainCategoryId: yup
    .string("Enter your mainCategoryId")
    .required("mainCategoryId is required"),
  selectedMainCategory: yup
    .string("Enter your selectedMainCategory")
    .required("selectedMainCategory is required")
    .notOneOf(["unselected"], "selectedMainCategory is required"),
  mainCategoryName: yup
    .string("Enter your mainCategoryName")
    .required("mainCategoryName is required"),
  subCategoryId: yup
    .string("Enter your subCategoryId")
    .required("subCategoryId is required"),
  selectedSubCategory: yup
    .string("Enter your selectedSubCategory")
    .required("selectedSubCategory is required")
    .notOneOf(["unselected", "1"], "selectedSubCategory is required"),
  subCategoryName: yup
    .string("Enter your subCategoryName")
    .required("subCategoryName is required"),
  detail: yup.string("Enter your detail").required("detail is required"),
})

export default function PartDetail({
  detailRef,
  selectedPart,
  handleClickPartClear,
}) {
  const [errMsg, setErrMsg] = React.useState("")
  const [asyncImageDataList, setAsyncImageDataList] = React.useState([])
  const [localImageDataList, setLocalImageDataList] = React.useState([])
  const [createLoading, setCreateLoading] = React.useState(false)
  const [updateLoading, setUpdateLoading] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      isShow: false,
      partCode: "",
      partName: "",

      partBrand: "unselected",
      selectedMainCategory: "unselected",
      selectedSubCategory: "unselected",

      partBrandName: "",
      mainCategoryName: "",
      subCategoryName: "",

      aliases: "",
      detail: "",
    },
    validationSchema: validationSchema,
  })

  const selectClasses = useSelectStyles()
  const switchClasses = useSwitchStyles()

  const {
    isLoading: getPartBrandLoading,
    data: getPartBrandResponse,
  } = useQuery(["part_brand"], getPartBrand)
  const { isLoading: getCategoryLoading, data: getCategoryResponse } = useQuery(
    ["categories"],
    getCategory,
  )
  const {
    isLoading: getSubCategoryLoading,
    fetchStatus: getSubCategoryFetchStatus,
    data: getSubCategoryResponse,
  } = useQuery(
    ["sub_categories", formik.values.mainCategoryId],
    ({ queryKey }) => getSubCategory(queryKey[1]),
    { enabled: formik.values.selectedMainCategory !== "unselected" },
  )

  const createPartMutation = useMutation(createPart, {
    onSuccess: () => {
      if (localImageDataList.length < 1) window.location.reload()
    },
  })
  const addPartImgMutation = useMutation(addPartImg)
  const deleteImgMutation = useMutation(deleteImg, {
    onSuccess: (res) => {
      if (res.data)
        setAsyncImageDataList((prev) =>
          prev.filter(({ id }) => id !== res.data.partImage.id),
        )
    },
  })
  const uploadImgMutation = useMutation(uploadImg)
  const updatePartMutation = useMutation(updatePart, {
    onSuccess: () => {
      if (localImageDataList.length < 1) window.location.reload()
    },
  })

  const deletePartMutation = useMutation(deletePart, {
    onSuccess: () => {
      window.location.reload()
    },
  })

  const partBrandList = getPartBrandResponse
    ? getPartBrandResponse.data.partBrands
    : []
  const mainCategoryList = getCategoryResponse
    ? getCategoryResponse.data.categories.map(({ id, name }) => ({ id, name }))
    : []
  const subCategoryList = getSubCategoryResponse
    ? getSubCategoryResponse.data.subCategories.map(({ id, name }) => ({
        id,
        name,
      }))
    : []

  const asyncImageList = asyncImageDataList.map(({ id, name, path }) => [
    <div key={id} style={{ maxWidth: "5rem" }}>
      <ModalImage
        small={`https://assets.echeol.com/${path}`}
        medium={`https://assets.echeol.com/${path}`}
        large={`https://assets.echeol.com/${path}`}
        alt="..."
      />
    </div>,
    name,
    <IconButton
      key={id}
      color="inherit"
      onClick={() => {
        deleteImgMutation.mutateAsync(id)
      }}>
      <Close color="error" />
    </IconButton>,
  ])

  React.useEffect(() => {
    if (selectedPart) {
      const mainCategory = mainCategoryList.find(
        ({ id }) => id === selectedPart.rel.subCategory.fkCategory,
      )
      formik.setValues({
        isShow: !selectedPart.part.isHidden,
        partCode: selectedPart.part.code,
        partName: selectedPart.part.name,
        partBrand: selectedPart.rel.brand.id,
        partBrandId: selectedPart.rel.brand.id,
        partBrandName: selectedPart.rel.brand.name,
        selectedMainCategory: selectedPart.rel.subCategory.fkCategory,
        mainCategoryId: selectedPart.rel.subCategory.fkCategory,
        mainCategoryName: mainCategory ? mainCategory.name : "",
        selectedSubCategory: selectedPart.rel.subCategory.id,
        subCategoryId: selectedPart.rel.subCategory.id,
        subCategoryName: selectedPart.rel.subCategory.name,
        aliases: selectedPart.part.aliases ? selectedPart.part.aliases : "",
        detail: selectedPart.part.detail,
      })
    }
  }, [selectedPart])

  React.useEffect(() => {
    if (selectedPart) setAsyncImageDataList(selectedPart.rel.images)
  }, [selectedPart, setAsyncImageDataList])

  React.useEffect(() => {
    if (deletePartMutation.error) {
      if (deletePartMutation.error.response.status === 412)
        setErrMsg(
          "If you want to delete this part, you must delete all quotations of this part.",
        )
    }
  }, [deletePartMutation.error])

  return (
    <>
      <div ref={detailRef} />
      <div
        style={{
          marginTop: "3rem",
          padding: "2rem",
        }}>
        <Typography
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.5em",
          }}>
          파트 상세
        </Typography>
        <GridContainer style={{ display: "flex", justifyContent: "end" }}>
          <GridItem xs={12} sm={12} md={1}>
            <Button
              disabled={Boolean(selectedPart)}
              fullWidth
              color="info"
              onClick={async () => {
                formik.setTouched({
                  isShow: true,
                  partName: true,
                  partCode: true,
                  partBrand: true,
                  selectedMainCategory: true,
                  selectedSubCategory: true,
                  detail: true,
                })
                const errors = await formik.validateForm()
                if (
                  !errors.isShow &&
                  !errors.partName &&
                  !errors.partCode &&
                  !errors.partBrand &&
                  !errors.selectedMainCategory &&
                  !errors.selectedSubCategory &&
                  !errors.detail
                ) {
                  setCreateLoading(true)
                  const partInfo = {
                    fkPartBrand: formik.values.partBrandId,
                    fkSubCategory: formik.values.subCategoryId,
                    code: formik.values.partCode,
                    name: formik.values.partName,
                    detail: formik.values.detail,
                    aliases: formik.values.aliases,
                    isHidden: formik.values.isShow ? 0 : 1,
                  }
                  const { data } = await createPartMutation.mutateAsync(
                    partInfo,
                  )
                  if (localImageDataList.length > 0) {
                    const reponses = await Promise.all(
                      localImageDataList.map((file) =>
                        addPartImgMutation.mutateAsync({
                          partId: data.part.id,
                          name: file.name,
                        }),
                      ),
                    )
                    const presignedUrls = reponses.map(
                      ({ data }) => data.presignedUrl,
                    )
                    await Promise.all(
                      localImageDataList.map((file, i) =>
                        uploadImgMutation.mutateAsync({
                          presignedUrl: presignedUrls[i],
                          file,
                        }),
                      ),
                    )
                    setCreateLoading(false)
                    window.location.reload()
                  }
                }
              }}>
              생성
            </Button>
            {createLoading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={1}>
            <Button
              disabled={!selectedPart}
              fullWidth
              color="primary"
              onClick={async () => {
                if (selectedPart) {
                  setUpdateLoading(true)
                  const partInfo = {
                    partId: selectedPart.part.id,
                    fkPartBrand: formik.values.partBrandId,
                    fkSubCategory: formik.values.subCategoryId,
                    code: formik.values.partCode,
                    name: formik.values.partName,
                    detail: formik.values.detail,
                    aliases: formik.values.aliases,
                    isHidden: formik.values.isShow ? 0 : 1,
                  }
                  const { data } = await updatePartMutation.mutateAsync(
                    partInfo,
                  )
                  if (localImageDataList.length > 0) {
                    const reponses = await Promise.all(
                      localImageDataList.map((file) =>
                        addPartImgMutation.mutateAsync({
                          partId: data.part.id,
                          name: file.name,
                        }),
                      ),
                    )
                    const presignedUrls = reponses.map(
                      ({ data }) => data.presignedUrl,
                    )
                    await Promise.all(
                      localImageDataList.map((file, i) =>
                        uploadImgMutation.mutateAsync({
                          presignedUrl: presignedUrls[i],
                          file,
                        }),
                      ),
                    )
                    setUpdateLoading(false)
                    window.location.reload()
                  }
                }
              }}>
              수정
            </Button>
            {updateLoading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={1}>
            <Button
              disabled={!selectedPart}
              fullWidth
              color="danger"
              onClick={() => {
                deletePartMutation.mutateAsync(selectedPart.part.id)
              }}>
              삭제
            </Button>
            {deletePartMutation.isLoading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              fullWidth
              color="dribbble"
              onClick={() => {
                setAsyncImageDataList([])
                setLocalImageDataList([])
                formik.resetForm()
                setErrMsg("")
                handleClickPartClear()
              }}>
              파트 정보 Clear
            </Button>
          </GridItem>
        </GridContainer>
        {errMsg && (
          <GridContainer>
            <GridItem>
              <FormHelperText error style={{ textAlign: "center" }}>
                {errMsg}
              </FormHelperText>
            </GridItem>
          </GridContainer>
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={2} />
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "5rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              파트 브랜드
            </Typography>
            <FormControl
              fullWidth
              className={
                formik.touched.partBrand && Boolean(formik.errors.partBrand)
                  ? selectClasses.selectFormControlError
                  : selectClasses.selectFormControl
              }
              error={
                formik.touched.partBrand && Boolean(formik.errors.partBrand)
              }>
              <Select
                MenuProps={{
                  className: selectClasses.selectMenu,
                }}
                classes={{
                  select: selectClasses.select,
                }}
                value={formik.values.partBrand}
                onChange={(e) => {
                  formik.setTouched({
                    partBrand: true,
                  })
                  formik.handleChange(e)
                  if (e.target.value !== "unselected") {
                    const finded = partBrandList.find(
                      ({ id }) => id === e.target.value,
                    )
                    if (!finded) return
                    formik.setTouched({
                      partBrandId: true,
                      partBrandName: true,
                    })
                    formik.setFieldValue("partBrandId", finded.id)
                    formik.setFieldValue("partBrandName", finded.name)
                  }
                }}
                inputProps={{
                  required: true,
                  id: "partBrand",
                  name: "partBrand",
                }}>
                <MenuItem value="unselected">파트 브랜드 선택</MenuItem>
                {getPartBrandLoading ? (
                  <MenuItem key={2} value={"loading"} style={{ height: 30 }}>
                    <CircularProgress
                      size={24}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  </MenuItem>
                ) : (
                  partBrandList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {formik.touched.partBrand && Boolean(formik.errors.partBrand) && (
                <FormHelperText error style={{ textAlign: "center" }}>
                  {formik.errors.partBrand}
                </FormHelperText>
              )}
            </FormControl>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "3rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              On/Off
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  id="isShow"
                  name="isShow"
                  checked={formik.values.isShow}
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setTouched({
                      isShow: true,
                    })
                  }}
                  value={formik.values.isShow}
                  classes={{
                    switchBase: switchClasses.switchBase,
                    checked: switchClasses.switchChecked,
                    thumb: switchClasses.switchIcon,
                    track: switchClasses.switchBar,
                  }}
                />
              }
              classes={{
                label: switchClasses.label,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2} />
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "5rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              파트 번호
            </Typography>
            <CustomInput
              error={formik.touched.partCode && Boolean(formik.errors.partCode)}
              helperText={formik.touched.partCode && formik.errors.partCode}
              inputProps={{
                placeholder: "파트 번호",
                name: "partCode",
                value: formik.values.partCode,
                onChange: (e) => {
                  formik.handleChange(e)
                  formik.setTouched({
                    partCode: true,
                  })
                },
                error:
                  formik.touched.partCode && Boolean(formik.errors.partCode),
              }}
              formControlProps={{
                fullWidth: true,
                style: { margin: 0, padding: 0 },
                error:
                  formik.touched.partCode && Boolean(formik.errors.partCode),
              }}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "5rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              파트명
            </Typography>
            <CustomInput
              error={formik.touched.partName && Boolean(formik.errors.partName)}
              helperText={formik.touched.partName && formik.errors.partName}
              inputProps={{
                placeholder: "파트명",
                name: "partName",
                value: formik.values.partName,
                onChange: (e) => {
                  formik.handleChange(e)
                  formik.setTouched({
                    partName: true,
                  })
                },
                error:
                  formik.touched.partName && Boolean(formik.errors.partName),
              }}
              formControlProps={{
                fullWidth: true,
                style: { margin: 0, padding: 0 },
                error:
                  formik.touched.partName && Boolean(formik.errors.partName),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2} />
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography
              style={{
                color: "#003C8D",
                marginRight: "1rem",
                minWidth: "5rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
              }}>
              카테고리
            </Typography>
            <FormControl
              fullWidth
              className={
                formik.touched.selectedMainCategory &&
                Boolean(formik.errors.selectedMainCategory)
                  ? selectClasses.selectFormControlError
                  : selectClasses.selectFormControl
              }
              error={
                formik.touched.selectedMainCategory &&
                Boolean(formik.errors.selectedMainCategory)
              }>
              <Select
                MenuProps={{
                  className: selectClasses.selectMenu,
                }}
                classes={{
                  select: selectClasses.select,
                }}
                value={formik.values.selectedMainCategory}
                onChange={(e) => {
                  formik.handleChange(e)
                  formik.setTouched({
                    mainCategory: true,
                  })
                  if (e.target.value === "unselected") {
                    formik.setFieldValue("mainCategoryId", "")
                    return formik.setFieldValue("mainCategoryName", "")
                  }
                  formik.setTouched({
                    selectedSubCategory: true,
                    subCategoryName: true,
                  })
                  formik.setFieldValue("selectedSubCategory", "unselected")
                  formik.setFieldValue("subCategoryName", "")
                  const finded = mainCategoryList.find(
                    ({ id }) => id === e.target.value,
                  )
                  if (!finded) return
                  formik.setTouched({
                    mainCategoryId: true,
                    mainCategoryName: true,
                  })
                  formik.setFieldValue("mainCategoryId", finded.id)
                  formik.setFieldValue("mainCategoryName", finded.name)
                }}
                inputProps={{
                  required: true,
                  id: "selectedMainCategory",
                  name: "selectedMainCategory",
                }}>
                <MenuItem value="unselected">메인 카테고리 선택</MenuItem>
                {getCategoryLoading ? (
                  <MenuItem key={2} value="loading" style={{ height: 30 }}>
                    <CircularProgress
                      size={24}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  </MenuItem>
                ) : (
                  mainCategoryList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {formik.touched.selectedMainCategory &&
                Boolean(formik.errors.selectedMainCategory) && (
                  <FormHelperText error style={{ textAlign: "center" }}>
                    {formik.errors.selectedMainCategory}
                  </FormHelperText>
                )}
            </FormControl>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}>
            <FormControl
              fullWidth
              className={
                formik.touched.selectedSubCategory &&
                Boolean(formik.errors.selectedSubCategory)
                  ? selectClasses.selectFormControlError
                  : selectClasses.selectFormControl
              }
              error={
                formik.touched.selectedSubCategory &&
                Boolean(formik.errors.selectedSubCategory)
              }>
              <Select
                MenuProps={{
                  className: selectClasses.selectMenu,
                }}
                classes={{
                  select: selectClasses.select,
                }}
                value={formik.values.selectedSubCategory}
                onChange={(e) => {
                  formik.handleChange(e)
                  formik.setTouched({
                    selectedSubCategory: true,
                  })
                  if (e.target.value === "unselected") {
                    formik.setFieldValue("subCategoryId", "")
                    return formik.setFieldValue("subCategoryName", "")
                  }
                  const finded = subCategoryList.find(
                    ({ id }) => id === e.target.value,
                  )
                  if (!finded) return
                  formik.setTouched({
                    subCategoryId: true,
                    subCategoryName: true,
                  })
                  formik.setFieldValue("subCategoryId", finded.id)
                  formik.setFieldValue("subCategoryName", finded.name)
                }}
                inputProps={{
                  required: true,
                  id: "selectedSubCategory",
                  name: "selectedSubCategory",
                }}>
                <MenuItem value="unselected">서브 카테고리 선택</MenuItem>
                {getSubCategoryLoading ? (
                  getSubCategoryFetchStatus === "idle" ? null : (
                    <MenuItem key={2} value={"loading"} style={{ height: 30 }}>
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    </MenuItem>
                  )
                ) : (
                  subCategoryList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {formik.touched.selectedSubCategory &&
                Boolean(formik.errors.selectedSubCategory) && (
                  <FormHelperText error style={{ textAlign: "center" }}>
                    {formik.errors.selectedSubCategory}
                  </FormHelperText>
                )}
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
          }}>
          <GridItem
            xs={12}
            sm={12}
            md={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <Typography
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.5em",
                color: "#000",
              }}>
              상품 설명을 입력하세요
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <GridItem
            xs={12}
            sm={12}
            md={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <Card
              style={{
                padding: "1rem",
                margin: "1rem 0",
              }}>
              <CustomInput
                error={formik.touched.detail && Boolean(formik.errors.detail)}
                helperText={formik.touched.detail && formik.errors.detail}
                formControlProps={{
                  fullWidth: true,
                  error: formik.touched.detail && Boolean(formik.errors.detail),
                }}
                inputProps={{
                  multiline: true,
                  placeholder: "input part detail...",
                  rows: 5,
                  name: "detail",
                  value: formik.values.detail,
                  onChange: (e) => {
                    formik.handleChange(e)
                    formik.setTouched({
                      detail: true,
                    })
                  },
                  error: formik.touched.detail && Boolean(formik.errors.detail),
                }}
              />
            </Card>
          </GridItem>
        </GridContainer>
        <PartImageForm
          asyncImageList={asyncImageList}
          uploadImgLoading={uploadImgMutation.isLoading}
          deleteImgLoading={deleteImgMutation.isLoading}
          localImageDataList={localImageDataList}
          setLocalImageDataList={setLocalImageDataList}
        />
        <RelPartForm formik={formik} selectedPart={selectedPart} />
      </div>
    </>
  )
}

PartDetail.propTypes = {
  selectedPart: PropTypes.object,
  detailRef: PropTypes.object,
  handleClickPartClear: PropTypes.func,
}
