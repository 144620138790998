import React from "react"
import PropTypes from "prop-types"
import { Input, InputAdornment } from "@material-ui/core"
import { EventNote } from "@material-ui/icons"

export default function CustomDatePickerInput(props) {
  return (
    <Input
      {...props}
      inputProps={{ className: props.className }}
      endAdornment={
        <InputAdornment position="end">
          <EventNote />
        </InputAdornment>
      }
    />
  )
}

CustomDatePickerInput.propTypes = {
  inputProps: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
}
