import customAxiosInstance from "api/axios"

/**
 * @params {
 *    userId: string,
 *    partId: string,
 *    quantity: number
 * }
 */
export const updatePartInCart = async ({
  userId = "",
  partId = "",
  quantity,
}) => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "put",
      url: `/users/${userId}/carts/${partId}`,
      data: { quantity },
    })
  } catch (e) {
    console.error(e)
  }
}

export default updatePartInCart
