import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import styles from "assets/jss/material-kit-pro-react/components/tableStyle.js"

const useStyles = makeStyles(styles)

export default function QuotationTableItem(props) {
  const {
    cells,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    onClickTableData,
  } = props

  const classes = useStyles()

  return (
    <>
      <TableRow
        hover={hover}
        className={props.className}
        onClick={onClickTableData}>
        {cells.map((cell, key) => {
          const tableCellClasses =
            classes.tableCell +
            " " +
            cx({
              [classes[colorsColls[coloredColls.indexOf(key)]]]:
                coloredColls.indexOf(key) !== -1,
              [customCellClasses[customClassesForCells.indexOf(key)]]:
                customClassesForCells.indexOf(key) !== -1,
            })
          return (
            <TableCell className={tableCellClasses} key={key}>
              {cell}
            </TableCell>
          )
        })}
      </TableRow>
    </>
  )
}

QuotationTableItem.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  className: "",
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  detailCustomHeadCellClasses: [],
  detailCustomHeadClassesForCells: [],
  detailCustomCellClasses: [],
  detailCustomClassesForCells: [],
  quotationCurrency: null,
}

QuotationTableItem.propTypes = {
  onClickTableData: PropTypes.func,
  quotationCurrency: PropTypes.string,
  quotationStatus: PropTypes.string,
  userId: PropTypes.string,
  quotationId: PropTypes.string,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  detailTableHead: PropTypes.arrayOf(PropTypes.string),
  detailTableData: PropTypes.array,
  hover: PropTypes.bool,
  className: PropTypes.string,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  cells: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  detailCustomCellClasses: PropTypes.arrayOf(PropTypes.string),
  detailCustomClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
}
