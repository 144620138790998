import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"

import { useHistory } from "react-router-dom"
import CustomModal from "components/Modal/Modal"
import { TextField } from "@material-ui/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import addPartToCart from "api/cart/addPartToCart"
import Button from "components/CustomButtons/Button.js"
import verifyTokenAndGetUserInfo from "api/auth/verifyTokenAndGetUserInfo"

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

export default function ProductItemToCartForm({ productId }) {
  const history = useHistory()
  const classesShoppingCart = useStylesShoppingCart()
  const [quantity, setQuantity] = React.useState(1)
  const [userId, setUserId] = React.useState("")
  const { data: verifyTokenAndGetUserInfoResponse } = useQuery(
    ["verify_token_and_get_user_info"],
    verifyTokenAndGetUserInfo,
    {
      refetchOnWindowFocus: true,
      retry: 0,
    },
  )
  const addPartToCartMutation = useMutation(addPartToCart)

  React.useEffect(() => {
    if (
      verifyTokenAndGetUserInfoResponse &&
      verifyTokenAndGetUserInfoResponse.data
    ) {
      setUserId(verifyTokenAndGetUserInfoResponse.data.user.id)
    }
  }, [verifyTokenAndGetUserInfoResponse])

  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <TextField
        hiddenLabel
        size="small"
        variant="outlined"
        style={{ width: "100%", maxWidth: "6.5rem" }}
        inputProps={{
          style: {
            textAlign: "center",
            marginLeft: "0.8rem",
            padding: "8px",
          },
          min: 1,
        }}
        value={quantity}
        onChange={(e) => {
          setQuantity(Number(e.target.value))
        }}
        type="number"
      />
      <div
        className={classesShoppingCart.buttonGroup}
        style={{ width: "100%", maxWidth: "6.5rem" }}>
        {userId ? (
          <CustomModal
            title=""
            body="The product has been placed in the cart. Would you like to check your cart?"
            buttonProps={{
              round: false,
              fullWidth: true,
              style: {
                padding: "6.5px 0",
              },
            }}
            onClick={async () => {
              if (userId) {
                await addPartToCartMutation.mutateAsync({
                  userId,
                  fkPart: productId,
                  quantity,
                })
              } else {
                // const prevCartItems =
                //   JSON.parse(localStorage.getItem("cartItems")) || [];
                // if (!prevCartItems) localStorage.setItem("cartItems", []);
                // if (prevCartItems.length > 0) {
                //   const finded = prevCartItems.find(
                //     (prev) => prev.part.id === productId
                //   );
                //   const news = finded
                //     ? prevCartItems.map((prev) => {
                //         const { part } = prev;
                //         if (part.id === productId)
                //           return {
                //             ...prev,
                //             part: {
                //               ...part,
                //               quantity: part.quantity
                //                 ? part.quantity + quantity
                //                 : quantity,
                //             },
                //           };
                //         return prev;
                //       })
                //     : [...prevCartItems, productInfo];
                //   localStorage.setItem("cartItems", JSON.stringify(news));
                // } else {
                //   localStorage.setItem(
                //     "cartItems",
                //     JSON.stringify([productInfo])
                //   );
                // }
              }
              setQuantity(1)
            }}
            onConfirm={(isConfirm) => {
              if (isConfirm) history.push("/cart")
            }}>
            ADD TO CART
          </CustomModal>
        ) : (
          <Button
            fullWidth={true}
            color="primary"
            style={{
              padding: "6.5px 0",
            }}
            onClick={() => {
              alert(
                "This service requires login. please try again after login.",
              )
            }}>
            ADD TO CART
          </Button>
        )}
      </div>
    </span>
  )
}

ProductItemToCartForm.propTypes = {
  productInfo: PropTypes.object,
  productId: PropTypes.string,
}
