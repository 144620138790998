import React from "react"

export const useProgressiveImage = (src = "") => {
  const [sourceLoaded, setSourceLoaded] = React.useState(null)

  React.useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

export default useProgressiveImage
