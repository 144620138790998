import customAxiosInstance from "api/axios"

/**
 * @params subCategoryId: string
 */
export const deleteSubCategory = async (subCategoryId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "delete",
      url: `/sub_categories/${subCategoryId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default deleteSubCategory
