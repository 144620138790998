import { Paper } from "@material-ui/core"
import React from "react"
import { useStyles } from "./styles"

import DashboardDetail from "./DashboardDetail"
import DashboardList from "./DashboardList"

export default function SectionContent() {
  const detailRef = React.useRef()
  const classes = useStyles()
  const [selectedQuotation, setSelectedQuotation] = React.useState()

  const handleClickQuotationClear = () => {
    setSelectedQuotation()
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper
        elevation={0}
        variant="outlined"
        style={{ padding: "1rem", maxWidth: "1960", width: "100%" }}>
        <DashboardList
          detailRef={detailRef}
          onClickRow={setSelectedQuotation}
          handleClickQuotationClear={handleClickQuotationClear}
        />
      </Paper>
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          padding: "1rem",
          maxWidth: "1960",
          width: "100%",
          marginTop: "1rem",
        }}>
        <DashboardDetail
          detailRef={detailRef}
          selectedQuotation={selectedQuotation}
          handleClickQuotationClear={handleClickQuotationClear}
        />
      </Paper>
    </main>
  )
}
