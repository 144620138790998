import customAxiosInstance from "api/axios"

/**
 * @params partId: string
 */
export const deletePart = async (partId = "") => {
  try {
    customAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`
    return customAxiosInstance.request({
      method: "delete",
      url: `/parts/${partId}`,
    })
  } catch (e) {
    console.error(e)
  }
}

export default deletePart
