import React from "react"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import facebookIcon from "assets/img/facebook_icon.png"
import watsappIcon from "assets/img/watsapp_icon.png"
import instagramIcon from "assets/img/instagram_icon.png"

export default function HomeFooterCopyRight() {
  return (
    <GridContainer
      style={{
        display: "flex",
        justifyContent: "center",
        background: "rgb(238, 238, 238)",
      }}>
      <GridItem
        xs={12}
        sm={5}
        md={5}
        style={{
          padding: "0 2rem",
          margin: "2rem 0",
          display: "flex",
          justifyContent: "center",
        }}>
        <p
          style={{
            textAlign: "start",
            marginRight: "2rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          }}>
          Copyright © Eceol Auto Parts. All right reserved.
        </p>
        <a href="https://www.facebook.com/echeolautoparts">
          <img
            src={facebookIcon}
            alt="..."
            style={{ width: "17.5px", height: "17.5px", marginRight: "8px" }}
          />
        </a>
        <a href="https://www.instagram.com/echeol_auto_parts/">
          <img
            src={instagramIcon}
            alt="..."
            style={{ width: "17.5px", height: "17.5px", marginRight: "8px" }}
          />
        </a>
        <a href="https://bit.ly/3N7MlUR">
          <img
            src={watsappIcon}
            alt="..."
            style={{ width: "17.5px", height: "17.5px" }}
          />
        </a>
      </GridItem>
    </GridContainer>
  )
}
