import axios from "axios"
// import { cacheAdapterEnhancer } from "axios-extensions";

const baseURL = "https://api.echeol.com"

export const customAxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    // "Cache-Control": "no-cache",
    // adapter: cacheAdapterEnhancer(axios.defaults.adapter),
  },
})

export default customAxiosInstance
