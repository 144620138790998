import { IconButton, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import Layers from "@material-ui/icons/Layers"
import Build from "@material-ui/icons/Build"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js"
import React from "react"

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js"
import Card from "components/Card/Card"
import CustomImgFileInput from "components/CustomImgFileInput/CustomImgFileInput"
import { Close } from "@material-ui/icons"
import PreviewImgItem from "./PreviewImgItem"

const useStylesShoppingCart = makeStyles(shoppingCartStyle)

export default function PartImageForm({
  localImageDataList,
  setLocalImageDataList,
  asyncImageList,
  uploadImgLoading,
  deleteImgLoading,
}) {
  const localImageList = localImageDataList.map((file, i) => {
    return [
      <div key={i} style={{ maxWidth: "5rem" }}>
        <PreviewImgItem file={file} />
      </div>,
      file.name,
      <IconButton
        key={i}
        disabled={deleteImgLoading}
        color="inherit"
        onClick={() => {
          setLocalImageDataList((prev) => prev.filter((f, no) => no !== i))
        }}>
        <Close color="error" />
      </IconButton>,
    ]
  })

  const shoppingCartClasses = useStylesShoppingCart()

  return (
    <>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <Card
            style={{
              maxHeight: "15rem",
              overflow: "scroll",
              borderRadius: 0,
              border: "1px solid #ddd",
            }}
            plain>
            <div style={{ width: "100%", height: "2px", background: "#ddd" }} />
            <Table
              tableHead={["", "파일명", <Build key={1} />]}
              tableData={asyncImageList.concat(localImageList)}
              customHeadCellClasses={[
                shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
              ]}
              customHeadClassesForCells={[0, 1, 2]}
              customCellClasses={[
                shoppingCartClasses.contentCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
                shoppingCartClasses.textCenter +
                  " " +
                  shoppingCartClasses.categoryList +
                  " " +
                  shoppingCartClasses.borderBox +
                  " " +
                  shoppingCartClasses.detail,
              ]}
              customClassesForCells={[0, 1, 2]}
            />
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <CustomImgFileInput
            multiple
            onChange={(fileList) => {
              setLocalImageDataList((prev) => [
                ...prev,
                ...Array.from(fileList),
              ])
            }}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "Upload Image Files...",
            }}
            endButton={{
              buttonProps: {
                round: true,
                disabled: uploadImgLoading,
                color: "info",
                justIcon: true,
                fileButton: true,
              },
              icon: <Layers />,
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  )
}

PartImageForm.propTypes = {
  localImageDataList: PropTypes.array,
  setLocalImageDataList: PropTypes.func,
  asyncImageList: PropTypes.array,
  uploadImgLoading: PropTypes.bool,
  deleteImgLoading: PropTypes.bool,
}
